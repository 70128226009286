<div *ngIf="isFormative()" >
  <div class="side-header">
    <img alt="Formative Strategy Icon" src="/assets/images/formative-strategy.png">
    <h3 class="green-header">
      Formative Strategy
    </h3>
    <p class="description">
      Learn how this strategy relates to the Formative Assessment Process.
    </p>
  </div>
  <sbdl-resource-card
    resourceUrl="https://portal.smarterbalanced.org/library/en/formative-assessment-process.pdf"
    text="Formative Assessment Process Flier"
    description="Formative Assessment Process Flier (opens in a new window)"
    ga="true"
    assetType="Pdf"
    [resource]="resource"
  >
    <i class="far fa-file-pdf icon-lg" aria-hidden="true"></i>
  </sbdl-resource-card>

  <sbdl-resource-card
    resourceUrl="https://www.youtube.com/watch?v=wpwZCqvt70U"
    text="Understanding the Formative Assessment Process"
    description="Understanding the Formative Assessment Process (opens in a new window)"
    ga="true"
    assetType="YouTubeLink"
    [resource]="resource"
  >
    <i class="fab fa-youtube icon-lg" aria-hidden="true"></i>
  </sbdl-resource-card>
</div>

<div *ngIf="!isFormative()">
  <ng-content></ng-content>
  <h4 class="learn-more">Learn more about accessibility.</h4>

  <sbdl-resource-card
    resourceUrl="{{resourceCardLink1}}"
    text="Understanding the Individual Student Assessment Accessibility Profile"
    description="Understanding the Individual Student Assessment Accessibility Profile"
    icon="goal"
  >
  </sbdl-resource-card>

  <sbdl-resource-card
    resourceUrl="{{resourceCardLink2}}"
    text="Individual Student Assessment Accessibility Profile: A Tool to Support Students"
    description="Individual Student Assessment Accessibility Profile: A Tool to Support Students (opens in a new window)"
    icon="goal"
  >
  </sbdl-resource-card>
</div>
