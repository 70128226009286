import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { TenantThemeService } from 'src/app/data/tenant-theme/tenant-theme.service';
import { PreloginSelectionsService } from 'src/app/data/prelogin-selections/prelogin-selections.service';
import { PreloginSelection } from 'src/app/data/prelogin-selections/model/prelogin-selection.model';
import { ActivatedRoute, Params } from '@angular/router';
import { StorageService } from '../../common/storage.service';
import { Title } from '@angular/platform-browser';
import {OktaAuth} from '@okta/okta-auth-js';
import {OKTA_AUTH} from '@okta/okta-angular';
import {Router} from '@angular/router';
import { map } from 'rxjs/operators';

const redirectUrlParameter = 'redirectUrl';

@Component({
  selector: 'sbdl-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  logo$: Observable<string>;

  preloginSelectionId = '';
  preloginSelections: PreloginSelection[];
  infoText = '';

  goButtonHidden = true;
  selectMenuHidden = true;
  queryParams: Params;

  private selectionsSubscription: Subscription;
  private queryParamsSubscription: Subscription;

  constructor(
    private tenantThemeService: TenantThemeService,
    private preloginSelectionsService: PreloginSelectionsService,
    private route: ActivatedRoute,
    private storageService: StorageService,
    private titleService: Title,
    @Inject(OKTA_AUTH) private oktaAuthService: OktaAuth,
    private router: Router
  ) {
    this.logo$ = tenantThemeService.currentTenantTheme$.pipe(
      map(theme => theme.logoUris.full));
  }

  ngOnInit() {
    this.titleService.setTitle('Login: Tools for Teachers - Smarter Balanced');

    this.selectionsSubscription = this.preloginSelectionsService.getAll().subscribe(sel => {
      this.preloginSelections = sel.result;
    });

    this.queryParamsSubscription = this.route.queryParams.subscribe(params => {
      this.queryParams = params;
    });
  }

  ngOnDestroy() {
    if (this.selectionsSubscription) {
      this.selectionsSubscription.unsubscribe();
    }

    if (this.queryParamsSubscription) {
      this.queryParamsSubscription.unsubscribe();
    }
  }

  onPreloginSelected() {
    this.goButtonHidden = false;
    this.infoText = this.preloginSelections.find(s => s.preloginSelectionId.toString() === this.preloginSelectionId).infoText;
  }

  onGoButtonClicked() {
    const sel = this.preloginSelections.find(s => s.preloginSelectionId.toString() === this.preloginSelectionId);
    this.storageService.set('isNormalLoginFlow', '1');
    this.storageService.set('originalUriToReturnTo', this.queryParams[redirectUrlParameter]);
    this.oktaAuthService.signInWithRedirect({ originalUri: 'auth/login-callback-component', idp: sel.oktaIdpId });
  }
}
