<button
  #button
  matRipple
  class="sbdl-icon-button material-icons"
  [attr.disabled]="disabled"
  [tabindex]="tabindex"
  [title]="buttonTitle"
  [matRippleRadius]="25"
  [matRippleCentered]="true"
  [matRippleColor]="rippleColor"
>
  <ng-content></ng-content>
</button>
