<div class="top-nav top-nav-grid-container" [ngClass]="{'container-show-search': showSearch}">
  <div class="icons-container nav-hamburger">

    <div class="open-close-arrow side-nav-closed" (click)="toggleNavVisibility()" *ngIf="navVisibilityState === 'closed'">
      <i class="far fa-chevron-circle-right"></i>
    </div>
    <div>
      <a
        class="hamburger"
        tabindex="1"
        (click)="toggleNavVisibility()"
      >
        <div class="imageArea">
          <i class="far fa-bars center" aria-hidden="true"></i>
        </div>
      </a>
    </div>
  </div>
  <div class="nav-logo">
    <a
      [routerLink]="[baseHref]"
      title="Tools for Teachers Home Page"
      [attr.aria-label]="'Tools for Teachers Home Page'"
      routerLinkActive=""
      #setAriaCurrentHome="routerLinkActive"
      [attr.aria-current]="setAriaCurrentHome.isActive ? 'page' : undefined"
      tabindex="1"
      class="logo"
    >
      <img class="full" [src]="(logo$ | async) | trustUrl" aria-hidden="true">
    </a>
  </div>
  <div class="nav-search search">
    <div class="nav-full-search" [ngClass]="{'show-search': showSearch}">
      <div class="close-search">
        <a title="Close Search" [attr.aria-label]="'Close Search'" (click)="toggleSearch()">
          <i class="far fa-arrow-left center" aria-hidden="true"></i>
        </a>
      </div>
      <sbdl-search
        [showHeadings]="false"
        [filters]=""
        [searchPage]="false"
        [navSearch]="true"
        [InstanceId]="2"></sbdl-search>
    </div>
    <div class="nav-icon-search" [ngClass]="{'show-search': showSearch}">
      <a title="Find Resources" [attr.aria-label]="'Find Resources'" (click)="toggleSearch()" class="nav-search-icon">
        <div class="imageArea">
          <i class="far fa-search center" aria-hidden="true"></i>
        </div>
      </a>
    </div>
  </div>
  <div class="nav-notes notes">
    <sbdl-button class="nav-button notes-button"
      *ngIf="(isAuthenticated$ | async)"
      title="Bookmarks & Notes"
      [attr.aria-label]="'Bookmarks & Notes'"
      [routerLink]="[baseHref, 'bookmarks']"
      routerLinkActive #rlaBookmarks="routerLinkActive"
      [ngClass]="{'selectedBackgroundIsActive': rlaBookmarks.isActive,
                   'selectedBackgroundNotActive': !rlaBookmarks.isActive}"
      #setAriaCurrentBookmarks="routerLinkActive"
      [attr.aria-current]="setAriaCurrentBookmarks.isActive ? 'page' : undefined"
    >
      <i class="far fa-bookmark center" aria-hidden="true"></i>
      <span class="notes-text">Bookmarks & Notes</span>
    </sbdl-button>
  </div>
  <div class="nav-user user">
    <sbdl-button class="primary nav-button"
       *ngIf="!(isAuthenticated$ | async)"
       title="Login"
       [attr.aria-label]="'Log in'"
       (click)="setTitle('Log in: Tools for Teachers - Smarter Balanced'); login()"
       routerLinkActive #rlaLogin="routerLinkActive"
       [ngClass]="{'selectedBackgroundIsActive': rlaLogin.isActive,
         'selectedBackgroundNotActive': !rlaLogin.isActive}"
       #setAriaCurrentLogin="routerLinkActive"
       [attr.aria-current]="setAriaCurrentLogin.isActive ? 'page' : undefined"
    >
      <i class="far fa-sign-in center" aria-hidden="true"></i>
      Log in
    </sbdl-button>
    <sbdl-button class="primary nav-button"
                 *ngIf="(isAuthenticated$ | async)"
                 title="Logout"
                 [attr.aria-label]="'Logout'"
                 routerLinkActive #rlaLogout="routerLinkActive"
                 [ngClass]="{'selectedBackgroundIsActive': rlaLogout.isActive,
         'selectedBackgroundNotActive': !rlaLogout.isActive}"
                 #setAriaCurrentLogout="routerLinkActive"
                 (click)="signOut();
       setTitle('Login: Tools for Teachers - Smarter Balanced')"
    >
      <i class="far fa-sign-out center" aria-hidden="true"></i>
      Logout
    </sbdl-button>
  </div>
</div>
