import { Component, Input, OnInit } from '@angular/core';
import { ResourceProperties } from 'src/app/data/resource/model/properties.model';
import { Claim } from 'src/app/data/resource/model/claim.model';
import { Subject } from 'src/app/data/resource/model/subject.model';
import { Standard } from 'src/app/data/resource/model/standard.model';
import { Target } from 'src/app/data/resource/model/target.model';
import { Category} from '../../../data/resource/model/category.model';
import { ResourceType } from 'src/app/data/resource/model/resource-type.enum';
import {
  byStringWithNumber,
  Comparator,
  on
} from '../../../common/sorting/sorting';
import { UaagTier } from 'src/app/data/resource/model/uaagTier.model';
import {Domain} from '../../../data/resource/model/domain.model';
import {DCIStrand} from '../../../data/resource/model/dciStrand.model';
import {PerformanceExpectation} from '../../../data/resource/model/performanceExpectation.model';
import {SortingService} from '../../../common/sorting/sorting.service';
import {StorageService} from '../../../common/storage.service';

const byTargetNumber: Comparator<Target> = on(
  (x) => x.number,
  byStringWithNumber()
);
const byStandardTitle: Comparator<Standard> = on(
  (x) => x.title,
  byStringWithNumber()
);
const byCategoryTitle: Comparator<Category> = on(
  (x) => x.title,
  byStringWithNumber()
);

const gradeSorter = ['K', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', 'HS'];
const domainPriorityOrder = ['ess', 'ls', 'ps', 'ets'];

@Component({
  selector: 'sbdl-educational-details',
  templateUrl: './educational-details.component.html',
  styleUrls: ['./educational-details.component.scss'],
})
export class EducationalDetailsComponent implements OnInit {
  @Input()
  properties: ResourceProperties;
  @Input()
  resourceType: string;
  @Input()
  uaagTiers: UaagTier[];

  readonly baseClaimsImagePath = '/assets/images/claims/';
  readonly baseAccessibilityImagePath =
    '/assets/images/accessibility-strategy.png';
  readonly baseProfessionalLearningImagePath =
    '/assets/images/professional-learning.png';

  gradeShortNames: string[];
  orderedStandards: Standard[];
  orderedTargets: Target[];
  orderedCategories: Category[];
  orderedDomains: Domain[];
  orderedDciStrands: DCIStrand[];
  orderedPerformanceExpectations: PerformanceExpectation[];
  divider = ',\u00A0';

  constructor(private sorting: SortingService) {}

  public ngOnInit() {
    this.gradeShortNames = this.properties.grades.map((g) => g.shortName)
      .sort((a, b) => gradeSorter.indexOf(a) - gradeSorter.indexOf(b));
    this.orderedStandards = this.properties.standards.sort(byStandardTitle);
    this.orderedTargets = this.properties.targets.sort(byTargetNumber);
    this.orderedCategories = this.properties.categories.sort(byCategoryTitle);

    this.sorting.customOrder(this.properties.domains, domainPriorityOrder);
    this.orderedDomains = this.properties.domains.sort(this.sorting.orderMultiples);

    this.sorting.customOrder(this.properties.dciStrands, domainPriorityOrder);
    this.orderedDciStrands = this.properties.dciStrands.sort(this.sorting.orderMultiples);

    this.sorting.customOrder(this.properties.performanceExpectations, domainPriorityOrder);
    this.orderedPerformanceExpectations = this.properties.performanceExpectations.sort(this.sorting.orderMultiples);

    // Change the divider if the domain is already a list
    this.orderedDomains.forEach(value => {
      const checkList = value.description.includes('and') || value.description.includes(',');
      if (checkList) { this.divider = ';\u00A0'; }
    });
  }

  isAccessibilityResource = () => {
    return this.resourceType === ResourceType.AccessibilityStrategy;
  }

  isInstructionalResource = () => {
    return this.resourceType === ResourceType.Instructional;
  }

  isProfessionalLearningResource = () => {
    return this.resourceType === ResourceType.ProfessionalLearning;
  }

  getAccessibilityImagePath = () => {
    return `${this.baseAccessibilityImagePath}`;
  }

  getProfessionalLearningImagePath = () => {
    return `${this.baseProfessionalLearningImagePath}`;
  }

  getClaimImagePath = (subject: Subject, claim: Claim) => {
    if (subject.code !== 'ela' && subject.code !== 'math') {
      // invalid subject
      return undefined;
    }

    if (isNaN(claim.number) || claim.number < 1 || claim.number > 4) {
      // invalid claim
      return undefined;
    }

    return `${this.baseClaimsImagePath}${subject.code}-${claim.number}.png`;
  }
}
