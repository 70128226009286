<h3>Remote/Hybrid Instruction</h3>
<p class="caption emphasis-medium">
  Learn how to adapt this resource to support instruction and learning in remote contexts.
</p>

<sbdl-resource-card
  *ngIf="remoteFramework"
  resourceUrl="/resource/{{ remoteFramework.id }}"
  [text]="remoteFramework.title"
  description="{{ remoteFramework.title }} (opens in a new window)"
>
  <sbdl-resource-type-icon
    class="link-icon"
    [type]="remoteFramework.type"
  ></sbdl-resource-type-icon>
</sbdl-resource-card>
