<div class="login-popover" role="dialog" aria-modal="true" id="login-popover-container">
  <h2 class="login-warning" aria-labelledby="login-popover-container">Wait! Do you want to log in?</h2>

  <div class="body">More resources and other subjects may be available based on your state/territory.
      * When you log in, you can access everything available in your location.
  </div>

  <div class="login">
    <a href="javascript:void(0)"
      (click)="login()"
       role="button"
    >Yes, log in to access my state/territory resources.</a>
    &nbsp;&nbsp;<i class="far fa-arrow-right" aria-hidden="true"></i>
  </div>

  <div class="continue">
    <a href="javascript:void(0)"
      (click)="close()"
       role="button"
    >No, continue with a limited search.</a>
  </div>

  <div class="foot-note">*Access is available to educators in subscribing states/territories.</div>
</div>
