import {Component, Input} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

interface NavLinkClasses {
  active?: boolean;
  [key: string]: boolean | undefined;
}

@Component({
  selector: 'sbdl-nav-link',
  templateUrl: './nav-link.component.html',
  styleUrls: ['./nav-link.component.scss']
})
export class NavLinkComponent {
  @Input() title: string;
  @Input() ariaLabel: string;
  @Input() icon?: string;
  @Input() linkDestination?: any;
  @Input() specialClass?: string;
  @Input() sbdlIcon?: string;
  @Input() tabIndexLink?: string;
  @Input() isLinkDisabled?: boolean;
  closed = false;

  constructor(private router: Router, private route: ActivatedRoute) {}

  getClasses(): NavLinkClasses {
    const classes: NavLinkClasses = {};
    if (this.specialClass) {
      classes[this.specialClass] = true;
    }
    classes.active = this.isActive();
    return classes;
  }

  isActive(): boolean {
    if (typeof this.linkDestination === 'string' && this.linkDestination.startsWith('http')) {
      return false; // External links are never 'active'
    }
    const currentUrlTree = this.router.parseUrl(this.router.url);
    const targetUrlTree = this.router.createUrlTree(
      Array.isArray(this.linkDestination) ? this.linkDestination : [this.linkDestination]
    );
    return this.router.serializeUrl(currentUrlTree) === this.router.serializeUrl(targetUrlTree);
  }

  navigate(): void {
    if (typeof this.linkDestination === 'string' && this.linkDestination.startsWith('http')) {
      window.open(this.linkDestination, '_blank'); // Open external links in a new tab
    } else if (this.linkDestination) {
      this.router.navigate(this.linkDestination instanceof Array ? this.linkDestination : [this.linkDestination]);
    }
  }
}
