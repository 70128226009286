<div class="card-wrapper" [ngClass]="'type-' + typeShort">
  <a #card
     [routerLink]="['/resource', resource.id]"
     class="search-result"
  >
    <div class="summary-content">
      <div class="resource-type">
        <sbdl-resource-type-icon [type]="resource.type"></sbdl-resource-type-icon>
        {{ resource.type | resourceType }}
      </div>
      <h3>{{ resource.properties.title }}</h3>


      <!-- Instructional and Playlists -->
      <span
        *ngIf="resource.properties.subject && (resource.type === 'Instructional' || resource.type === 'Connections Playlist')">
        <div class="grade-subject" title="{{ resource.properties.subject.fullName }}">

          <span *ngIf="resource.properties.subject.code === 'ela';else subjectFull">
            {{ resource.properties.subject.code | uppercase }}
          </span>
          <ng-template #subjectFull>
            {{ resource.properties.subject.fullName }}
          </ng-template>
          <span *ngIf="(resource.properties.grades.length > 0) && (resource.properties.subject)">
            &nbsp;&nbsp;&bull;&nbsp;
          </span>
          <span *ngIf="resource.properties.grades.length > 0"  >
            <span [ngPlural]="resource.properties.grades.length">
              <ng-template ngPluralCase="=1">Grade </ng-template>
              <ng-template ngPluralCase="other">Grades </ng-template>
            </span>
            <ng-container *ngFor="let grade of resource.properties.grades">
              <span [ngClass]="{'grade-circle': grade.shortName !== 'HS' && grade.shortName !== 'K'}">
                {{ grade.shortName }}
              </span>
            </ng-container>
          </span>
        </div>
      </span>

      <div class="metadata"
           [ngClass]="{'no-metadata':
           (typeShort === 'pl' && resource.professionalLearningCategories.length === 0)
           || (typeShort === 'as' && resource.accessibilityCategories.length === 0 && resource.uaagTiers.length === 0)
           || typeShort === 'fs'
           }">
        <div class="metadata-row">
          <ng-container *ngIf="resource.properties.claims.length > 0">
            <ng-container *ngIf="resource.properties.claims.length === 1">
              <div class="metadata-title">Claim:</div>
              <div class="metadata-claims">
                <div class="metadata-option" title="{{ resource.properties.claims[0].number }}: {{ resource.properties.claims[0].title }}">
                  {{ resource.properties.claims[0].number }}:
                  {{ resource.properties.claims[0].title }}
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="resource.properties.claims.length > 1">
              <div class="metadata-title">Claims:</div>
              <div class="metadata-claims">
                <div *ngFor="let option of resource.properties.claims | slice:0:4"
                     class="metadata-option"
                     title="{{option.number}}: {{option.title}}">
                  {{option.number}}
                </div>
                <span *ngIf="resource.properties.claims.length > 4">...</span>
              </div>
            </ng-container>
          </ng-container>
        </div>


        <div class="metadata-row">
          <ng-container *ngIf="resource.properties.targets.length > 0" class="metadata-row">
            <ng-container *ngIf="resource.properties.targets.length === 1">
              <div class="metadata-title">Target:</div>
              <div class="metadata-targets">
                <div class="metadata-option" title="{{ resource.properties.targets[0].number }}: {{ resource.properties.targets[0].description }}">
                  {{ resource.properties.targets[0].number }}:
                  {{ resource.properties.targets[0].description }}
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="resource.properties.targets.length > 1">
              <div class="metadata-title">Targets:</div>
              <div class="metadata-targets">
                <div *ngFor="let option of resource.properties.targets  | slice:0:8"
                     class="metadata-option"
                     title="{{option.number}}: {{option.description}}">
                  {{option.number}}
                </div>
                <span *ngIf="resource.properties.targets.length > 8">
                  ...
                </span>
              </div>
            </ng-container>
          </ng-container>
        </div>

        <div class="metadata-row">
          <ng-container *ngIf="resource.properties.domains.length > 0" class="metadata-row">
            <ng-container *ngIf="resource.properties.domains.length === 1">
              <div class="metadata-title">Domain:</div>
              <div class="metadata-domains">
                <div class="metadata-option" title="{{ resource.properties.domains[0].description }}">
                  {{ resource.properties.domains[0].description }}
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="resource.properties.domains.length > 1">
              <div class="metadata-title">Domains:</div>
              <div class="metadata-domains">
                <div *ngFor="let option of resource.properties.domains | slice:0:4"
                     class="metadata-option"
                     title="{{option.description}}">
                  {{option.shortDescription}}
                </div>
                <span *ngIf="resource.properties.domains.length > 4">
                  ...
                </span>
              </div>
            </ng-container>
          </ng-container>
        </div>

        <div class="metadata-row">
          <ng-container *ngIf="resource.properties.dciStrands.length > 0" class="metadata-row">
            <ng-container *ngIf="resource.properties.dciStrands.length === 1">
              <div class="metadata-title">DCI Strand:</div>
              <div class="metadata-dci-strands">
                <div class="metadata-option" title="{{ resource.properties.dciStrands[0].description }}">
                  {{ resource.properties.dciStrands[0].description }}
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="resource.properties.dciStrands.length > 1">
              <div class="metadata-title">DCI Strands:</div>
              <div class="metadata-dci-strands">
                <div *ngFor="let option of orderedDCIStrands | slice:0:10"
                     class="metadata-option"
                     title="{{option.description}}">
                  {{option.shortDescription}}
                </div>
                <span *ngIf="orderedDCIStrands.length > 10">
                  ...
                </span>
              </div>
            </ng-container>
          </ng-container>
        </div>

        <div class="metadata-row">
          <ng-container *ngIf="resource.properties.performanceExpectations.length > 0" class="metadata-row">
            <ng-container *ngIf="resource.properties.performanceExpectations.length === 1">
              <div class="metadata-title">Performance Expectation:</div>
              <div class="metadata-pes">
                <div class="metadata-option" title="{{ resource.properties.performanceExpectations[0].description }}">
                  {{ resource.properties.performanceExpectations[0].shortDescription }}
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="resource.properties.dciStrands.length > 1">
              <div class="metadata-title">Performance Expectations:</div>
              <div class="metadata-pes">
                <div *ngFor="let option of orderedPerformanceExpectations | slice:0:8"
                     class="metadata-option"
                     title="{{option.description}}">
                  {{option.shortDescription}}
                </div>
                <span *ngIf="orderedPerformanceExpectations.length > 8">
                  ...
                </span>
              </div>
            </ng-container>
          </ng-container>
        </div>

        <div class="metadata-row">
          <ng-container *ngIf="resource.properties.standards.length > 0" class="metadata-row">
            <ng-container *ngIf="resource.properties.standards.length === 1">
              <div class="metadata-title">Standards:</div>
              <div class="metadata-standards">
                <div class="metadata-option" title="{{ resource.properties.standards[0].description }}">
                  {{ resource.properties.standards[0].title }}
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="resource.properties.standards.length > 1">
              <div class="metadata-title">Standards:</div>
              <div class="metadata-standards">

                <div *ngFor="let option of resource.properties.standards | slice:0:5"
                     class="metadata-option"
                     title="{{option.description}}">
                  {{option.title}}
                </div>
                <span *ngIf="resource.properties.standards.length > 5">
                  ...
                </span>
              </div>
            </ng-container>
          </ng-container>
        </div>

        <div class="metadata-row">
          <ng-container *ngIf="resource.properties.categories.length > 0" class="metadata-row">
            <ng-container *ngIf="resource.properties.categories.length === 1">
              <div class="metadata-title">Category:</div>
              <div class="metadata-categories">
                <div class="metadata-option" title="{{ resource.properties.categories[0].title }}">
                  {{ resource.properties.categories[0].title }}
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="resource.properties.categories.length > 1">
              <div class="metadata-title">Categories:</div>
              <div class="metadata-categories">
                <div *ngFor="let option of resource.properties.categories | slice:0:5"
                     class="metadata-option"
                     title="{{option.title}}">
                  {{option.title}}
                </div>
                <span *ngIf="resource.properties.categories.length > 5">
                  ...
                </span>
              </div>
            </ng-container>
          </ng-container>
        </div>

        <div class="metadata-row">
          <ng-container *ngIf="resource.uaagTiers.length > 0" class="metadata-row">
            <ng-container *ngIf="resource.uaagTiers.length === 1">
              <div class="metadata-title">UAAG Tier:</div>
              <div class="metadata-uaagTiers">
                <div class="metadata-option" title="{{ resource.uaagTiers[0].title }}">
                  {{ resource.uaagTiers[0].title }}
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="resource.uaagTiers.length > 1">
              <div class="metadata-title">UAAG Tiers:</div>
              <div class="metadata-uaagTiers">
                <div *ngFor="let option of resource.uaagTiers | slice:0:5"
                     class="metadata-option"
                     title="{{option.title}}">
                  {{option.title}}
                </div>
                <span *ngIf="resource.uaagTiers.length > 5">
                  ...
                </span>
              </div>
            </ng-container>
          </ng-container>
        </div>

        <div class="metadata-row">
          <ng-container *ngIf="resource.accessibilityCategories.length > 0" class="metadata-row">
            <ng-container *ngIf="resource.accessibilityCategories.length === 1">
              <div class="metadata-title">Category:</div>
              <div class="metadata-accessibilityCategories">
                <div class="metadata-option" title="{{ resource.accessibilityCategories[0].title }}">
                  {{ resource.accessibilityCategories[0].title }}
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="resource.accessibilityCategories.length > 1">
              <div class="metadata-title">Categories:</div>
              <div class="metadata-accessibilityCategories">
                <div *ngFor="let option of resource.accessibilityCategories | slice:0:5"
                     class="metadata-option"
                     title="{{option.title}}">
                  {{option.title}}
                </div>
                <span *ngIf="resource.accessibilityCategories.length > 5">
                  ...
                </span>
              </div>
            </ng-container>
          </ng-container>
        </div>

        <div class="metadata-row">
          <ng-container *ngIf="resource.professionalLearningCategories.length > 0" class="metadata-row">
            <ng-container *ngIf="resource.professionalLearningCategories.length === 1">
              <div class="metadata-title">Category:</div>
              <div class="metadata-professionalLearningCategories">
                <div class="metadata-option" title="{{ resource.professionalLearningCategories[0].title }}">
                  {{ resource.professionalLearningCategories[0].title }}
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="resource.professionalLearningCategories.length > 1">
              <div class="metadata-title">Categories:</div>
              <div class="metadata-professionalLearningCategories">
                <div *ngFor="let option of resource.professionalLearningCategories | slice:0:5"
                     class="metadata-option"
                     title="{{option.title}}">
                  {{option.title}}
                </div>
                <span *ngIf="resource.professionalLearningCategories.length > 5">
                  ...
                </span>
              </div>
            </ng-container>
          </ng-container>
        </div>

      </div>
      <div
        *ngIf='textOnlyResourceSummary.length > 5'
        id="{{resource.id}}-summary"
        class="summary"
        [ngClass]="{'open': addOpenClass}"
      >
        {{ textOnlyResourceSummary }}
      </div>
    </div>
  </a>
  <!-- 43 characters per row -->
  <a *ngIf='textOnlyResourceSummary.length > 86 && typeShort ==="ir"' class="see-more"
     (click)="toggleOpenClass()">{{ addOpenClass ? 'See Less' : 'See More' }}</a>
  <a *ngIf='textOnlyResourceSummary.length > 150 && typeShort ==="pl"' class="see-more"
     (click)="toggleOpenClass()">{{ addOpenClass ? 'See Less' : 'See More' }}</a>
  <a *ngIf='textOnlyResourceSummary.length > 43 && typeShort ==="cp"' class="see-more"
     (click)="toggleOpenClass()">{{ addOpenClass ? 'See Less' : 'See More' }}</a>
  <a *ngIf='textOnlyResourceSummary.length > 245 && typeShort ==="as"' class="see-more"
     (click)="toggleOpenClass()">{{ addOpenClass ? 'See Less' : 'See More' }}</a>
  <a *ngIf='textOnlyResourceSummary.length > 349 && typeShort ==="fs"' class="see-more"
     (click)="toggleOpenClass()">{{ addOpenClass ? 'See Less' : 'See More' }}</a>
</div>
