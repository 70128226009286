export interface SearchFilters {
  query: string;
  resourceTypes: Filter[];
  grades: Filter[];
  subjects: Filter[];
  claims: Filter[];
  domains: Filter[];
  dciStrands: Filter[];
  performanceExpectations: Filter[];
  targets: Filter[];
  standards: Filter[];
  contentArea?: Filter[];
}

export interface Filter {
  title: string;
  code: string;
  selected?: boolean;
  disabled?: boolean;
  originalOrder?: number;
  number?: string;
  grade?: string;
  subject?: string;
}

export const emptyFilters: SearchFilters = {
  query: '',
  resourceTypes: [],
  grades: [],
  domains: [],
  dciStrands: [],
  performanceExpectations: [],
  subjects: [],
  claims: [],
  targets: [],
  standards: [],
  contentArea: []
};
