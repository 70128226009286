<div class="playlist-resources">
  <h3>Playlist Resources</h3>
  <p class="caption emphasis-medium">
    Quick links to the referenced materials.
  </p>
  <a
    #resourceRef
    [attr.aria-label]="resource.title"
    class="card-blue-left-stripe"
    *ngFor="let resource of resources"
    [routerLink]="['/resource', resource.id]"
  >
    <div class="type-info">
      <span class="text-title">{{ resource.title }}</span>
      <sbdl-resource-type-icon
        [type]="resource.type"
      ></sbdl-resource-type-icon>
    </div>
  </a>
</div>
