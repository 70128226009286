import { Component, Inject, Input, OnInit } from '@angular/core';
import { APP_BASE_HREF } from '@angular/common';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { NavVisibilityService } from '../../../common/nav-visbility.service';
import { TenantThemeService } from '../../../data/tenant-theme/tenant-theme.service';
import { UserService } from '../../../data/user/user.service';
import {emptyFilters, SearchFilters} from '../../../data/search/search-filters.model';

@Component({
  selector: 'sbdl-top-navigation',
  templateUrl: './top-navigation.component.html',
  styleUrls: ['./top-navigation.component.scss']
})
export class TopNavigationComponent implements OnInit {

  logo$: Observable<string>;
  isAuthenticated$!: Observable<boolean>;
  filters: SearchFilters = emptyFilters;
  showSearch = false;
  navVisibilityState: string;

  @Input()
  fromOverlay?: boolean;

  constructor(
    @Inject(APP_BASE_HREF) public baseHref: string,
    private tenantThemeService: TenantThemeService,
    private userService: UserService,
    private router: Router,
    private titleService: Title,
    private navVisibilityService: NavVisibilityService
  ) {
    this.logo$ = this.tenantThemeService.currentTenantTheme$.pipe(
      map(theme => theme.logoUris.full)
    );

    this.navVisibilityState = this.navVisibilityService.getVisibility();
  }

  ngOnInit() {
    this.isAuthenticated$ = this.userService.authenticated;
    this.navVisibilityService.currentVisibility.subscribe(visibilityState => {
      this.navVisibilityState = visibilityState;
    });
  }

  login() {
    this.router.navigate(['/auth/login'], {
      queryParams: {
        redirectUrl: this.router.url
      }
    });
  }

  setTitle(title: string) {
    this.titleService.setTitle(title);
  }

  toggleSearch() {
    this.showSearch = !this.showSearch;
  }

  signOut() {
    this.router.navigate(['/auth/logout']);
  }

  toggleNavVisibility() {
    if (this.navVisibilityState === 'closed') {
        this.navVisibilityService.setVisibility('lesson');
    } else {
        this.navVisibilityService.setVisibility('closed');
    }
  }
}
