import { Component, ViewChild, ElementRef, Input } from '@angular/core';

@Component({
  selector: 'sbdl-button-icon',
  templateUrl: './button-icon.component.html'
})
export class ButtonIconComponent {

  @ViewChild('button', { static: true })
  button: ElementRef;

  @Input()
  disabled: boolean;

  @Input()
  buttonTitle: string;

  @Input()
  tabindex = 0;

  @Input()
  rippleColor: string;

  constructor() { }

}
