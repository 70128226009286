<ng-container *ngIf="playlists != null && playlists.length > 0">
  <h3>Playlists</h3>
  <p class="caption emphasis-medium">
    Interim connections playlists utilizing this resource.
  </p>
  <ul>
    <li *ngFor="let playlist of playlists; let i = index" 
      [ngClass]="{'fireFoxPadding': playlists.length-1 === i}">
      <a
        class="playlist-card"
        [attr.aria-label]="playlist.title"
        [routerLink]="['/resource', playlist.id]"
      >
        <div class="playlist-card-left">
          <sbdl-resource-thumbnail
            [subjectCode]="subjectCode"
            [url]="playlist.image"
          ></sbdl-resource-thumbnail>
        </div>
        <div class="playlist-card-right">
          <div class="playlist-card-information">
            <h4 class="title">{{ playlist.title }}</h4>
            <p class="caption emphasis-medium" [ngPlural]="playlist.count" >
              <ng-template ngPluralCase="=1">{{ playlist.count }} resource</ng-template>
              <ng-template ngPluralCase="other">{{ playlist.count }} resources</ng-template>
            </p>
          </div>
          <sbdl-icon
            icon="playlist-play"
            class="playlist-icon"
          ></sbdl-icon>
        </div>
      </a>
    </li>
  </ul>
</ng-container>
