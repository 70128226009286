<div *ngIf="!isAccessibilityResource() && !isProfessionalLearningResource()" class="subject-grades">
  <!-- TODO: better mapping strategy fo claim images; accessibility -->
  <img
    *ngIf="properties.claims.length > 0"
    [src]="getClaimImagePath(properties.subject, properties.claims[0])"
    alt=""
  />
  <div>
    <h3 class="subject">{{ properties.subject.shortName }}</h3>
    <h4 class="grades">
      <span [ngPlural]="properties.grades.length">
        <ng-template ngPluralCase="=1">Grade</ng-template>
        <ng-template ngPluralCase="other">Grades</ng-template>
      </span>
      {{ gradeShortNames | join: { conjunction: "and" } }}
    </h4>
  </div>
</div>

<div *ngIf="isAccessibilityResource()" class="subject-grades">
  <img [src]="getAccessibilityImagePath()" alt=""/>
  <div>
    <h3 class="subject">{{ resourceType }}</h3>
  </div>
</div>

<div *ngIf="isProfessionalLearningResource()" class="subject-grades">
  <img [src]="getProfessionalLearningImagePath()" alt=""/>
  <div>
    <h3 class="subject">{{ resourceType }}</h3>
  </div>
</div>

<div class="detail-container">
  <div class="details"
       *ngIf="properties.claims && properties.claims.length > 0">
    <!-- Claims -->
    <div class="label"
      [ngPlural]="properties.claims.length"
    >
      <ng-template ngPluralCase="=1">Claim</ng-template>
      <ng-template ngPluralCase="other">Claims</ng-template>
    </div>
    <div *ngIf="properties.claims && properties.claims.length > 0" class="value">
      <span
        *ngFor="let claim of properties.claims; let isLast = last"
        title="{{ claim.title }}"
      >
        <sbdl-tooltip
          [title]="'CLAIM ' + claim.number  + ': ' + claim.title"
          [text]="'<p>' + claim.longDescription + '</p>'"
          [isScrollable]="false"
          [browseMoreResourcesFilter]="'claims'"
          [browseMoreResourcesDetails]="claim.code"
          [filterOpen]="false"
          class="educational-details-link"
          style="white-space: nowrap"
        >
          <span class="gradient-hover"
          >{{ claim.number }}: {{ claim.title }} </span
          >{{ isLast ? "" : ",&nbsp;" }}
        </sbdl-tooltip>
      </span>
    </div>

  </div>
  <div class="details"
       *ngIf="properties.targets && properties.targets.length > 0">
    <!-- Targets -->
    <div class="label"
      [ngPlural]="properties.targets.length"
    >
      <ng-template ngPluralCase="=1">Target</ng-template>
      <ng-template ngPluralCase="other">Targets</ng-template>
    </div>
    <div
      *ngIf="properties.targets && properties.targets.length > 0"
      class="value"
    >
      <span
        *ngFor="let target of orderedTargets; let isLast = last"
        title="{{ target.description }}"
      >
        <sbdl-tooltip
          [title]="'TARGET ' + target.number"
          [text]="'<p>' + target.description + '</p>'"
          [isScrollable]="false"
          [browseMoreResourcesFilter]="'targets'"
          [browseMoreResourcesDetails]="target.code"
          class="educational-details-link"
          style="white-space: nowrap"
        >
          <span class="gradient-hover"
            >{{ target.number }} </span
          >{{ isLast ? "" : ",&nbsp;" }}
        </sbdl-tooltip>
      </span>
    </div>
  </div>
  <div class="details"
       *ngIf="properties.standards && properties.standards.length > 0">
    <!-- Standards -->
    <div class="label"
      [ngPlural]="properties.standards.length"
    >
      <ng-template ngPluralCase="=1">Standard</ng-template>
      <ng-template ngPluralCase="other">Standards</ng-template>
    </div>
    <div
      *ngIf="properties.standards && properties.standards.length > 0"
      class="value"
    >
      <span
        *ngFor="let standard of orderedStandards; let isLast = last"
        title="{{ standard.title }}"
      >
        <sbdl-tooltip
          [title]="'STANDARD ' + standard.title"
          [text]="'<p>' + standard.description + '</p>'"
          [isScrollable]="false"
          [browseMoreResourcesFilter]="'standards'"
          [browseMoreResourcesDetails]="standard.code"
          class="educational-details-link"
          style="white-space: normal"
          ><span class="gradient-hover"
            >{{ standard.title }}</span
          >{{ isLast ? "" : ",&nbsp;" }}
        </sbdl-tooltip>
      </span>
    </div>
  </div>
  <div class="details"
       *ngIf="properties.categories && properties.categories.length > 0">
    <!-- Category -->
    <div class="label"
      [ngPlural]="properties.categories.length"
    >
      <ng-template ngPluralCase="=1">Category</ng-template>
      <ng-template ngPluralCase="other">Categories</ng-template>
    </div>
    <div
      *ngIf="properties.categories && properties.categories.length > 0"
      class="value"
    >
      <span
        *ngFor="let category of orderedCategories; let isLast = last"
        title="{{ category.title }}"
      >
        <sbdl-tooltip
          [title]="category.title | uppercase"
          [text]="'<p>' + category.description + '</p>'"
          [isScrollable]="false"
          class="educational-details-link"
          style="white-space: nowrap"
        >
          <span class="gradient-hover">
            {{ category.title }}
          </span>{{ isLast ? "" : ",&nbsp;" }}
        </sbdl-tooltip>
      </span>
    </div>
  </div>
  <div class="details"
       *ngIf="uaagTiers && uaagTiers.length > 0">
    <!-- UAAG Tier -->
    <div class="label"
      [ngPlural]="uaagTiers.length"
    >
      <ng-template ngPluralCase="=1">UAAG Tier</ng-template>
      <ng-template ngPluralCase="other">UAAG Tier</ng-template>
    </div>
    <div *ngIf="uaagTiers && uaagTiers.length > 0" class="value">
      <span
        *ngFor="let tier of uaagTiers; let isLast = last"
        title="{{ tier.title }}"
      >
        <sbdl-tooltip
          [title]="tier.title | uppercase"
          [text]="'<p>' + tier.description + '</p>'"
          [readMoreUrl]="tier.uaagLink"
          readMoreText="Open the UAAG"
          [isScrollable]="false"
          class="educational-details-link"
          style="white-space: nowrap"
        >
          <span class="gradient-hover">
            {{ tier.title }}
          </span>{{ isLast ? "" : ",&nbsp;" }}
        </sbdl-tooltip>
      </span>
    </div>
  </div>
  <div class="details details-wide-labels"
       *ngIf="orderedDomains && orderedDomains.length > 0">
    <!-- Domains -->
    <div class="label"
         [ngPlural]="orderedDomains.length"
    >
      <ng-template ngPluralCase="=1">Domain</ng-template>
      <ng-template ngPluralCase="other">Domains</ng-template>
    </div>

    <div *ngIf="orderedDomains && orderedDomains.length > 0" class="value">
      <span
        *ngFor="let domain of orderedDomains; let isLast = last"
        title="{{ domain.shortDescription }}"
      >
        {{ domain.description }}{{ isLast ? "" : divider }}
      </span>
    </div>
  </div>
  <div class="details details-wide-labels"
       *ngIf="orderedDciStrands && orderedDciStrands.length > 0">
    <!-- Claims -->
    <div class="label"

         [ngPlural]="orderedDciStrands.length"
    >
      <ng-template ngPluralCase="=1">DCI Strand</ng-template>
      <ng-template ngPluralCase="other">DCI Strands</ng-template>
    </div>
    <div
      *ngIf="orderedDciStrands && orderedDciStrands.length > 0"
      class="value"
    >
      <span
        *ngFor="let dciStrand of orderedDciStrands; let isLast = last"
        title="{{ dciStrand.description }}"
      >
        <sbdl-tooltip
          [title]="'DCI Strand ' + dciStrand.shortDescription"
          [text]="'<p>' + dciStrand.description + '</p>'"
          [isScrollable]="false"
          class="educational-details-link"
          style="white-space: nowrap"
        >
          <span class="gradient-hover"
          >{{ dciStrand.shortDescription }}</span
          >{{ isLast ? "" : ",&nbsp;" }}
        </sbdl-tooltip>
      </span>
    </div>
  </div>
  <div class="details details-wide-labels"
       *ngIf="orderedPerformanceExpectations && orderedPerformanceExpectations.length > 0">
    <!-- Claims -->
    <div class="label"

         [ngPlural]="orderedPerformanceExpectations.length"
    >
      <ng-template ngPluralCase="=1">Performance Expectation</ng-template>
      <ng-template ngPluralCase="other">Performance Expectations</ng-template>
    </div>
    <div
      *ngIf="orderedPerformanceExpectations && orderedPerformanceExpectations.length > 0"
      class="value"
    >
      <span
        *ngFor="let performanceExpectation of orderedPerformanceExpectations; let isLast = last"
        title="{{ performanceExpectation.shortDescription }}"
      >
        <sbdl-tooltip
          [title]="'Performance Expectation ' + performanceExpectation.shortDescription"
          [text]="'<p>' + performanceExpectation.description + '</p>'"
          [isScrollable]="false"
          class="educational-details-link"
          style="white-space: nowrap"
        >
          <span class="gradient-hover"
          >{{ performanceExpectation.shortDescription }}</span
          >{{ isLast ? "" : ",&nbsp;" }}
        </sbdl-tooltip>
      </span>
    </div>
  </div>
</div>
