import {
  Component,
  ElementRef,
  Inject,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { OnMount } from '../dynamic/interfaces';
import { PopoverComponent } from '../popover/popover.component';
import { PopoverService } from '../popover/popover.service';
import {StorageService} from '../../storage.service';

/**
 * A specifically structured popover which has specific functionality and format.  Used for
 * displaying additional information about content inside a popover.
 */
@Component({
  selector: 'sbdl-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
})
export class TooltipComponent implements OnInit, OnMount {
  /**
   * The title of this tooltip.
   */
  @Input()
  title: string;

  /**
   * Tooltip text.
   */
  @Input()
  text: string;

  /**
   * Url to click to browse more resources.
   */
  @Input()
  browseMoreResourcesFilter: string;

  @Input()
  browseMoreResourcesDetails: string;

  @Input()
  browseMoreResourcesText = 'Browse More Resources';

  @Input()
  filterOpen: boolean;
  /**
   * Url to click to find more info about the tool tip text.
   */
  @Input()
  readMoreUrl: string;

  @Input()
  readMoreText = 'Keep Reading';

  @Input()
  isScrollable = true;

  dynamicLoadedContent: string;
  popover: PopoverComponent;
  popoverCloseSubscription: Subscription;
  browseMoreResourcesUrl: {};

  @ViewChild('tooltip', { static: false })
  tooltipContainer: ElementRef;

  @ViewChild('tooltipPopover', { static: false })
  tooltipPopover: ElementRef;

  dynamicOnMount(
    attrs?: Map<string, string>,
    content?: string,
    element?: Element
  ): void {
    this.title = attrs.get('title');
    this.text = attrs.get('text');
    this.browseMoreResourcesFilter = attrs.get('browsemoreresourcesfilter');
    this.browseMoreResourcesDetails = attrs.get('browsemoreresourcesdetails');
    this.browseMoreResourcesText = attrs.get('browsemoreresourcestext');
    this.browseMoreResourcesUrl = this.getBrowseMoreResourcesUrl(attrs.get('browsemoreresourcesfilter'), attrs.get('browsemoreresourcesdetails'));
    this.readMoreUrl = attrs.get('readmoreurl');
    this.readMoreText = attrs.get('readmoretext');
    this.dynamicLoadedContent = content;
  }

  constructor(
    @Inject('Window') private window: Window,
    private popoverService: PopoverService,
    private storageService: StorageService
  ) {}

  ngOnInit() {
    this.browseMoreResourcesUrl = this.getBrowseMoreResourcesUrl(this.browseMoreResourcesFilter, this.browseMoreResourcesDetails);
  }

  getBrowseMoreResourcesTextLabel() {
    return this.browseMoreResourcesText || 'Browse More Resources';
  }

  getReadMoreTextLabel() {
    return this.readMoreText || 'Keep Reading';
  }

  openTooltipPopover() {
    this.popover = this.popoverService.openOnBody(this.tooltipPopover, {
      offset: this.offset(this.tooltipContainer.nativeElement),
      cssClass: 'tooltip',
      placement: 'top',
      isScrollable: this.isScrollable,
    });
    this.popover.onClose.subscribe(this.close);
  }

  close = () => {
    if (this.popoverCloseSubscription) {
      this.popoverCloseSubscription.unsubscribe();
    }

    this.popoverCloseSubscription = undefined;
    this.popover = undefined;
    requestAnimationFrame(() => this.tooltipContainer.nativeElement.focus());
  }

  private offset(el) {
    const rect = el.getBoundingClientRect();
    const width = rect.right - rect.left;
    const scrollLeft =
      this.window.pageXOffset || document.documentElement.scrollLeft;
    const scrollTop =
      this.window.pageYOffset || document.documentElement.scrollTop;

    const rectOffset = {
      top: rect.top,
      left: rect.left + width / 2 + 24,
      actualTop: rect.top,
      actualLeft: rect.left,
      width: rect.width,
      height: rect.height
    };

    if (this.isScrollable) {
      rectOffset.top = rect.top + scrollTop;
      rectOffset.left = rect.left + scrollLeft + width / 2 + 24;
    }

    return rectOffset;
  }

  getTooltipBounds() {
    return this.tooltipContainer.nativeElement.getBoundingClientRect();
  }

  getBrowseMoreResourcesUrl(filterUrl, details){
    return {[filterUrl]: details};
  }

  setFilter() {
    this.storageService.set('openFilter', 'false');
  }
}
