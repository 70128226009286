<section #sectionHeader>
  <h3 *ngIf="title">{{ title }}</h3>
  <div *ngFor="let strategy of strategies" class="strategy-link">
    <h3 *ngIf="!title" class="strategy-title">
      <a href="/resource/{{strategy.id}}"
         [attr.aria-label]="strategy.title">
        {{ strategy.title }}
        <i class="far fa-arrow-right" aria-hidden="true"></i>
      </a>
    </h3>
    <h4 *ngIf="title" class="strategy-title">
      <a href="/resource/{{strategy.id}}"
         [attr.aria-label]="strategy.title">
        {{ strategy.title }}
        <i class="far fa-arrow-right" aria-hidden="true"></i>
      </a>
    </h4>
    <dynamic-html [content]="strategy.description"></dynamic-html>
  </div>
</section>
