<div
  #container
  cdkTrapFocus
  role="dialog" aria-modal="true"
  class="popover-container {{options.cssClass}} {{options.placement}}"
>
  <div class="popover-content">
    <button
      #closeButton
      class="close-button"
      aria-label="close"
      (click)="close()"
    >
        <sbdl-icon icon="circle-close"></sbdl-icon>
    </button>
    <ng-container *ngTemplateOutlet="template"></ng-container>
  </div>
  <div #tooltipArrow class="tooltip-arrow">
  </div>
</div>
