<sbdl-button-icon *ngIf="authenticated$ | async" buttonTitle="Notes" (click)="toggleNotes()">
  <sbdl-icon [ngClass]="{'has-notes': hasNotes}" icon="take-note"></sbdl-icon>
</sbdl-button-icon>
<sbdl-button-icon
  buttonTitle="Share"
  cdkOverlayOrigin
  #trigger="cdkOverlayOrigin"
  (click)="shareOverlayOpen = !shareOverlayOpen"
>
  <i class="far fa-share"></i>
</sbdl-button-icon>
<ng-template
  cdkConnectedOverlay
  cdkConnectedOverlayPanelClass="windowed"
  cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
  [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayOpen]="shareOverlayOpen"
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayPositions]="shareOverlayPositions"
  [cdkConnectedOverlayPush]="true"
  (backdropClick)="shareOverlayOpen = false"
>
  <sbdl-share-form
    cdkTrapFocus
    cdkTrapFocusAutoCapture
    [value]="shareValue"
    (closed)="shareOverlayOpen = false"
    (gtm)="gtmEvent()"
  ></sbdl-share-form>
</ng-template>

<ng-container #shareButton></ng-container>
<sbdl-button-icon *ngIf="authenticated$ | async" buttonTitle="Bookmark" (click)="toggleBookmarked()">
  <div *ngIf="!updatingBookmarked && bookmark">
    <i class="fas fa-bookmark"></i>
  </div>
  <div *ngIf="!updatingBookmarked && !bookmark">
    <i class="far fa-bookmark"></i>
  </div>
  <div *ngIf="updatingBookmarked">
    <i class="fas fa-spinner fa-spin"></i>
  </div>
</sbdl-button-icon>
<sbdl-button-icon *ngIf= "!resource.teaser" buttonTitle="Print" (click)="togglePrintingMode()">
  <div *ngIf="printingMode">
    <i class="fas fa-print"></i>
  </div>
  <div *ngIf="!printingMode">
    <i class="far fa-print"></i>
  </div>
</sbdl-button-icon>
<sbdl-button-icon buttonTitle="Toggle Reading Mode" [hidden]="hideReadingModeToggle" (click)="toggleReadingMode()">
  <div *ngIf="readingMode">
    <i class="far fa-compress-alt"></i>
  </div>
  <div *ngIf="!readingMode">
    <i class="far fa-expand-alt"></i>
  </div>
</sbdl-button-icon>
