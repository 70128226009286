<div *ngIf="!addingNote && !editingNote">
  <h2>Notes</h2>
  <!--<h3>Resource Notes</h3>-->
  <sbdl-note *ngFor="let note of notes" [note]="note" (editClick)="editNote(note)" (deleteClick)="deleteNote(note.id)">
  </sbdl-note>
  <sbdl-button class="plain" (click)="addNote()">
    <span *ngIf="notes.length === 0">+ Add your first note</span>
    <span *ngIf="notes.length > 0">+ Add another note</span>
  </sbdl-button>
</div>
<div [hidden]="!addingNote && !editingNote" [ngClass]="{saving: saving}">
  <h2>{{ actionText }}</h2>
  <angular-editor
    [config]="editorConfig"
    [(ngModel)]="noteContent"
    [attr.disabled]="saving">
  </angular-editor>
  <div class="dialog-buttons">
    <sbdl-button (click)="cancelNote()" [hidden]="saving">Cancel</sbdl-button>
    <sbdl-button class="primary" (click)="saveNote()" [hidden]="saving">
      <i class="fas fa-check-circle" aria-hidden="true"></i> Save
    </sbdl-button>
    <div *ngIf="saving">
      <i class="fas fa-spinner fa-pulse" aria-hidden="true"></i> Saving note...
    </div>
  </div>
</div>
