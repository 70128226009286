<div class="resource-type">{{ resource.type | resourceType }}</div>
<h1 *ngIf="!showIconsCol; else titleWithIcons"><a tabindex="-1" [routerLink]="['/resource', resource.id]">{{ properties.title }}</a></h1>
<ng-template #titleWithIcons>
  <div class="title-with-icons">
    <div class="icon-col">
      <div *ngIf="grade" class="circle">G{{ grade.shortName }}</div>
      <!--<sbdl-icon [icon]="properties.assessmentTypeIcon"></sbdl-icon>-->
    </div>
    <h1><a [routerLink]="['/resource', resource.id]">{{ properties.title }}</a></h1>
  </div>
</ng-template>
<a class="print-only print-url" aria-hidden="true" [routerLink]="['/resource', resource.id]">{{ fullUrl }}</a>
<section class="byline">
  <div>{{ properties.authors | join: { conjunction: 'and' } }}</div>
  <div class="font-light">{{ properties.authorOrg }}</div>
  <div *ngIf="properties.authors.length === 0 && !properties.authorOrg">
    <em>Missing author data.</em>
  </div>

<span *ngIf="properties.lastUpdatedDate" class="caption">
  <span class="emphasis-medium">Updated</span>
  {{ properties.lastUpdatedDate | date: 'mediumDate' }}
</span>

</section>

<sbdl-button *ngIf="resource.attachments.length > 0" class="primary attachments-btn" (click)="emitAttachmentsClicked()">
  <i class="far fa-paperclip"></i> Attachments
</sbdl-button>

<sbdl-button *ngIf="(hasIaipAccess$ | async) && isInterimItemPortalVisable" class="primary attachments-btn" (click)="openInterimItems()" >
    <i class="fas fa-badge-check"></i> VIEW INTERIM ITEMS
</sbdl-button>
