import { Component,
  OnInit,
  Input,
  ViewChild,
  ViewContainerRef,
  ElementRef,
  EventEmitter,
  Output } from '@angular/core';
import { ConnectedPosition } from '@angular/cdk/overlay';
import { BrowseridService} from '../../common/browserid.service';

@Component({
  selector: 'sbdl-landing-actions',
  templateUrl: './landing-actions.component.html',
  providers: [BrowseridService]
})
export class LandingActionsComponent implements OnInit {

  @Input()
  landingType: string;

  @ViewChild('shareButton', { static: false, read: ViewContainerRef })
  shareContainer: ViewContainerRef;

  @ViewChild('sharePopover', { static: false })
  sharePopover: ElementRef;

  @Output()
  printPage = new EventEmitter();

  shareValue: string;
  shareOverlayOpen: boolean;
  printSafari = false;

  readonly shareOverlayPositions: ConnectedPosition[] = [
    {
      originX: 'center',
      originY: 'bottom',
      overlayX: 'center',
      overlayY: 'top'
    }
  ];

  constructor(
    private browserIdService: BrowseridService
  ) {}

  ngOnInit() {
    this.shareValue = `${location.protocol}//${location.host}/landing/${this.landingType}`;

    if (this.browserIdService.getBrowser() === 'safari/webkit') {
      this.printSafari = true;
    }
  }

  printLandingPage() {
    this.printPage.emit();
  }
}
