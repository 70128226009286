import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SortingService {

  constructor() { }

  // Helper function for doing ordering by multiple values
  orderMultiples(a, b) {
    const cmp = (aCmp, bCmp) => { if (aCmp > bCmp) { return +1; } if (aCmp < bCmp) { return -1; } return 0; };
    return cmp(a.order, b.order) || cmp(a.code, b.code);
  }

  // Helper function for ordering by custom array
  customOrder(toOrder, order) {
    toOrder.forEach(value => {
      order.forEach((item, index) => {if (value.code.includes(item)) {{ value.order = index; }}});
    });
  }
}
