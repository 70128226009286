<article #header class="formative-container">
  <h2 class="leading-icon">
    <sbdl-icon icon="strategies"></sbdl-icon>
    Formative Assessment Strategies
  </h2>
  <p class="prof-learning-content">
    These strategies were developed to be used with students. Modify as needed for adult use.
  </p>
  <sbdl-strategy-reference-list
    title="Strategies Used"
    [strategies]="strategies">
  </sbdl-strategy-reference-list>
</article>
