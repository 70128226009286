<article #sectionHeader class="background-container" *ngIf="overview">

  <h2 class="leading-icon">
    <i class="fas fa-check-circle" aria-hidden="true"></i>
    IAB Background
    <sbdl-tooltip text="A description of the interim assessment connected with this playlist, including a brief summary of what students should know or be able to do.">
      <sbdl-icon icon="info"></sbdl-icon>
    </sbdl-tooltip>
  </h2>

  <section class="background-table">

    <h3>Description</h3>
    <dynamic-html [content]="overview.description"></dynamic-html>

    <div class="primary" *ngIf="(hasIaipAccess$ | async) && isInterimItemPortalVisable">
      <sbdl-button *ngIf="(hasIaipAccess$ | async)" class="primary" (click)="openInterimItems()">
        <i class="fas fa-badge-check"></i> VIEW INTERIM ITEMS
      </sbdl-button>    
    </div>

    <h3>Why is this important for students to learn?</h3>
    <dynamic-html [content]="overview.importance"></dynamic-html>

    <h3>
      Academic Vocabulary
      <sbdl-tooltip
        text="Construct relevant vocabulary that students should know because it is essential to this claim(s) and/or target(s), these terms should be part of instruction.">
      <sbdl-icon class="tooltip-icon" icon="info"></sbdl-icon></sbdl-tooltip>
    </h3>
    <dynamic-html [content]="overview.academicVocabulary"></dynamic-html>

    <h3>Dive Deeper</h3>
    <div class="dive-links">
      <a target="_blank" class="primary"
         href="https://sampleitems.smarterbalanced.org/BrowseItems/">
        Sample Items
        <i class="far fa-arrow-right" aria-label="(opens in a new window)"></i>
      </a>
      <a target="_blank" class="primary"
         href="https://contentexplorer.smarterbalanced.org/">
        Content Explorer
        <i class="far fa-arrow-right" aria-label="(opens in a new window)"></i>
      </a>
    </div>
  </section>
</article>
