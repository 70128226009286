import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {environment} from '../../../environments/environment';
import { StorageService } from '../../common/storage.service';
import {Observable} from 'rxjs';
import {TenantThemeService} from '../../data/tenant-theme/tenant-theme.service';
import {map} from 'rxjs/operators';

@Component({
  selector: 'sbdl-development-environment',
  templateUrl: './development-environment.component.html',
  styleUrls: ['./development-environment.component.scss']
})
export class DevelopmentEnvironmentComponent implements OnInit {
  public devEnvUsername: string;
  public devEnvPassword: string;
  private devEnv = false;
  public logo$: Observable<string>;
  public displayError = false;
  public envName: string;

  @Output() devEnvState: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private storageService: StorageService,
    private tenantThemeService: TenantThemeService
  ) {
    this.logo$ = tenantThemeService.currentTenantTheme$.pipe(
      map(theme => theme.logoUris.full));
  }

  ngOnInit(): void {
    if (!environment.production) {
      this.devEnv = true;
    }
    this.devEnvState.emit(this.devEnv);

    this.envName = environment.envName;
  }

  onSubmit() {
    if (this.devEnvUsername === 'admin' && this.devEnvPassword === 'T4TDevelopment' ) {
      this.devEnv = false;
      this.storageService.set('devLoggedIn', 'true');
      this.devEnvState.emit(this.devEnv);
    } else {
      this.displayError = true;
    }
  }

}
