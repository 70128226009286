import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {Router} from '@angular/router';
import {BehaviorSubject} from 'rxjs';
import {StorageService} from '../../../common/storage.service';
import {OktaAuth} from '@okta/okta-auth-js';
import {OKTA_AUTH} from '@okta/okta-angular';

@Component({
  selector: 'sbdl-preview-login-prompt',
  templateUrl: './preview-login-prompt.component.html',
  styleUrls: ['./preview-login-prompt.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PreviewLoginPromptComponent {
  loading$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    private storageService: StorageService,
    private router: Router,
    @Inject(OKTA_AUTH) private oktaAuthService: OktaAuth
  ) {}

  onLoginButtonClick(): void {
    this.loading$.next(true);
    // use to differential between seamless login and normal login
    this.storageService.set('isNormalLoginFlow', '1');
    this.router.navigate(['/auth/login'], { queryParams: { redirectUrl: this.router.url }});
  }
}
