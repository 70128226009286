import { NgModule } from '@angular/core';
import { JoinPipe } from './join.pipe';
import { FileTypeIconPipe } from './file-type-icon.pipe';
import { ReplaceNullImagePipe } from './replace-null-image.pipe';
import { ResourceTypePipe } from './resource-type.pipe';
import { TrustUrlPipe } from './trust-url.pipe';
import { SlugifyPipe } from './slugify.pipe';
import { FileExtensionPipe } from './file-extension.pipe';
import { InternalLinksPipe } from './internal-links.pipe';
import {TruncatePipe} from './truncate.pipe';

@NgModule({
    declarations: [
      JoinPipe,
      FileTypeIconPipe,
      ReplaceNullImagePipe,
      ResourceTypePipe,
      TrustUrlPipe,
      SlugifyPipe,
      FileExtensionPipe,
      InternalLinksPipe,
      TruncatePipe
    ],
    exports: [
      JoinPipe,
      FileTypeIconPipe,
      ReplaceNullImagePipe,
      ResourceTypePipe,
      TrustUrlPipe,
      SlugifyPipe,
      FileExtensionPipe,
      InternalLinksPipe,
      TruncatePipe
    ]
})
export class PipesModule { }
