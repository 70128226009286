import {APP_BASE_HREF} from '@angular/common';
import {APP_INITIALIZER, ErrorHandler, NgModule} from '@angular/core';
import {BrowserModule, Title} from '@angular/platform-browser';
import {NoopAnimationsModule} from '@angular/platform-browser/animations';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {AppConfig} from './common/config/app.config';
import {GlobalErrorHandler} from './common/global-error-handler';
import {BookmarksModule} from './bookmarks/bookmarks.module';
import {DataModule} from './data/data.module';
import {HomeModule} from './home/home.module';
import {LayoutModule} from './layout/layout.module';
import {ResourceModule} from './resource/resource.module';
import {SbdlCommonModule} from './common/common.module';
import {NotesModule} from './notes/notes.module';
import { FormsModule} from '@angular/forms';
import {LandingModule} from './landing/landing.module';
import { LandingPrintModule} from './landing/landing-print/landing-print.module';
import { MatRippleModule} from '@angular/material/core';
import { BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { MatInputModule} from '@angular/material/input';
import { MatIconModule} from '@angular/material/icon';
import {GoogleTagManagerModule} from 'angular-google-tag-manager';
import {environment} from '../environments/environment';
import { OktaAuthModule, OKTA_CONFIG } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {OKTA_CALLBACK_LOGOUT_PATH, OKTA_CALLBACK_PATH} from './common/constants';
import {BrowseridService} from './common/browserid.service';
import {AuthInterceptor} from './data/auth.interceptor';
import {OverlayModule} from '@angular/cdk/overlay';
import {PortalModule} from '@angular/cdk/portal';

export function initializeApp(appConfig: AppConfig) {
  return () => appConfig.load();
}

export function initializeGTM() {
  return environment.googleTagManagerId;
}

const oktaAuth = new OktaAuth({
  issuer: environment.oktaIssuer,
  clientId: environment.oktaclientId,
  redirectUri: window.location.origin + '/' + OKTA_CALLBACK_PATH,
  postLogoutRedirectUri: window.location.origin + '/' + OKTA_CALLBACK_LOGOUT_PATH,
  scopes: ['openid', 'profile'],
  pkce:  true,
  responseType: ['token', 'id_token'],
});

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    NoopAnimationsModule,
    AppRoutingModule,
    BookmarksModule,
    DataModule,
    LayoutModule,
    HomeModule,
    ResourceModule,
    NotesModule,
    SbdlCommonModule,
    FormsModule,
    LandingModule,
    LandingPrintModule,
    MatRippleModule,
    BrowserAnimationsModule,
    MatInputModule,
    MatIconModule,
    GoogleTagManagerModule,
    OktaAuthModule,
    HttpClientModule,
    BrowserModule,
    OverlayModule,
    PortalModule
  ],
  providers: [
    {
      provide: APP_BASE_HREF,
      useValue: '/' // Could move into AppConfig
      // deps: [ AppConfig ]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [ AppConfig ], multi: true
    }, {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    }, {
      provide: OKTA_CONFIG,
      useValue: { oktaAuth }
    },
    {
      provide: 'Window',
      useValue: window
    },
    Title,
    {
      provide: 'googleTagManagerId',
      useFactory: initializeGTM
    },
    BrowseridService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
],
  bootstrap: [ AppComponent ]
})
export class AppModule { }
