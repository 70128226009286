import {Component, ElementRef, Input, OnInit, ViewChildren} from '@angular/core';
import { ResourceProperties } from '../../../data/resource/model/properties.model';
import { ResourceType } from '../../../data/resource/model/resource-type.enum';
import { Resource } from '../../../data/resource/model/resource.model';
import { environment} from '../../../../environments/environment';

@Component({
  selector: 'sbdl-strategy-properties',
  templateUrl: './strategy-properties.component.html',
  styleUrls: ['./strategy-properties.component.scss']
})
export class StrategyPropertiesComponent implements OnInit {

  @Input()
  resourceType: ResourceType;

  @Input()
  resource: Resource;

  @Input()
  properties: ResourceProperties;

  @ViewChildren('link')
  linkElementRefs: ElementRef[];

  resourceCardLink1: string;
  resourceCardLink2: string;

  isFormative(): boolean {
    return this.resourceType === ResourceType.FormativeStrategy;
  }

  ngOnInit() {
    switch (environment.envName) {
      case 'default':
        this.resourceCardLink1 = 'resource/1458';
        this.resourceCardLink2 = 'resource/1459';
        break;

      case 'dev':
      case 'qa':
        this.resourceCardLink1 = 'resource/126';
        this.resourceCardLink2 = 'resource/127';
        break;

      case 'uat':
        this.resourceCardLink1 = 'https://smartertoolsforteachers.org/resource/1458';
        this.resourceCardLink2 = 'https://smartertoolsforteachers.org/resource/1459';
        break;

      case 'stage':
        this.resourceCardLink1 = 'https://smartertoolsforteachers.org/resource/1458';
        this.resourceCardLink2 = 'https://smartertoolsforteachers.org/resource/1459';
        break;

      case 'prod':
        this.resourceCardLink1 = 'resource/1458';
        this.resourceCardLink2 = 'resource/1459';
        break;
    }
  }


}
