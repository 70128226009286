import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileExtension'
})
export class FileExtensionPipe implements PipeTransform {

  transform(value) {
    if (!value) {
      return false;
    } else if (value.endsWith('.pdf')) {
      return true;
    } else {
      return false;
    }
  }
}
