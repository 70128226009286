import { Component, Input, ViewChild, ElementRef, Output, EventEmitter, Optional, OnInit, OnDestroy } from '@angular/core';
import { controlValueAccessorProvider } from '../../form/control-value-accessor.functions';
import { AbstractFormControlValueAccessor } from '../../form/abstract-form-control-value-accessor';
import { AbstractControl, ControlContainer, FormControl } from '@angular/forms';
import { SearchTextService } from '../../../data/search/search-text.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'sbdl-search-field',
  templateUrl: './search-text-field.component.html',
  styleUrls: ['./search-text-field.component.scss'],
  providers: [
    controlValueAccessorProvider(SearchTextFieldComponent)
  ]
})
export class SearchTextFieldComponent extends AbstractFormControlValueAccessor implements OnInit, OnDestroy {
  @Input() label: string;
  @Input() placeholder: string;
  @Input() fontAwesomeIcon: string;
  @Input() disabled: boolean;
  @Input() submitButtonAriaLabel = '';
  @Input() isSmallerText = false;
  @Input() searchPage = false;
  @Input() navSearch = false;
  @Input() instanceId: number;

  @Output() submit = new EventEmitter<string>();

  @ViewChild('textField', { static: false }) textFieldRef: ElementRef;

  private readonly formControl: FormControl = new FormControl('');
  private searchTextSubscription: Subscription;

  constructor(
    @Optional() private controlContainer: ControlContainer,
    private searchTextService: SearchTextService
  ) {
    super();
  }

  ngOnInit(): void {
    // Initialize the control
    this.clearInput();
    this.control.setValue('');

    // Check which instance of the search text field has been clicked in last (active)
    this.searchTextSubscription = this.searchTextService.activeInstance$.subscribe((activeId) => {
      // Clear this instance if it is not the active one
      if (activeId !== this.instanceId) {
        this.clearInput();
      }
    });
  }

  get control(): AbstractControl {
    return this.controlContainer != null
      ? this.controlContainer.control
      : this.formControl;
  }

  onSubmitButtonClick() {
    this.submit.emit(this.control.value);
  }

  onInputChange() {
    // Set this as the active one when clicked
    this.searchTextService.updateActiveInstance(this.instanceId);
  }

  clearInput() {
    // Clears the input field using the FormControl
    this.control.setValue('');
  }

  ngOnDestroy(): void {
    if (this.searchTextSubscription) {
      this.searchTextSubscription.unsubscribe();
    }
  }
}
