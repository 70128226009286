<button
  #tooltip
  class="open-button"
  [attr.aria-label]="text"
  (click)="openTooltipPopover()"
>
  <dynamic-html [content]="dynamicLoadedContent"></dynamic-html>
  <ng-content></ng-content>
</button>
<ng-template #tooltipPopover>
  <div class="tooltip-title" [ngClass]="{'browse-more-title': browseMoreResourcesFilter}">{{ title }}</div>
  <dynamic-html [content]="text" class="tooltip-description"></dynamic-html>
  <a
    *ngIf="browseMoreResourcesFilter"
    [routerLink]="['/', 'search', browseMoreResourcesUrl]"
    rel="no-refresh"
    [title]="title"
    class="browse-more"
    (click)="setFilter()"
  >{{ getBrowseMoreResourcesTextLabel()
    }} <i class="far fa-arrow-right" aria-hidden="true"></i
    ></a>
  <a
    *ngIf="readMoreUrl"
    [routerLink]="[readMoreUrl]"
    rel="no-refresh"
    [title]="title"
    class="read-more"
    >{{ getReadMoreTextLabel()
    }}<i class="far fa-arrow-right" aria-hidden="true"></i
  ></a>
</ng-template>
