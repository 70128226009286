<div class="alert-margin-top {{marginAmount}}"></div>
<div class="center">
  <div class="alert-wrapper">
    <ng-container *ngFor="let item of alertsToShow">
      <div id="alert-{{item.alertId}}" role="alert" class="alert {{item.typeCode}}" [ngClass]="className">
        <div class="alert-message" [innerHTML]='item.message'></div>
        <div *ngIf="item.isDismissible" class="alert-dismiss">
          <button
            class="topCloseButton sbdl-icon-button material-icons"
            aria-label="Close"
            (click)="dismissAlert(item.alertId)"
          >
            <i class="far fa-times-circle"></i>
          </button>
        </div>
      </div>
    </ng-container>
  </div>
</div>


