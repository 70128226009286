import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {SbdlCommonModule} from '../common/common.module';
import {PipesModule} from '../pipes/pipes.module';
import {SearchResultsComponent} from './results/search-results.component';
import {SearchResultCardComponent} from './results/card/search-result-card.component';
import {SearchComponent} from './search.component';
import {ResourceModule} from '../resource/resource.module';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { LoginWarningComponent } from './login-warning/login-warning.component';
import {OverlayModule} from '@angular/cdk/overlay';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatOptionModule} from '@angular/material/core';
import {MatChipsModule} from '@angular/material/chips';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        SbdlCommonModule,
        PipesModule,
        RouterModule,
        OverlayModule,
        MatFormFieldModule,
        MatSelectModule,
        MatOptionModule,
        ReactiveFormsModule,
        MatChipsModule
    ],
  declarations: [
    SearchComponent,
    SearchResultsComponent,
    SearchResultCardComponent,
    LoginWarningComponent
  ],
  exports: [
    SearchComponent,
    SearchResultsComponent,
    SearchResultCardComponent,
    LoginWarningComponent
  ],
  entryComponents: [
    LoginWarningComponent
  ]
})
export class SearchModule {}
