<header class="resource-header">
  <div class="top-header">
    <img src="/assets/images/yellow-spot.png" aria-hidden="true">
    <sbdl-actions
      [hasNotes]="notes.length > 0"
      [notesVisible]="notesVisible"
      [readingMode]="readingMode"
      [printingMode]="printingMode"
      [resource]="resource"
      (readingModeChanged)="readingModeChanged.emit($event)"
      (printingModeChanged)="printModeChanged.emit($event)"
      (notesVisibilityChanged)="noteModeChanged.emit($event)"
    ></sbdl-actions>
  </div>

  <sbdl-header
    [resource]="resource"
    (attachmentsClicked)="scrollToAttachments()"
  ></sbdl-header>
</header>
<nav class="outline">
  <sbdl-outline
    [resource]="resource"
    [outline]="outline"
    [readingMode]="readingMode"
    [hidden]="printingMode"
  ></sbdl-outline>
  <sbdl-enhanced-printing
    [resource]="resource"
    [outline]="outline"
    [hidden]="!printingMode"
    (printingModeChanged)="printModeChanged.emit($event)"
    (documentOutlineChanged)="setOutline($event)"
  ></sbdl-enhanced-printing>
</nav>
<main id="main" class="resource-content">
  <sbdl-professional-content
    [notes]="notes"
    [notesVisible]="notesVisible"
    [printingMode]="printingMode"
    [readingMode]="readingMode"
    [outline]="outline"
    [resource]="resource"
    (outlineLoaded)="setOutline($event)"
    (printingModeChanged)="printModeChanged.emit($event)"
    (readingModeChanged)="readingModeChanged.emit($event)"
    (notesVisibilityChanged)="noteModeChanged.emit($event)"
  ></sbdl-professional-content>
</main>
<aside class="resource-properties" [ngClass]="{hidden: notesVisible}">
  <sbdl-resource-properties
    [properties]="resource.properties"
  >
    <sbdl-educational-details
      [properties]="resource.properties"
      [resourceType]="resource.type"
    ></sbdl-educational-details>

    <sbdl-related-resources
      [parentResource]="resource"
      [properties]="resource.properties"
      [resourceType]="resource.type"
      >
    </sbdl-related-resources>

  </sbdl-resource-properties>
</aside>
<aside
  class="resource-notes"
  [ngClass]="{
    hidden: !notesVisible,
    'has-notes': notes.length
  }"
>
  <button
    (click)="closeNotes()"
    aria-label="Close"
    buttonTitle="Close"
    tabindex="0"
    class="close-notes-top sbdl-icon-button material-icons"
    data-sbdl-dialog-button-default
    data-sbdl-dialog-initial-focus
  >
    <i class="far fa-times-circle"></i>
  </button>

  <sbdl-notes
    *ngIf="user"
    [notes]="notes"
    [resource]="resource"
    (notesChanged)="notesChanged($event)">
  </sbdl-notes>
  <sbdl-button tabindex="0" class="primary attachments-btn close-notes-bottom" (click)="closeNotes()">
    <span class="sbdl-button__label">Close Notes</span>
  </sbdl-button>
</aside>
<footer
  class="resource-footer"
  [ngClass]="{'reading-mode': readingMode, 'full-mode': !readingMode}">
  <sbdl-footer
  ></sbdl-footer>
</footer>
