import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Inject,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { PopoverOptions } from './popover.service';
import { take } from 'rxjs/operators';
import { MathJaxService } from '../../mathjax.service';

@Component({
  selector: 'sbdl-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss'],
})
export class PopoverComponent implements AfterViewInit {
  public isVisible: boolean;
  top: number;

  @HostBinding('style')
  cssVarStyle: SafeStyle;

  @Output()
  onClose = new EventEmitter();

  @ViewChild('closeButton', { static: false })
  closeButton: ElementRef;

  /**
   * The ng-template to display in the popover.
   */
  @Input()
  template: any;

  @Input()
  options = {} as PopoverOptions;

  @ViewChild('container', { static: false })
  container: ElementRef;

  @ViewChild('tooltipArrow', { static: false })
  tooltipArrow: ElementRef;

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.close();
  }

  constructor(
    @Inject('Window') private window: Window,
    private sanitizer: DomSanitizer,
    private mathJaxService: MathJaxService
  ) {}

  ngAfterViewInit(): void {
    this.mathJaxService
      .typeset()
      .pipe(take(1))
      .subscribe(() => {});

    if (this.options.offset) {
      this.updateOffset();
    }
  }

  @HostListener('document:click', ['$event.path'])
  onClickOutside($event: Array<any>) {
    if (this.isVisible) {
      if (!$event) {
        this.close();
        return;
      }

      const elementRefInPath = $event.find(
        (node) =>
          node.className &&
          node.className.indexOf &&
          node.className.indexOf('popover-container') !== -1
      );
      if (!elementRefInPath) {
        this.close();
      }
    }
  }

  inView(bounding: DOMRect, offset: any): boolean {
    return (
      offset.actualTop - bounding.height >= 0 &&
      offset.actualLeft - bounding.width / 2 >= 0 &&
      offset.actualLeft + bounding.width / 2 <= this.window.innerWidth
    );
  }

  close() {
    this.onClose.emit();
  }

  updateOffset() {
    const offset = this.options.offset;
    const rect = this.container.nativeElement.getBoundingClientRect();
    const height = rect.bottom - rect.top;
    this.top =
      this.options.placement === 'top' ? offset.top - height - 28 : offset.top;
    const position = this.options.isScrollable
      ? 'position: absolute'
      : 'position: fixed; z-index: 10';

    setTimeout(() => {
      const popoverDiff = this.getPopoverViewportXDiff();
      let leftOffset = offset.left;

      if (popoverDiff > 0) {
        leftOffset -= popoverDiff;
      }

      this.cssVarStyle = this.sanitizer.bypassSecurityTrustStyle(
        `${position}; top: ${this.top}px; left: ${leftOffset}px`
      );
      this.isVisible = true;

      // Update arrow
      if (popoverDiff > 0) {
        this.tooltipArrow.nativeElement.style.left = `${ rect.width / 2 + popoverDiff}px`;
      }

      if (this.options.placement === 'bottom'){
        this.tooltipArrow.nativeElement.style.top = `-5px`;
      }
    }, 0);

    setTimeout(() => {
      if (!this.inView(rect, this.options.offset)) {
        // this.container.nativeElement.scrollIntoView({
        //   behavior: "smooth",
        //   block: "start",
        //   inline: "nearest",
        // });
      }
      this.closeButton.nativeElement.focus();
    });
  }

  /**
   * The left and right coordinates of the popover component
   * Extra logic must be done as the `getBoundingClientRect` returns the relative positon coordinates
   * @returns
   */
  getPopoverLeftRight() {
    const offset = this.options.offset;
    const rect = this.container.nativeElement.getBoundingClientRect();

    const popoverLeft =
      offset.actualLeft + offset.width / 2 - rect.width / 2;
    const popoverRight =
      offset.actualLeft + offset.width / 2 + rect.width / 2;

    return { left: popoverLeft, right: popoverRight };
  }

  /**
   * We need to determine if the popover is going to be out of viewport, if so, we must shift it by x amount
   * A padding of 40px is added to compensate for the scrollbar
   * @returns delta of the x-axis of popover & viewport
   */
  getPopoverViewportXDiff() {
    const viewPortWidth = this.window.innerWidth;
    const { left, right } = this.getPopoverLeftRight();

    return right - (viewPortWidth - 40); // 40px padding for scrollbar
  }
}
