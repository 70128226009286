<article *ngIf="contentHtml" #sectionHeader class="section-container">
  <h2 class="leading-icon  {{ options.iconBackground }}">
    <i *ngIf="options.fontAwesomeIcon" class="far {{ options.fontAwesomeIcon }}"></i>
    <sbdl-icon *ngIf="options.sbdlIcon" [icon]="options.sbdlIcon"></sbdl-icon>
    {{ options.title }}
  </h2>
  <section>
    <dynamic-html [content]="contentHtml"></dynamic-html>
  </section>
</article>
<hr *ngIf="contentHtml"/>
