<sbdl-button-icon
  buttonTitle="Share"
  [attr.aria-label]="'share this resource'"
  cdkOverlayOrigin
  #trigger="cdkOverlayOrigin"
  (click)="shareOverlayOpen = !shareOverlayOpen"
>
  <div>
    <i class="far fa-share"></i>
  </div>

</sbdl-button-icon>

<ng-template
  cdkConnectedOverlay
  cdkConnectedOverlayPanelClass="windowed"
  cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
  [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayOpen]="shareOverlayOpen"
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayPositions]="shareOverlayPositions"
  [cdkConnectedOverlayPush]="true"
  (backdropClick)="shareOverlayOpen = false"
>
  <sbdl-share-form
    cdkTrapFocus
    cdkTrapFocusAutoCapture
    [value]="shareValue"
    (closed)="shareOverlayOpen = false"
  ></sbdl-share-form>
</ng-template>
<!-- (click)="togglePrintingMode()" -->

<sbdl-button-icon *ngIf='!printSafari' buttonTitle="Print" aria-label="print" (click)="printLandingPage()">
  <div>
    <i class="fas fa-print"></i>
  </div>
</sbdl-button-icon>

<sbdl-button-icon *ngIf='printSafari'
                  buttonTitle="Download"
                  aria-label="download"
                  (click)="printLandingPage()">
  <div>
    <i class="far fa-download"></i>
  </div>
</sbdl-button-icon>
