<h1 *ngIf="searchPage" class="search-title">Discover <span class="title-classroom">classroom </span>
  <span class="title-resources">resources</span> you can use today.</h1>
<div class="search-text-filters-container" [ngClass]="{'search-page': searchPage, 'nav-search': navSearch}">
  <sbdl-search-field
    [searchPage]="searchPage"
    [navSearch]="navSearch"
    (submit)="search({query: $event}, true); updateSearchText($event)"
    [(ngModel)]="typedText"
    [placeholder]="navSearch ? 'Start typing a skill, standard, or learning objective.' : 'What are you looking for? (e.g., fractions, research, shapes)'"
    fontAwesomeIcon="fa-search"
    [instanceId]="InstanceId"
  >
<!--    placeholder='Try a multi-word search! Combine key words like "Grade 3 ELA Categorizing Information" or "Math Grade 5 Graphing."'-->
  </sbdl-search-field>

  <ng-template #loginWarningPopover *ngIf="!navSearch">
    <sbdl-login-warning [queryParams]="rectifyParams({query: filters.query})"></sbdl-login-warning>
  </ng-template>

  <div class="caption" *ngIf="!searchPage && !navSearch">
    <a title="Advanced Search"
       (click)="search(params, true)"><i class="fas fa-filter advanced-icon"></i>
      Advanced
      Search</a>
  </div>

  <div *ngIf="searchPage">
    <div class="filter-container">
      <div #filterResourceType class="mat-chip-list-wrapper">
        <mat-chip color="primary" *ngFor="let resourceType of defaultFilters?.resourceTypes"
                  [value]="resourceType.code"
                  aria-current="true"
                  [attr.aria-current]="isResourceTypeSelected(resourceType.code) ? 'true' : 'false'"
                  disableRipple matRipple matRippleColor="#005F7B8a"
                  (click)="onChipSelectChange(resourceType.code, resourceType.title, 'filterResourceType')">
        <span class="sbdl-filter-title">
          <span *ngIf="isResourceTypeSelected(resourceType.code)" class="sbdl-chip-svg">
            <div class="check-border"><i class="far fa-check"></i></div>
          </span>
          <span *ngIf="!isResourceTypeSelected(resourceType.code)" class="sbdl-chip-svg">
            <div class="check-border"></div>
          </span>
          <span class="resource-type-title">{{ resourceType.title }}</span>
        </span>
        </mat-chip>
      </div>

      <div #filterContainer class="filter-container-wrapper" *ngIf="defaultFilters?.grades"
           [ngClass]="{'advanced-show': showAdvanced}">
        <div class="filter filter-grades title-above" *ngIf="defaultFilters?.grades">
          <span class="filter-label">Grade</span>
          <mat-form-field appearance="fill">
            <mat-label>Grade</mat-label>
            <mat-select multiple [(ngModel)]="getFilterByName('grades').selectedValues"
                        [compareWith]="compareWithFn"
                        (ngModelChange)="onSelectDropdownChange('grades', $event, 'filterContainer')">
              <mat-option *ngFor="let grade of defaultFilters?.grades" [value]="grade">
                {{ grade.title }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="filter filter-content-area title-above" *ngIf="combinedOptions.length">
          <span class="filter-label">Content Area</span>
          <mat-form-field appearance="fill">
            <mat-label>Content Area</mat-label>
            <mat-select multiple [(ngModel)]="getFilterByName('contentArea').selectedValues"
                        [compareWith]="compareWithFn"
                        (ngModelChange)="onSelectDropdownChange('contentArea', $event, 'filterContainer')">
              <mat-option *ngFor="let option of combinedOptions" [value]="option">
                {{ option.title }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="filter filter-targets title-above" *ngIf="defaultFilters?.targets">
          <span class="filter-label">Target</span>
          <mat-form-field appearance="fill">
            <mat-label>Target</mat-label>
            <mat-select multiple [(ngModel)]="getFilterByName('targets').selectedValues"
                        [compareWith]="compareWithFn"
                        (ngModelChange)="onSelectDropdownChange('targets', $event, 'filterContainer')">
              <mat-option *ngFor="let target of defaultFilters?.targets" [disabled]="target.disabled" [value]="target">
                {{target.title}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="filter filter-standards title-above" *ngIf="defaultFilters?.standards">
          <span class="filter-label">Standard</span>
          <mat-form-field appearance="fill">
            <mat-label>Standard</mat-label>
            <mat-select multiple [(value)]="getFilterByName('standards').selectedValues"
                        [compareWith]="compareWithFn"
                        (valueChange)="onSelectDropdownChange('standards', $event, 'filterContainer')">
              <mat-option *ngFor="let standard of defaultFilters?.standards" [disabled]="standard.disabled"
                          [value]="standard">
                {{ standard.title }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="filter filter-science-filters" *ngIf="containsSci()">
          Science Filters:
        </div>

        <div class="filter filter-dci-strands title-above" *ngIf="defaultFilters?.dciStrands.length > 0">
          <span class="filter-label">DCI Strand</span>
          <mat-form-field appearance="fill">
            <mat-label>DCI Strand</mat-label>
            <mat-select multiple [(ngModel)]="getFilterByName('dciStrands').selectedValues"
                        [compareWith]="compareWithFn"
                        (ngModelChange)="onSelectDropdownChange('dciStrands', $event, 'filterContainer')">
              <mat-option *ngFor="let dciStrand of defaultFilters?.dciStrands" [disabled]="dciStrand.disabled"
                          [value]="dciStrand">
                {{ dciStrand.title }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="filter filter-performance-expectations title-above"
             *ngIf="defaultFilters?.performanceExpectations.length > 0">
          <span class="filter-label">Performance Expectation</span>
          <mat-form-field appearance="fill">
            <mat-label>Performance Expectation</mat-label>
            <mat-select multiple [(ngModel)]="getFilterByName('performanceExpectations').selectedValues"
                        [compareWith]="compareWithFn"
                        (ngModelChange)="onSelectDropdownChange('performanceExpectations', $event, 'filterContainer')">
              <mat-option *ngFor="let performanceExpectation of defaultFilters?.performanceExpectations"
                          [disabled]="performanceExpectation.disabled" [value]="performanceExpectation">
                {{ performanceExpectation.title }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

      </div>

      <div class="mobile-caption" *ngIf="searchPage">
        <a title="{{ showAdvanced ? 'Close Advanced Filters' : 'Advanced Filters' }}"
           (click)="showAdvancedClick()"><i class="fas fa-filter advanced-icon"></i>
          {{ showAdvanced ? 'Close Advanced Filters' : 'Advanced Filters' }}
        </a>
      </div>

      <div class="applied-filters-wrapper">
        <div class="applied-filters-list" *ngIf="hasSelectedValues()">
          <div class="applied-filters-title">
            Applied Filters:
          </div>
          <div class="applied-filters-filters">
            <div *ngFor="let filter of selectedFilters">
              <div *ngIf="filter.selectedValues.length > 0">
                <ng-container *ngFor="let filterValue of filter.selectedValues">
                  <!-- Conditional rendering based on filter.type -->
                  <sbdl-button *ngIf="filter.type !== 'claims' && filter.type !== 'domains'"
                               class="filter-attachment-button"
                               buttonTitle="{{ filterValue.title | truncate:84:false:'...' }}"
                               aria-label="Close Filter"
                               (click)="removeFilterValue(filter.type, filterValue.code)">
                    {{ shouldUseCodeForTitle(filter.type)
                    ? (filterValue.code | truncate:90:false:'...')
                    : (filterValue.title | truncate:90:false:'...') }}
                    <i class="fal fa-times"></i>
                  </sbdl-button>
                </ng-container>
              </div>
            </div>
            <a (click)="clearAllFilters()" class="emphasis-medium caption applied-filters-clear" role="button">
              <i class="fa fa-undo" aria-hidden="true"></i> Clear Filters
            </a>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>

<div *ngIf="filterLoading" class="filter-loader">
  <div class="filter-spinner-container">
    <i class="fas fa-spinner fa-pulse" aria-label="Loading..."></i>
  </div>
</div>

