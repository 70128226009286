import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ChangeDetectorRef
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { FileType, ResourceAttachment } from 'src/app/data/resource/model/attachment.model';
import { DocumentSectionType } from 'src/app/resource/components/outline/document-outline.model';
import { PrintableSectionComponent } from 'src/app/resource/printable-section.component';
import {Resource} from '../../../data/resource/model/resource.model';
import {AttachmentsGalleryComponent} from './attachments-gallery/attachments-gallery.component';
import {MatDialog} from '@angular/material/dialog';

export enum CardType { File, Media, Link }

@Component({
  selector: 'sbdl-attachments',
  templateUrl: './attachments.component.html',
  styleUrls: ['./attachments.component.scss', '../../printable-section.component.scss']
})
export class AttachmentsComponent extends PrintableSectionComponent implements AfterViewInit, OnInit {

  @Input()
  attachments: ResourceAttachment[];

  @Input()
  resource: Resource;

  fileAttachments: ResourceAttachment[] = [];
  mediaAttachments: ResourceAttachment[] = [];
  linkAttachments: ResourceAttachment[] = [];
  CardType = CardType;
  isDialogOpen = false;

  constructor(
    sanitizer: DomSanitizer,
    public dialogAttachments: MatDialog
  ) {
    super(sanitizer, DocumentSectionType.Attachments);
  }

  ngOnInit(): void {
    this.attachments.forEach(a => {
      switch (a.fileType) {
        case FileType.VideoLink:
        case FileType.VimeoLink:
        case FileType.YouTubeLink:
        case FileType.Audio:
          this.mediaAttachments.push(a);
          break;
        case FileType.ExternalLink:
          this.linkAttachments.push(a);
          break;
        default:
          this.fileAttachments.push(a);
          break;
      }
    });
  }

  ngAfterViewInit(): void {
    if (this.headerElement) {
      this.sectionLoaded.emit({
        canPrint: false,
        component: this,
        elementRef: this.headerElement.nativeElement,
        fontAwesomeIcon: 'fa-paperclip',
        selectedForPrint: false,
        title: 'Attachments',
        type: DocumentSectionType.Attachments
      });
    }
  }

  openDialog(attachmentIndex: number) {
    if (!this.isDialogOpen) { // Check if the dialog is not already open
      this.isDialogOpen = true; // Set the flag to true indicating the dialog is open
      const dialogAttachmentsRef = this.dialogAttachments.open(AttachmentsGalleryComponent, {
        data: {
          attachmentIndex,
          fileAttachments: this.fileAttachments,
        },
        panelClass: 'attachments-modal'
      });

      dialogAttachmentsRef.afterClosed().subscribe(result => {
        this.isDialogOpen = false; // Set the flag to false when the dialog is closed
      });
    }
  }
}
