<h2>Log In for Complete Access</h2>
<p>
  The full content of this resource is available to educators in
  subscribing states and territories. Log in for access.
</p>
<sbdl-button
  class="primary"
  (click)="onLoginButtonClick()"
  [ngClass]="{
    processing: loading$ | async
  }"
>
  <span class="button-content">
    Log in
  </span>
  <span class="button-processing-content">
    <i class="fas fa-spinner fa-pulse"></i>
  </span>
</sbdl-button>
