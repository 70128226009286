<div class="dev-env-container">
  <img class="logo-full"
     [src]="(logo$ | async) || '/assets/svg/tft-logo-full.svg' | trustUrl"
     alt="Tools for Teachers from Smarter Balanced">
  <h1 class="capitalize">{{ envName }} Environment Site</h1>
  <h5>This site is just for development use.</h5>
  <h4>Please go to our main site to use Tools for Teachers:
      <a href='https://smartertoolsforteachers.org'>https://smartertoolsforteachers.org</a>
  </h4>
  <p>If you are a developer, you can log in here to see the development site:
  </p>
  <div *ngIf="displayError" class="mat-error">Username or password were incorrect.</div>
  <form (ngSubmit)="onSubmit()">
    <div class="dev-env-container-field">
      <label for="username">Username: </label>
      <input
        class="dev-env-container-input"
        type="text"
        id="username"
        name="username"
        [(ngModel)]="devEnvUsername"
        required>
    </div>
    <div class="dev-env-container-field">
      <label for="password">Password: </label>
      <input
        class="dev-env-container-input"
        type="password"
        id="password"
        name="password"
        [(ngModel)]="devEnvPassword"
        required>
    </div>
    <button type="submit" class="dev-env-container-button">
      Log in
    </button>
  </form>
</div>

