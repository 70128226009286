<ng-container *ngIf="url && url.endsWith('svg'); else notSvg">
  <object
    type="image/svg+xml"
    [data]="url | replaceNullImage | trustUrl"
    aria-hidden="true"
    tabindex="-1"
  ></object>
</ng-container>
<ng-template #notSvg>
  <img
    [src]="url | replaceNullImage | trustUrl"
    aria-hidden="true"
  >
</ng-template>
