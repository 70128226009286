<article #sectionHeader class="interim-container">
  
  <section class="interim-table">
    <h3>
      What are Interim Assessments?
    </h3>

    <sbdl-able-player
      class="video"
      [youtubeVideoId]="'Im-thuZCp4c'"
      [isTranscriptVisable]="isTranscriptVisable">
    </sbdl-able-player>

    <div>
      <p>
        <sbdl-button 
          *ngIf="isTranscriptVisable" 
          (click)="hideTranscript()"
          class="plain">
          <i class="far fa-compress-alt" aria-hidden="true"></i> Hide Transcript
        </sbdl-button>
        <sbdl-button 
          *ngIf="!isTranscriptVisable" 
          (click)="showTranscript()" 
          class="plain">
          <i class="far fa-expand-alt" aria-hidden="true"></i> Show Transcript
        </sbdl-button>
      </p>
    </div>

    <a href="https://portal.smarterbalanced.org/library/en/interim-assessments-overview.pdf"
         aria-label="Interim Overview Flier (opens in a new window)."
         target="_blank">
      <h4>Interim Overview Flier</h4>
    </a>
    <p>
      Dig into the details of the interim assessments available to you and browse a
      complete list of assessments by grade and subject.
    </p>

    <h3>Access Your Interim Assessments</h3>
    <p>
      Wondering how to access the interim assessments associated with this
      playlist? Go to your assessment portal to access the test administration
      system in your area. For assistance, contact your state or district
      representative.
      <a [href]="(contactUri$ | async) | trustUrl"
         aria-label="Find contact information here (opens in a new window)."
         target="_blank" >Find contact information here.</a>
    </p>
  </section>
</article>

