import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { DataService } from '../data.service';
import { Observable} from 'rxjs';
import { Alert, AllAlerts } from './alerts.model';

@Injectable({
  providedIn: 'root'
})

export class AlertsService {

  constructor(
    private dataService: DataService
    ) {
  }

  get = (): Observable<AllAlerts> => {
    return this.dataService
      .get(`/api/alerts`);
      // .pipe(
      //   map(this.alertFromJson)
      // );
  }

  private alertFromJson = (alertJson: any): AllAlerts => {

    return {
      ...alertJson
    } as AllAlerts;

  }
}
