// TODO
// replace need for these with the natural ordering information provided by a
// getSearchOptions() API endpoints to be introduced at a later time

/**
 * The natural ordering of resource types
 */
export const resourceTypeCodeOrdering: string[] = [
  'ir',
  'cp',
  'pl',
  'fs',
  'as'
];

/**
 * The natural ordering of grades
 */
export const gradeCodeOrdering: string[] = [
  'gk',
  'g1',
  'g2',
  'g3',
  'g4',
  'g5',
  'g6',
  'g7',
  'g8',
  'g9',
  'g10',
  'g11',
  'g12',
  'ghs'
];

/**
 * The ordering of subjects
 */
export const subjectCodeOrdering: string[] = [
  'ela',
  'math',
  'sci'
];

/**
 * The ordering of grades by short Description
 */
export const gradeCodeShortOrdering: string[] = [
  'K',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
  'HS'
];

export const resourceFieldOrdering: string[] = [
  'ess',
  'ls',
  'ps',
  'ets'
];
