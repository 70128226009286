<ul id="version-stamping" style="display:none">
    <li>Version: {{ appVersion.version }}</li>
    <li>Env Name: {{ envName }}</li>
    <li>Google Tag Manager: {{ googleIDEnv }}</li>
    <li>Hash: {{ appVersion.hash }}</li>
    <li>Raw: {{ appVersion.raw }}</li>
    <li>Tag: {{ appVersion.tag }}</li>
    <li>Semver: {{ appVersion.semver }}</li>
    <li>Semver String: {{ appVersion.semverString }}</li>
</ul>
<sbdl-svg-defs></sbdl-svg-defs>
<sbdl-overlay></sbdl-overlay>
<router-outlet></router-outlet>
