import {Injectable} from '@angular/core';
import {EMPTY, Observable, of} from 'rxjs';
import {expand} from 'rxjs/internal/operators/expand';
import {map} from 'rxjs/internal/operators/map';
import {reduce} from 'rxjs/internal/operators/reduce';
import {DataService} from '../data.service';
import {SearchResults} from './search-results.model';
import {Claim} from '../resource/model/claim.model';
import {Grade} from '../resource/model/grade.model';
import {Subject} from '../resource/model/subject.model';
import {Standard} from '../resource/model/standard.model';
import {Target} from '../resource/model/target.model';
import {Filter, SearchFilters} from './search-filters.model';
import {ResourceSummary} from '../resource/model/summary.model';
import {
  gradeCodeOrdering,
  gradeCodeShortOrdering,
  resourceTypeCodeOrdering,
  subjectCodeOrdering,
  resourceFieldOrdering
} from '../resource/resource-field-orderings';
import {byOrdering, byString, byStringWithNumber, Comparator, on} from '../../common/sorting/sorting';
import {UserService} from '../user/user.service';
import {shareReplay, switchMap, tap} from 'rxjs/operators';
import {Category} from '../resource/model/category.model';
import {Domain} from '../resource/model/domain.model';
import {DCIStrand} from '../resource/model/dciStrand.model';
import {PerformanceExpectation} from '../resource/model/performanceExpectation.model';
import {SortingService} from '../../common/sorting/sorting.service';
import {BumpySearchResults} from './bumpy-search-results.model';
import {HttpParams} from '@angular/common/http';
import {UaagTier} from '../resource/model/uaagTier.model';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  constructor(
    private dataService: DataService,
    private userService: UserService,
    private sorting: SortingService
  ) {
    // Creating an initial default filters for use in many methods - shareReplay means we only call it once
    if (!this.defaultFilters) {
      this.defaultFilters = this.dataService.get('/api/search_filters').pipe(
        map((defaultFilters: SearchFilters) => {
          return this.sortFilters(this.extractDefaultFilters(defaultFilters));
        }),
        shareReplay(1) // Cache the latest value and replay it to new subscribers
      );
    }
  }

  private searchUrl = '/api/search_dl_resources';
  private defaultFilters: Observable<SearchFilters>;

  // All the sorts
  private byCode: Comparator<Filter> = on(x => x.code, byString());
  private byResourceTypeCode: Comparator<Filter> = on(x => x.code, byOrdering(resourceTypeCodeOrdering));
  private byResourceFieldOrdering: Comparator<Filter> = on(x => x.code, byOrdering(resourceFieldOrdering));
  private byGradeCode: Comparator<Filter> = on(x => x.code, byOrdering(gradeCodeOrdering));
  private byGradeCodeShort: Comparator<Filter> = on(x => x.grade, byOrdering(gradeCodeShortOrdering));
  private bySubjectCode: Comparator<Filter> = on(x => x.subject, byOrdering(subjectCodeOrdering));
  private byNumber: Comparator<Filter> = on(x => x.number, byStringWithNumber());

  // Create the defaultFilters as a SearchFilters type we'll use elsewhere
  public getDefaultFilters(): Observable<SearchResults> {
    return this.defaultFilters.pipe(
      map((filters: SearchFilters) => ({
        results: [],
        filters,
        defaultFilters: filters,
      }))
    );
  }

  // This helps determine if we're going to do a full search or not - if not we just return the
  // default filters, if we are then we do a full search.
  fetchSearchResult(request: HttpParams, isDefaultFilter: boolean): Observable<SearchResults> {
    if (isDefaultFilter) {
      // Get the default filters data and map it to SearchResults
      return this.getDefaultFilters();
    } else {
      // Perform a search using searchResourcesPaginate
      return this.searchResourcesPaginate(request, this.searchUrl);
    }
  }

  // This is used on the landing pages to reduce filters there to just the type of the landing page
  public getFilterWithParams(params): Observable<SearchResults> {
    return this.dataService.get('/api/search_filters_params', params)
      .pipe(
        map((defaultFilters) => ({
          results: [],
          filters: this.sortFilters(this.extractDefaultFilters(defaultFilters))
        })));
  }

  // This is the most important function, it performs the search and returns the results. It also runs
  // the helper functions that determine what is available for filters.
  private searchResourcesPaginate(request: HttpParams, url: string): Observable<SearchResults> {
    // Retrieve default filters from this.defaultFilters and flatten the inner observable
    const results = this.defaultFilters.pipe(
      switchMap(defaultFilters => {
        return this.dataService.get(url, request).pipe(
          expand(page => {
            // Continue to try to actually fetch the next page as long as we have a next link.
            // The previous call returns a value 'hydra:next' if there are more results.
            return this.hasNextPage(page) ?
              this.dataService.get(page['hydra:view']['hydra:next']) :
              EMPTY;
          }),
          tap(finalData => {
          }),
          reduce(
            (searchFilters, page) => {
              // These two create the filters

              searchFilters.results.push(...page['hydra:member'].map(this.resourceSummaryFromJson));
              searchFilters.filters.push(...page['hydra:member'].map(this.extractFilters));
              return searchFilters;
            },
            {results: [], filters: [], defaultFilters: []}
          ),
          map((bumpy: BumpySearchResults): SearchResults => ({
            // This takes the bumpy results, de-dupes and then sorts the filters
            results: bumpy.results,
            filters: this.sortFilters(this.dedupeFilters(bumpy.filters, request.get('Search_Text'))),
            defaultFilters // Use the defaultFilters retrieved earlier
          })),
          tap(finalData => {
          })
        );
      })
    );
    return results;
  }

  // This gets the params ready to be sent to the API which uses different naming convention.
  // The api also require that the parameters are sent in array notation which looks like:
  // api/search_dl_resources?Target[]=ela-c1-g3-t14&Target[]=ela-c1-g3-t10&Standard[]=ela-g3-l4
  // Note that the array keys are repeated (In our example 'Target'). This allows for multiple
  // values to be sent to the search
  public paramsToHttpParams(params: any): HttpParams {
    let httpParams = new HttpParams();
    // This maps to the names in the API
    const keyMapping = {
      grades: 'Grade',
      resourceTypes: 'Resource_Type',
      claims: 'Claim',
      domains: 'Domain',
      dciStrands: 'DciStrand',
      performanceExpectations: 'PerformanceExpectation',
      standards: 'Standard',
      targets: 'Target',
      subjects: 'Subject'
    };
    // Add the query text if there is one
    if (params.query) {httpParams = httpParams.append('Search_Text', params.query);}

    const appendParamsInArrayNotation = (key: string, value: string | string[]) => {
      // Transform the key using the mapping, defaulting to the original key if not found in the mapping
      const transformedKey = keyMapping[key] || key;
      if (typeof value === 'string') {
        value.split(',').forEach(item => {
          httpParams = httpParams.append(`${transformedKey}[]`, item.trim());
        });
      } else if (Array.isArray(value)) {
        value.forEach(item => {
          httpParams = httpParams.append(`${transformedKey}[]`, item);
        });
      }
    };

    // Iterate over all properties in the params object to make them the right format, except 'query'
    Object.keys(params).forEach(key => { if (key !== 'query') {appendParamsInArrayNotation(key, params[key]); }});
    return httpParams;
  }

  private hasNextPage(page: any): boolean {
    return page['hydra:view'].hasOwnProperty('hydra:next');
  }

  // Maps one JSON object representing a search result item from the API into our ResourceSummary object model.
  private resourceSummaryFromJson = (json: any): ResourceSummary => {
    return {
      id: json.resourceId,
      properties: {
        authorOrg: json.authorOrganization,
        authors: json.resourceAuthors.map(ra => ra.name),
        categories: json.categories ? json.categories.map(this.categoryFromJson) : [],
        claims: json.claims.map(this.claimFromJson),
        domains: json.domains.map(this.domainFromJson),
        dciStrands: json.dciStrands.map(this.dciStrandFromJson),
        performanceExpectations: json.performanceExpectations.map(this.performanceExpectationFromJson),
        grades: json.grades.map(this.gradeFromJson),
        standards: json.standards.map(this.standardFromJson),
        targets: json.targets.map(this.targetFromJson),
        title: json.title,
        image: json.imagePath,
        lastUpdatedDate: new Date(json.updatedAt),
        subject: this.subjectFromJson(json.subject),
      },
      type: json.dlResourceType.description,
      summary: this.summaryFromJson(json),
      uaagTiers: json.uaagTiers ? json.uaagTiers.map(this.uaagTierFromJson) : [],
      accessibilityCategories: json.accessibilityCategories ? json.accessibilityCategories.map(this.accessibilityCategoriesFromJson) : [],
      professionalLearningCategories: json.professionalLearningCategories ? json.professionalLearningCategories.map(this.professionalLearningCategoriesFromJson) : [],
    };
  }

  private summaryFromJson(json: any): string {
    const resourceContentKeys = [
      ['dlResourceContentIr', 'overview'],
      ['dlResourceContentPl', 'overview'],
      ['dlResourceContentCp', 'description'],
      ['dlResourceContentStrategy', 'overview']];

    for (const [key, field] of resourceContentKeys.values()) {
      if (json[key]) {
        return json[key][field];
      }
    }
    return '';
  }

  private categoryFromJson(jsonCategory: any): Category {
    return {
      code: jsonCategory.code,
      description: jsonCategory.description,
      title: jsonCategory.title
    };
  }

  private claimFromJson(jsonClaim: any): Claim {
    return {
      code: jsonClaim.code,
      number: jsonClaim.sequenceNo,
      title: jsonClaim.description,
      description: jsonClaim.description,
      longDescription: jsonClaim.longDescription
    };
  }

  private domainFromJson(jsonDomain: any): Domain {
    return {
      code: jsonDomain.code,
      shortDescription: jsonDomain.shortDescription,
      description: jsonDomain.description
    };
  }

  private dciStrandFromJson(jsonDCIStrand: any): DCIStrand {
    return {
      code: jsonDCIStrand.code,
      shortDescription: jsonDCIStrand.shortDescription,
      description: jsonDCIStrand.description
    };
  }

  private performanceExpectationFromJson(jsonPerformanceExpectation: any): PerformanceExpectation {
    return {
      code: jsonPerformanceExpectation.code,
      shortDescription: jsonPerformanceExpectation.shortDescription,
      description: jsonPerformanceExpectation.description
    };
  }

  private gradeFromJson(jsonGrade: any): Grade {
    return {
      code: jsonGrade.code,
      shortName: jsonGrade.shortDescription,
      longName: jsonGrade.description
    };
  }

  private standardFromJson(jsonStd: any): Standard {
    return {
      code: jsonStd.code,
      title: jsonStd.standard,
      description: jsonStd.description
    };
  }

  private targetFromJson(jsonTgt: any): Target {
    return {
      code: jsonTgt.code,
      number: jsonTgt.number,
      description: jsonTgt.description
    };
  }

  private subjectFromJson(jsonSub: any): Subject {
    if (!jsonSub) {
      return {
        code: null,
        shortName: null,
        fullName: null
      };
    } else {
      return {
        code: jsonSub.code,
        shortName: jsonSub.shortDescription,
        fullName: jsonSub.description
      };
    }
  }

  private uaagTierFromJson(jsonUaagTier: any): UaagTier {
    return {
      code: jsonUaagTier.code,
      description: jsonUaagTier.description,
      title: jsonUaagTier.shortDescription
    };
  }

  private accessibilityCategoriesFromJson(jsonAccessibilityCategories: any): Category {
    return {
      code: jsonAccessibilityCategories.code,
      description: jsonAccessibilityCategories.description,
      title: jsonAccessibilityCategories.shortDescription
    };
  }

  private professionalLearningCategoriesFromJson(jsonProfessionalLearningCategories: any): Category {
    return {
      code: jsonProfessionalLearningCategories.code,
      description: jsonProfessionalLearningCategories.description,
      title: jsonProfessionalLearningCategories.shortDescription
    };
  }

  // The filters are made from what's available from the search
  // This list of what's available is used to show or hide filters that are unavailable
  private extractFilters(res: any): SearchFilters {
    const hasSubject = res.subject;
    const contentAreaClaims = res.claims.map(c => ({
      code: c.code,
      title: c.code.split('-')[0].charAt(0).toUpperCase() + c.code.split('-')[0].slice(1) + ' - ' + c.sequenceNo + ' ' + c.description
    }));
    const contentAreaDomains = res.domains.map(d => ({
      code: d.code,
      title: `Science - ${d.title}`
    }));
    const contentAreaCombined = [...contentAreaClaims, ...contentAreaDomains];
    return {
      query: '',
      resourceTypes: [{code: res.dlResourceType.code, title: res.dlResourceType.description}],
      grades: res.grades.map(g => ({code: g.code, title: g.description})),
      subjects: hasSubject ? [{ code: res.subject.code, title: res.subject.description }] : [],
      claims: res.claims.map(c => ({code: c.code, title: `${c.sequenceNo}: ${c.description}`})),
      domains: res.domains.map(d => ({code: d.code, title: d.description})),
      dciStrands: res.dciStrands.map(ds => ({code: ds.code, title: ds.shortDescription + ' ' + ds.description})),
      performanceExpectations: res.performanceExpectations.map(pe => ({
        code: pe.code,
        title: `${pe.shortDescription} - ${pe.description}`
      })),
      targets: res.targets.map(t => ({
        code: t.code,
        title: `${t.number} - ${t.description} (${t.grade} ${t.claim})`,
        number: t.number, grade: t.gradeShort, subject: t.code.split('-')[0]
      })),
      standards: res.standards.map(s => ({
        code: s.code,
        title: `${s.standard}`
      })),
      contentArea: contentAreaCombined
    };
  }

  // Default filters have a slightly different naming convention: resourceType and subject instead
  // of resourceTypes and subjects.
  public extractDefaultFilters(res: any) {
    const contentAreaClaims = res.claims.map(c => ({
      code: c.code,
      title: c.code.split('-')[0].charAt(0).toUpperCase() + c.code.split('-')[0].slice(1) + ' - ' + c.sequenceNo + ' ' + c.description
    }));
    const contentAreaDomains = res.domains.map(d => ({
      code: d.code,
      title: `Science - ${d.description}`
    }));
    const contentAreaCombined = [...contentAreaClaims, ...contentAreaDomains];
    return {
      query: '',
      resourceTypes: res.resourceType.map(rt => ({code: rt.code, title: rt.description})),
      grades: res.grades.map(g => ({code: g.code, title: g.description})),
      subjects: res.subject.filter(s => s.code !== null && (s.code === 'math' || s.code === 'ela' || s.code === 'sci'))
        .map(s => ({code: s.code, title: s.description})),
      claims: res.claims.map(c => ({code: c.code, title: `${c.subject} - ${c.sequenceNo}. ${c.description}`})),
      domains: res.domains.map(d => ({code: d.code, title: `${d.subject} - ${d.description}`})),
      dciStrands: res.dciStrands.map(dc => ({code: dc.code, title: `${dc.shortDescription} - ${dc.description}`})),
      performanceExpectations: res.performanceExpectations.map(pe => ({
        code: pe.code,
        title: `${pe.shortDescription} - ${pe.description}`
      })),
      targets: res.targets.map(t => ({
        code: t.code,
        title: `${t.number} - ${t.description} (${t.grade} ${t.claim})`,
        number: t.number, grade: t.gradeShort, subject: t.code.split('-')[0]
      })),
      standards: res.standards.map(st => ({
        code: st.code,
        title: `${st.standard}`
      })),
      contentArea: contentAreaCombined
    };
  }

  // Remove duplicate results once we've looked through all the search results
  private dedupeFilters(filtersList: SearchFilters[], origQuery: string): SearchFilters {
    const result = {query: origQuery} as SearchFilters;
    const codeSets: Set<string>[] = [];

    // because I don't want to write this all out exactly the same six times
    const filterKeys = ['claims', 'grades', 'resourceTypes', 'standards', 'subjects',
      'targets', 'domains', 'dciStrands', 'performanceExpectations', 'contentArea'];

    for (const key of filterKeys) {
      result[key] = [];
      codeSets[key] = new Set<string>();  // we're going to use sets to speed up the deduping
    }

    // This looks O(n^3) but it's actually O(n) with n = the total # number of
    // filter values across returned resources.
    for (const filters of filtersList) {        // loop over our sets of filters
      for (const key of filterKeys) {           // for every type of filter (claims, grades, etc.)
        for (const value of filters[key]) {     // look at every filter value from the resource
          if (!codeSets[key].has(value.code)) { // if we haven't seen this value before
            result[key].push(value);            // add it to our final result set
            codeSets[key].add(value.code);      // and mark that we've seen it
          }
        }
      }
    }
    return result;
  }

  // This sets all the filter sorts (most of these are at the top of the file)
  private sortFilters(filters: SearchFilters): SearchFilters {
    this.sorting.customOrder(filters.domains, resourceFieldOrdering);
    this.sorting.customOrder(filters.dciStrands, resourceFieldOrdering);
    this.sorting.customOrder(filters.performanceExpectations, resourceFieldOrdering);

    const sortedResourceTypes = [...filters.resourceTypes || []].sort(this.byResourceTypeCode);
    const sortedGrades = [...filters.grades || []].sort(this.byGradeCode);
    const sortedSubjects = [...filters.subjects || []].sort(this.byCode);
    const sortedClaims = [...filters.claims || []].sort(this.byCode);
    const sortedDomains = [...filters.domains || []].sort(this.sorting.orderMultiples);
    const sortedDCIStrands = [...filters.dciStrands || []].sort(this.sorting.orderMultiples);
    const sortedPerformanceExpectations = [...filters.performanceExpectations || []].sort(this.sorting.orderMultiples);
    const sortedStandards = [...filters.standards || []].sort(this.byCode);

    // Sorting targets by grade, then by subject, and finally by number
    const sortedTargets = [...filters.targets || []].sort((a, b) => {
      let result = this.byGradeCodeShort(a, b); // First, compare by grade
      if (result === 0) { // If grades are equal
        result = this.bySubjectCode(a, b); // Then, compare by subject
        if (result === 0) { // If subjects are also equal
          result = this.byNumber(a, b); // Then, compare by number
        }
      }
      return result;
    });

    // Return the filters with sorted arrays
    return {
      ...filters,
      resourceTypes: sortedResourceTypes,
      grades: sortedGrades,
      subjects: sortedSubjects,
      claims: sortedClaims,
      domains: sortedDomains,
      dciStrands: sortedDCIStrands,
      performanceExpectations: sortedPerformanceExpectations,
      targets: sortedTargets,
      standards: sortedStandards,
    };
  }
}
