import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SearchTextService {
  private activeInstanceSubject = new BehaviorSubject<number | null>(null);
  activeInstance$ = this.activeInstanceSubject.asObservable();

  private searchTextSubject = new BehaviorSubject<string>('');
  searchText$ = this.searchTextSubject.asObservable();

  updateActiveInstance(instanceId: number) {
    this.activeInstanceSubject.next(instanceId);
  }

  updateSearchText(newValue: string) {
    this.searchTextSubject.next(newValue);
  }
}
