<article #sectionHeader *ngIf="steps.length > 0" class="step-container">
  <h2 class="leading-icon">
    <sbdl-icon icon="steps"></sbdl-icon>
      Step-by-Step
      <sbdl-tooltip
        text="The process for implementing this resource in your class."
      ><sbdl-icon class="tooltip-icon" icon="info"></sbdl-icon></sbdl-tooltip>
  </h2>
  <ol>
    <li sbdl-step *ngFor="let step of steps"
               [stepModel]="step"
               (sectionLoaded)="addSubsection($event)"></li>
  </ol>
</article>
<hr *ngIf="steps.length > 0"/>
