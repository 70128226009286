import {Component, Input, OnInit} from '@angular/core';
import { RelatedResources } from 'src/app/data/resource/model/related-resources';
import { Resource } from '../../../data/resource/model/resource.model';
import { ResourceType } from 'src/app/data/resource/model/resource-type.enum';
import { ResourceProperties } from '../../../data/resource/model/properties.model';
import { ProfessionalLearningResource } from '../../../data/resource/model/professional-learning.model';


@Component({
  selector: 'sbdl-related-resources',
  templateUrl: './related-resources.component.html',
  styleUrls: ['./related-resources.component.scss']
})
export class RelatedResourcesComponent implements OnInit {

  @Input()
  properties: ResourceProperties;

  @Input()
  resourceType: string;

  @Input()
  resource: Resource;

  @Input()
  parentResource: ProfessionalLearningResource;

  constructor() { }

  ngOnInit() {
    // console.log('Properties', this.properties);
    // console.log('Resource Type', this.resourceType);
    // console.log('Related Resources', this.parentResource.relatedProfLearningResources);
    // console.log('Resource', this.parentResource);
  }

}
