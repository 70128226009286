<sbdl-protected-content
  [authenticated]="!resource.teaser"
>
  <sbdl-playlist-topics
    [readingMode]="readingMode"
    [resource]="resource"
    (sectionLoaded)="addDocumentSection($event)"
  ></sbdl-playlist-topics>

  <sbdl-playlist-intervention
    [content]="resource.overview.interventionSuggestions"
    (sectionLoaded)="addDocumentSection($event)">
  </sbdl-playlist-intervention>

  <sbdl-simple-section
    [contentHtml]="resource.thingsToConsider"
    [options]="commentsSectionOptions"
    (sectionLoaded)="addDocumentSection($event)"
  ></sbdl-simple-section>

  <sbdl-playlist-background
    [overview]="resource.overview"
    [type] = "resource.type"
    [prefilteredIaipLink] = "resource.prefilteredIaipLink"
    (sectionLoaded)="addDocumentSection($event)"
  ></sbdl-playlist-background>

  <sbdl-playlist-interim
    (sectionLoaded)="addDocumentSection($event)">
  </sbdl-playlist-interim>
</sbdl-protected-content>
