<footer role="contentinfo" aria-label="footer">
  <div [ngClass]="{'footer-links': true, 'no-tenant': !(user$ | async)}">
    <div *ngIf="user$ | async">
      <h2>{{tenantName$ | async}} Resources</h2>
      <ul>
        <li *ngFor="let link of (tenantFooterLinks$ | async)">
          <a [href]="link.uri"
             target="_blank"
             [attr.aria-label]="link.name + ' (opens in a new window'">
            <span *ngIf="link.icon"><i [ngClass]="link.icon"></i></span>
            {{ link.name }}
          </a>
        </li>
      </ul>
    </div>
    <div class="more-tools-for-teachers">
      <h2>More Tools for Teachers</h2>
      <ul>
        <li><a target="_blank" aria-label="Content Explorer (opens in a new window)"href="https://contentexplorer.smarterbalanced.org/">Content Explorer</a></li>
        <li><a target="_blank" aria-label="SmART (opens in a new window)" href="https://smart.smarterbalanced.org">SmART</a></li>
        <li><a target="_blank" aria-label="Sample Items Website (opens in a new window)" href="https://sampleitems.smarterbalanced.org">Sample Items Website</a></li>
        <li><a target="_blank" aria-label="Remote Administration (opens in a new window)" href="https://remote.smartertoolsforteachers.org/summative">Remote Administration</a></li>
        <!--
          TODO: Re-enable when the proper link is known.
          <li><a target="_blank" aria-label="Get Involved! (opens in a new window)" href="https://www.smarterbalanced.org/about">Get Involved!</a></li>
        -->
      </ul>
    </div>
    <div class="connect">
      <h2>Connect</h2>
        <ul>
            <li *ngFor="let link of (tenantFooterSocialLinks$ | async)">
              <a [href]="link.uri"
                 target="_blank"
                 [attr.aria-label]="link.name + ' (opens in a new window'">
                <span *ngIf="link.icon"><i [ngClass]="link.icon"></i></span>
                {{ link.name }}
              </a>
            </li>
          </ul>
    </div>
    <div class="policy">
      <h2>Policy</h2>
      <ul>
        <li><a target="_blank" aria-label="Terms & Conditions (opens in a new window)"href="https://remote.smartertoolsforteachers.org/terms-of-service/">Terms & Conditions</a></li>
        <li><a target="_blank" aria-label="Diversity, Inclusion & Equity (opens in a new window)"href="https://smarterbalanced.org/equity/">Diversity, Inclusion & Equity</a></li>
        <li><a target="_blank" aria-label="Accessibility Statement (opens in a new window)" href="https://smarterbalanced.org/web-accessibility-statement/">Accessibility Statement</a></li>
        <li><a target="_blank" aria-label="Privacy (opens in a new window)" href="https://www.smarterbalanced.org/privacy-policy/">Privacy</a></li>
      </ul>
    </div>
  </div>
  <hr/>
  <div class=copyright>
    <img src="/assets/images/SmarterBalanced_Logo_Horizontal_Color.png"
         alt="Smarter Balanced Assessment Consortium Logo">
    <img *ngIf="user$ && tenantFooterLogo$ | async"
         [src]="(tenantFooterLogo$ | async) | trustUrl"
         alt="{{ (tenantName$ | async) }} Logo">
    <span>© {{copyrightYear}} The Regents of the University of California</span>
  </div>
</footer>
