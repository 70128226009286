import { Component, Input, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AlertsService } from '../data/alerts/alerts.service';
import { Alert } from '../data/alerts/alerts.model';
import { Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'sbdl-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss']
})

export class AlertsComponent implements OnInit {

  constructor(private alertsService: AlertsService, @Inject(DOCUMENT) document) {}

  @Input()
  alert: Alert;

  alerts: any;
  alertsToShow = [];
  marginAmount: string;
  className = '';

  numberOfAlertsToShow = this.alertsToShow.length;

  ngOnInit() {
    this.listAlerts();
    setTimeout(() => {
      this.className = 'open';
    }, 500);
  }

  listAlerts() {
    this.alertsService.get().subscribe(alert => {
      this.alerts = alert;
      this.alertsToShow = [];
      this.alerts.forEach( (alertItem) => {
        if (sessionStorage.getItem('alertDismiss-' + alertItem.alertId) === null) {
          this.alertsToShow.push(alertItem);
          // console.log('Number of Alerts to show', this.alertsToShow.length);
          this.marginAmount = 'alert-' + this.alertsToShow.length;
        }
      });
    });
  }

  dismissAlert(alertIdNumber) {
    this.listAlerts();
    sessionStorage.setItem('alertDismiss-' + alertIdNumber, 'false');
    document.getElementById('alert-' + alertIdNumber).classList.add('hide');
  }
}
