import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NavVisibilityService {
  private visibilitySource = new BehaviorSubject('closed');
  currentVisibility = this.visibilitySource.asObservable();

  constructor() { }

  /**
   * Sets the visibility based on the provided visibility state.
   * If the visibility state is set to 'closed' this will close the menu, if it's set
   * to anything else it will open the corresponding panel in side-navigation component.
   * If you put in an incorrect value it can mess up the nav, so use only correct values,
   * which is either 'closed', 'lesson', 'professional', or 'assessment'. See
   * webapp/src/app/layout/navigation/side-navigation/side-navigation.component.html for values.
   */
  setVisibility(navVisibilityState: string) {
      this.visibilitySource.next(navVisibilityState);
  }

  getVisibility() {
    return this.visibilitySource.value;
  }

  setNavHidden() {
    this.visibilitySource.next('closed');
  }

  toggleNavVisibility(visibilitySection: string) {
    if (this.visibilitySource.value === visibilitySection && this.visibilitySource.value !== 'closed') {
      this.setVisibility('closed');
    } else {
      this.setVisibility(visibilitySection);
    }
  }
}
