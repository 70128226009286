<!-- Deeper section -->
<div *ngIf="landingPage.diveDeeperSection && landingPage.diveDeeperSection.links.length > 0" class="rightSideSections">

    <h2 class="dive-deeper">Dive Deeper</h2>
    <p style="margin-right: 1rem;">
        {{landingPage.diveDeeperSection.description}}
    </p>

    <ul>
        <li *ngFor="let dd of landingPage.diveDeeperSection.links"
            style="margin-bottom: 5px;margin-left: -20px;">
          <div *ngIf="dd.url | fileExtension; else noGA">
            <a href={{dd.url}} (click)="gtmEvent(dd.url, dd.label)" googleanalytics style="display: inline;" target="_blank">
              {{dd.label}}
            </a>
          </div>
          <div>
          <ng-template #noGA>
            <a *ngIf="dd.url | internalLinks; else externalLink"
               [routerLink]='[(dd.url | internalLinks)]'
               style="display: inline;">
              {{dd.label}}
            </a>
            <ng-template #externalLink>
              <a href={{dd.url}} style="display: inline;">
                {{dd.label}}
              </a>
            </ng-template>
          </ng-template>
          </div>

        </li>
    </ul>
</div>
