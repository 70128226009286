import {APP_BASE_HREF} from '@angular/common';
import {Inject, Injectable, OnDestroy} from '@angular/core';
import {Observable, ReplaySubject, Subject} from 'rxjs';
import {TenancyChainEntity, User, UserTenancy} from './user.model';
import {TftError, TftErrorType} from 'src/app/common/tft-error-type.enum';
import {TftErrorService} from 'src/app/common/tft-error.service';
import {StorageService} from '../../common/storage.service';
import {Router} from '@angular/router';
import {OKTA_AUTH, OktaAuthStateService} from '@okta/okta-angular';
import {AuthState, OktaAuth} from '@okta/okta-auth-js';
import {takeUntil} from 'rxjs/operators';

/**
 */
@Injectable({
  providedIn: 'root'
})
export class UserService implements OnDestroy {

  /**
   * ReplaySubject is a Subject (see https://rxjs.dev/guide/subject) that
   * remembers the last n (here n = 1) values that passed through it. We use
   * this to cache the user object. We expose this as our `user` property.
   *
   * When a caller subscribes, if we have already had at least one value they
   * will immediately receive our most recent value. It we have not yet
   * received a value (page load for example) then they will wait for us to
   * produce a value. For example, see the DataService's usage.
   */
  private user$: ReplaySubject<User> = new ReplaySubject(1);
  private hasOktaAuthToken$: ReplaySubject<boolean> = new ReplaySubject(1);
  private hasIaipRole$: ReplaySubject<boolean> = new ReplaySubject(1);
  private tokenExpirationTimer: any;
  private userInfo: any; // This is the UserClaims type, but that doesn't have the custom claim for sbacTenancyChain
  private isAuthenticated: boolean;
  private authState: ReplaySubject<boolean> = new ReplaySubject(1);
  private unsubscribe$: Subject<void> = new Subject();

  static parseSbacTenancyChain(sbacTenancyChain: string[]): UserTenancy[] {
    const validTenancies: UserTenancy[] = [];

    const extractEntity = (segments: string[], idx: number): TenancyChainEntity => {
      if (segments[idx] && segments[idx].length > 0) {
        return { id: segments[idx], name: segments[idx + 1] };
      } else {
        return undefined;
      }
    };

    sbacTenancyChain.forEach(chain => {
      if (chain.toLowerCase().includes('dl_enduser')) {
        const segments = chain.split('|');

        validTenancies.push({
          role: extractEntity(segments, 1),
          level: extractEntity(segments, 3),
          client: extractEntity(segments, 4),
          stateGroup: extractEntity(segments, 6),
          state: extractEntity(segments, 8),
          districtGroup: extractEntity(segments, 10 ),
          district: extractEntity(segments, 12),
          institutionGroup: extractEntity(segments, 14),
          institution: extractEntity(segments, 16),
        });
      }
    });

    return validTenancies;
  }

  static parseSbacTenancyChainForIAIP(sbacTenancyChain: string[]): boolean {
    return sbacTenancyChain.some(chain => {
      return chain.toLowerCase().includes('sb_iaip_user');
    });
  }

  static validateUserSession(user: User): TftError | null {
    if (!user.accessToken) {
      return {
        type: TftErrorType.AuthNoAppAccess,
        details: 'User has no access token.'
      };
    }
  }

  constructor(
    @Inject(APP_BASE_HREF) private baseHref,
    private errorService: TftErrorService,
    private storageService: StorageService,
    private route: Router,
    private oktaAuthStateService: OktaAuthStateService,
    @Inject(OKTA_AUTH) private oktaAuthService: OktaAuth,
  ) {
    this.oktaAuthStateService.authState$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(authState => {
        this.isAuthenticated = authState.isAuthenticated;
        this.authState.next(this.isAuthenticated);
        if (this.isAuthenticated) {
          const accessToken = this.oktaAuthService.getAccessToken();
          this.updateUser();
        } else {
          this.user$.next(null);
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  get user(): Observable<User> {
    return this.user$.asObservable();
  }

  get authenticated(): Observable<boolean> {
    return this.authState.asObservable();
  }

  get hasOktaAuthToken(): boolean{
    // Subscribe to authentication state changes
    this.oktaAuthStateService.authState$.subscribe( authState  => {
      this.isAuthenticated = authState.isAuthenticated;
    });
    return this.isAuthenticated;
  }

  get hasIaipRole(): Observable<boolean> {
    return this.hasIaipRole$.asObservable();
  }

  /**
   * Update our ReplaySubject with new User information depending on the new
   * auth state. This is the meat and potatoes of this service. This needs to
   * run to update the youth service. Additionally, it updates new iaipRole status.
   */
  public updateUser = async () => {
    await this.readUserFromOkta().then(
      user => {
        if (user.accessToken) {
          if (user.tenantIds.length === 0) {
            // This is a special case: valid user but no DL_EndUser. Allow them to progress
            // like an anonymous user.
            this.user$.next(null);
          } else {
            const iaipCheck = UserService.parseSbacTenancyChainForIAIP(this.userInfo.sbacTenancyChain);
            if (iaipCheck) {
              this.hasIaipRole$.next(this.isAuthenticated);
            }
            this.setTokenExpirationTimer();
            this.user$.next(user);
          }
        } else {
          this.user$.next(null);
          this.hasIaipRole$.next(null);
        }
      }
    );

    // Do this last as it is used by the login component as a signal that user
    // authentication has completed.
    this.authState.next(this.isAuthenticated);
    this.hasOktaAuthToken$.next(this.isAuthenticated);
    return true;
  }

  /**
   * Interrogate the OktaAuthService and build a User model object.
   */
  private readUserFromOkta = async (): Promise<User> => {
    const accessToken = this.oktaAuthService.getAccessToken();
    await this.oktaAuthService.getUser().then(oktaUser => { this.userInfo = oktaUser; });

    /**
     * This is where the user is created, it must then go through updateUser to send emitters
     * to all that use the service.
     */
    return new User(
      this.userInfo.preferred_username,
      this.userInfo.name,
      this.userInfo.given_name,
      this.userInfo.family_name,
      this.userInfo.sub,
      UserService.parseSbacTenancyChain(this.userInfo.sbacTenancyChain),
      accessToken
    );
  }

  userSessionCheck() {
    return new Promise((resolve, reject) => {
      this.oktaAuthService.session.get().then(
        session => {
          if (session.status === 'ACTIVE') {
            if (!sessionStorage.getItem('isNormalLoginFlow')) {
              this.storageService.set('isNormalLoginFlow', '1');
              if (!this.storageService.get('userSessionState')) {
                const randomHash = Math.random().toString(36).slice(-5);
                this.storageService.set('userSessionState', randomHash);

                // // Override the default setting of Okta token redirect API
                const overrideSetting = {responseType: ['token', 'id_token'], prompt: 'none', display: null};
                //
                // // Redirects to login callback endpoint configured for okta
                this.oktaAuthService.token.getWithRedirect(overrideSetting);
                return true;
              }
              return true;
            } else {
              return true; // Resolving the promise when isNormalLoginFlow is already set
            }
          } else {
            return true; // Resolving the promise when session status is not ACTIVE
          }
        }
      );
    });
  }

  private setTokenExpirationTimer() {
    const {accessToken} = JSON.parse(localStorage.getItem('okta-token-storage'));
    const expirationDuration = new Date(accessToken.expiresAt * 1000).getTime() - new Date().getTime();
    this.autoLogout(expirationDuration);
  }

  private autoLogout(expirationDuration: number) {
    this.tokenExpirationTimer = setTimeout(() => {
      this.route.navigate(['/auth/logout']);
    }, expirationDuration);
  }

  clearTokenExpirationTimer() {
    if (this.tokenExpirationTimer) {
      clearTimeout(this.tokenExpirationTimer);
    }
    this.tokenExpirationTimer = null;
  }

  getFullName(): string | null {
    return this.userInfo ? this.userInfo.name : null;
  }

  getUserSub(): string | null {
    return this.userInfo ? this.userInfo.sub : null;
  }

  getEmail(): string | null {
    return this.userInfo ? this.userInfo.preferred_username : null;
  }

  getFamilyName(): string | null {
    return this.userInfo ? this.userInfo.family_name : null;
  }

  getGivenName(): string | null {
    return this.userInfo ? this.userInfo.given_name : null;
  }
}
