<article *ngIf="resource.assessmentUse" #sectionHeader class="section-container">
  <h2 class="leading-icon">
    <i class="far fa-universal-access" aria-hidden="true"></i>Assessment Use
  </h2>
  <div *ngIf="resource.assessmentUse.linkToSample; else elseBlock">
    <a [href]="resource.assessmentUse.linkToSample"
       [attr.aria-label]="'Try out the ' + resource.properties.title + ' on the Sample Items or the Sample Tests Websites (opens in a new window).'"
       target="_blank">
      Try out the {{ resource.properties.title }} on the Sample Items or the Sample Tests Websites.
    </a>
    <dynamic-html [content]=resource.assessmentUse.description></dynamic-html>
  </div>
  <ng-template #elseBlock>
    <dynamic-html [content]=resource.assessmentUse.description></dynamic-html>
  </ng-template>
</article>
<hr *ngIf="resource.assessmentUse"/>
