<a
  [routerLink]="['/resource', resourceSummary.id]"
  class="inner-card"
  [attr.aria-label]="(resourceSummary.type | resourceType) + ' ' + resourceSummary.properties.title"
>
  <sbdl-resource-thumbnail
    [subjectCode]="resourceSummary.properties.subject.code"
    [url]="resourceSummary.properties.image"
  ></sbdl-resource-thumbnail>

  <div class="resource-summary-card-right">
    <div class="title">
      {{ resourceSummary.properties.title }}
    </div>

    <ng-container>
      <p
        *ngIf="
          resourceSummary.type === ResourceType.Instructional
          || resourceSummary.type === ResourceType.ConnectionsPlaylist
        "
        class="details caption"
      >
        <span
          *ngFor="let detail of details; last as last"
          class="detail-item"
          [ngClass]="detail.type"
        >
          <span class="value">{{ detail.value }}</span>
          <span *ngIf="!last">&nbsp;&nbsp;•&nbsp;&nbsp;</span>
        </span>
      </p>
    </ng-container>
  </div>

</a>
