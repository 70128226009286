<div role="alertdialog" aria-modal="true" aria-labelledby="files-modal"
     aria-describedby="preview-files">
  <div class="files-header">
    <h2 class="files-dialog">
      {{ getCurrentElementCategory() }}
    </h2>
    <sbdl-button-icon
      buttonTitle="Close"
      aria-label="Close"
      (click)="closeDialog()"
      tabindex="1"
    >
      <i class="far fa-times-circle"></i>
    </sbdl-button-icon>
  </div>

  <div class="sbdl-dialog__content" id="files-dialog-content">
    <div class="sbdl-dialog__content_file">
      <div class="cta-loader" *ngIf="loading">
        <i class="fas fa-spinner fa-pulse" aria-label="Loading..."></i>
      </div>
      <div *ngIf="pdfSrc">
        <iframe [src]="pdfSrc" #pdfIframe id="printf" class="pdfSrc"></iframe>
      </div>
      <div *ngIf="imageSrc"  #imageDiv class="imgSrc" width="100%">
        <img [src]="imageSrc">
      </div>
      <div>
        <div *ngIf="videoSrc">
          <video controls [src]="videoSrc" class="videoSrc">
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </div>
</div>
  <div class="sbdl-dialog__actions">
    <div *ngIf="elements.length > 0">
      <p class="attachments-info">Showing attachment {{ getCurrentElementIndex() }} of {{ getTotalElementsCount() }}</p>
      <div class="attachment-arrows">

        <button
          matRipple
          class="sbdl-icon-button material-icons sbdl-attachments-button"
          [matRippleRadius]="25"
          [matRippleCentered]="true"
          (click)="jumpToFirstElement()"
          [disabled]="isFirstElement"
          tabindex="2"
        >
          <i class="far fa-fast-backward"></i>
        </button>

        <button
          matRipple
          class="sbdl-icon-button material-icons sbdl-attachments-button"
          [matRippleRadius]="25"
          [matRippleCentered]="true"
          (click)="showPreviousElement()"
          [disabled]="isFirstElement"
          tabindex="3"
        >
          <i class="far fa-backward"></i>
        </button>

        <div class="attachments-info">{{ getCurrentElementIndex() }}</div>

        <button
          #button
          matRipple
          class="sbdl-icon-button material-icons sbdl-attachments-button"
          [matRippleRadius]="25"
          [matRippleCentered]="true"
          (click)="showNextElement()"
          [disabled]="isLastElement"
          tabindex="4"
        >
          <i class="far fa-forward"></i>
        </button>

        <button
          #button
          matRipple
          class="sbdl-icon-button material-icons sbdl-attachments-button"
          [matRippleRadius]="25"
          [matRippleCentered]="true"
          (click)="jumpToLastElement()"
          [disabled]="isLastElement"
          tabindex="5"
        >
          <i class="far fa-fast-forward"></i>
        </button>
      </div>
    </div>
    <div *ngIf="elements.length === 0">
      <p>No elements available.</p>
    </div>
    <div class="sbdl-dialog__actions_buttons">
        <sbdl-button *ngIf='pdfSrc || imageSrc'
                     #bottomPrintButton class="primary" buttonTitle="Print" aria-label="print"
                     tabindex="6" (click)="printFile()" tabindex="2"
                     [ngClass]="pausePrint ? 'pause' : null">
          {{ pausePrint ? 'Printing' : 'Print' }}
        </sbdl-button>

        <sbdl-button *ngIf='pdfSrc || imageSrc || videoSrc'
                     class="primary middle-bottom-button" buttonTitle="Download" aria-label="download"
                     tabindex="7" (click)="downloadFile()" tabindex="3"
                     [ngClass]="pauseDownload ? 'pause' : null">
          {{ pauseDownload ? 'Downloading' : 'Download' }}
        </sbdl-button>
    <sbdl-button class="primary" buttonTitle="Close" aria-label="close"
                 tabindex="8" (click)="closeDialog()">Close
    </sbdl-button>
    </div>

  </div>
</div>
