import { Resource } from '../data/resource/model/resource.model';
import {Inject, Injectable} from '@angular/core';
import {Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import { Observable } from 'rxjs';
import { ResourceService } from '../data/resource/resource.service';
import {OktaAuthStateService} from '@okta/okta-angular';
import {UserService} from '../data/user/user.service';
import { ActivatedRoute, Params } from '@angular/router';
import {StorageService} from '../common/storage.service';

@Injectable({
    providedIn: 'root'
})
export class ResourceResolve implements Resolve<Resource> {
    constructor(private service: ResourceService,
                private oktaAuthStateService: OktaAuthStateService,
                private userService: UserService,
                private route: ActivatedRoute,
                private storageService: StorageService
    ) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Resource> {
      return this.service.get(route.params.resourceId);
    }
}
