import { Component, Inject, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { TftErrorType, TftErrorMessage, errorMessages } from 'src/app/common/tft-error-type.enum';
import { TenantThemeService } from 'src/app/data/tenant-theme/tenant-theme.service';
import { UserService } from 'src/app/data/user/user.service';
import { OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';

@Component({
  selector: 'sbdl-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {
  error$: BehaviorSubject<TftErrorType> = new BehaviorSubject<TftErrorType>(TftErrorType.Unknown);
  errorDetails$: Observable<string>;
  errorMessage$: Observable<TftErrorMessage>;
  loading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  logo$: Observable<string>;
  user$: Observable<boolean>;
  userState: boolean;
  errorTypes = TftErrorType;
  returnMessage = 'Take me back to the home page.';
  returnLink: string[] = ['/'];

  private routerSubscription: Subscription;

  constructor(
    private location: Location,
    private route: ActivatedRoute,
    private router: Router,
    private tenantThemeService: TenantThemeService,
    private userService: UserService,
    @Inject(OKTA_AUTH) private oktaAuthService: OktaAuth
  ) {
    this.logo$ = tenantThemeService.currentTenantTheme$.pipe(
      map(theme => theme.logoUris.full)
    );
  }

  ngOnInit() {
    this.user$ = this.userService.authenticated;
    this.userService.authenticated.subscribe(state => this.userState = state);

    // Subscribe to route params to set the error type
    this.route.params.pipe(
      map(p => {
        const errorType: TftErrorType = p.type;
        if (!Object.values(TftErrorType).some(v => v === errorType)) {
          this.error$.next(TftErrorType.Unknown); // Update BehaviorSubject
          return TftErrorType.Unknown;
        } else {
          this.error$.next(errorType); // Update BehaviorSubject
          return errorType;
        }
      })
    ).subscribe(); // Subscribe to execute the logic

    this.errorMessage$ = this.route.queryParams.pipe(
      map(params => {
        let customTitle = params.title;
        const customMessage = params.message;

        if (customTitle === 'resourceError') {
          customTitle = 'Please log in for access.';
          this.returnLink = ['/search'];
          this.returnMessage = 'Return to search.';
          if (this.userState) {
            customTitle = 'This resource is restricted.';
          }
        }

        // If both title and message are provided via query parameters, return them.
        if (customTitle && customMessage) {
          return {
            title: customTitle,
            message: customMessage
          };
        }

        // Otherwise, fall back to the error messages based on the error type.
        return errorMessages.get(this.route.snapshot.params.type as TftErrorType);
      })
    );

    this.errorDetails$ = this.route.params.pipe(
      // tap(data => console.log('tap data', data)),
      map(params => {
        // Check if the error type is 'unknown'
        if (params.type === 'unknown') {
          this.error$.next(TftErrorType.PageNotFound);
          return '500 error from API';
        } else {
          // If the type is not 'unknown', return the details
          return params.details;
        }
      })
    );
  }


  onLoginButtonClick(): void {
    this.loading$.next(true);
    this.router.navigate(['/auth/login'], { queryParams: { redirectUrl: this.location.path() } });
  }
}
