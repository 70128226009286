import { APP_BASE_HREF } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { TftErrorService } from 'src/app/common/tft-error.service';
import { UserService } from 'src/app/data/user/user.service';
import {OktaAuth} from '@okta/okta-auth-js';
import {OKTA_AUTH, OktaAuthStateService} from '@okta/okta-angular';
import {StorageService} from '../../common/storage.service';

@Component({
  selector: 'sbdl-login-callback',
  templateUrl: './login-callback.component.html',
  styleUrls: ['./login-callback.component.scss']
})
export class LoginCallbackComponent implements OnInit {

  private parsingToken = false;
  private loginTarget: { uri: string, extras?: NavigationExtras };
  private token;

  constructor(
    @Inject(APP_BASE_HREF) private baseHref: string,
    private route: ActivatedRoute,
    private router: Router,
    private tftErrorService: TftErrorService,
    private userService: UserService,
    @Inject(OKTA_AUTH) private oktaAuthService: OktaAuth,
    private storageService: StorageService
  ) {
    this.loginTarget = { uri: baseHref };
  }

  ngOnInit() {
      this.userService.updateUser().then( data =>
        { this.router.navigateByUrl(this.storageService.get('originalUriToReturnTo')); }
      );
  }
}
