import { Component, Input, OnInit } from '@angular/core';
import { InstructionalRemoteFramework } from 'src/app/data/resource/model/instructional.model';

@Component({
  selector: 'sbdl-instructional-remote',
  templateUrl: './instructional-remote.component.html',
  styleUrls: ['./instructional-remote.component.scss']
})
export class InstructionalRemoteComponent implements OnInit {
  @Input()
  remoteFramework: InstructionalRemoteFramework;

  constructor() { }

  ngOnInit() {
  }

}
