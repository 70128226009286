<div #formField class="sbdl-form-field" [ngClass]="{ 'disabled': !documentSection.canPrint }">
  <div #checkbox>
    <mat-checkbox class="sbdl-checkbox-title"
                  #sectionCheckbox
      [attr.disabled]="!documentSection.canPrint"
      (change)="change($event.checked)"
      [id]="id" [checked]=amIChecked
    >
        {{ documentSection.title }}

    </mat-checkbox>
  </div>
</div>
<div class="caption emphasis-medium"
     *ngIf="!documentSection.canPrint">This section cannot be printed</div>
