<section #sectionHeader>
  <h3>How It's Used</h3>
  <div>
    <h4>
      Clarify Intended Learning
      <sbdl-tooltip
        text="What students will know or be able to do."
      ><sbdl-icon icon="info"></sbdl-icon></sbdl-tooltip>
    </h4>
    <dynamic-html [content]="content.clarify"></dynamic-html>
    <h4>
      Elicit Evidence
      <sbdl-tooltip
        text="What students currently know or can do."
      ><sbdl-icon icon="info"></sbdl-icon></sbdl-tooltip>
    </h4>
    <dynamic-html [content]="content.elicit"></dynamic-html>
    <h4>
      Interpret Evidence
      <sbdl-tooltip
        text="Evaluation of what students currently know/can do in relation to learning goal."
      ><sbdl-icon icon="info"></sbdl-icon></sbdl-tooltip>
    </h4>
    <dynamic-html [content]="content.interpret"></dynamic-html>
    <h4>
      Act on Evidence
      <sbdl-tooltip
        text="How we are going to get students to where they need to be."
      ><sbdl-icon icon="info"></sbdl-icon></sbdl-tooltip>
    </h4>
    <dynamic-html [content]="content.act"></dynamic-html>
  </div>
</section>
