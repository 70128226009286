<div class="watermarks">
  <img class="waterspot green" src="/assets/images/green-spot-2.png" aria-hidden="true">
  <img class="waterspot yellow" src="/assets/images/yellow-spot-6.png" aria-hidden="true">
  <img class="waterspot blue" src="/assets/images/blue-spot-3.png" aria-hidden="true">
  <img class="waterspot green-mobile" height="98px" src="/assets/images/green-spot-5.png" aria-hidden="true">
  <img class="waterspot yellow-mobile" height="28px" src="/assets/images/yellow-spot-9.png" aria-hidden="true">
  <img class="waterspot blue-mobile" height="46px" src="/assets/images/blue-spot-5.png" aria-hidden="true">
  <img class="waterspot yellow-tablet" src="/assets/images/yellow-spot-13.png" width="59" aria-hidden="true">
</div>
<sbdl-alerts></sbdl-alerts>
<main id="main" class="home-main">

  <img class="logo-full"
       [src]="(logo$ | async) | trustUrl"
       [alt]="((tenantName$ | async) !== null && (tenantName$ | async) !== 'Smarter Balanced') ? (tenantName$ | async) + ' and Tools for Teachers from Smarter Balanced Logo' : 'Tools for Teachers from Smarter Balanced Logo'"
  >
  <h1>
    Find <span class="strong-green">classroom </span>
    <span class="strong-blue">resources</span> you can use today.
  </h1>
  <p>
    Teacher-created, standards-aligned lesson plans with printable handouts, presentations,
    and integrated formative assessment strategies.
  </p>
  <p><sbdl-button (click)="goToSearch('')" [buttonTitle]="'Search Lesson Plans'" class="primary home-search" >
    <i class="far fa-search" aria-hidden="true"></i> SEARCH LESSON PLANS</sbdl-button>
    <sbdl-button (click)="goToIRLanding()"
                 [buttonTitle]="'Learn More'" class="home-learn-more">LEARN MORE</sbdl-button>
  </p>
</main>

<hr/>

<section class="resources-overview home-main">
  <div class="resources-overview-text-cards-container">
    <div class="resources-overview-text section-1">
      <h2>Do you have <span class="highlight-blue">interim assessment</span> data or <span class="highlight-blue">observational data</span> indicating that students would benefit from additional support?</h2>
      <p>Search through our standards-aligned resources and playlists.</p>
    </div>
    <div class="resources-overview-cards section-1">
      <div class="resources-overview-card">
        <div class="resources-overview-image"><img src="/assets/images/tenant-tools/InterimConnections.png" alt=""></div>
        <h3 class="resources-overview-title">Interim<br/> Connections Playlist</h3>
        <div class="resources-overview-button"><sbdl-button (click)="goToSearch(';resourceTypes=cp')" [buttonTitle]="'Connections Playlist'"
                                                    class="primary" ><i class="far fa-search" aria-hidden="true"></i> CONNECTIONS PLAYLIST</sbdl-button></div>
      </div>
      <div class="resources-overview-card resources-overview-wider">
        <div class="resources-overview-image"><img src="/assets/images/tenant-tools/InstructionalResources.png" alt=""></div>
        <h3 class="resources-overview-title">Instructional Resources</h3>
        <div class="resources-overview-button"><sbdl-button (click)="goToSearch(';resourceTypes=ir')"  [buttonTitle]="'Instructional Resources'"
                                                            class="primary" ><i class="far fa-search" aria-hidden="true"></i> INSTRUCTIONAL RESOURCES</sbdl-button></div>
      </div>
    </div>
  </div>
  <div class="resources-overview-text-cards-container">
    <div class="resources-overview-cards section-2">
      <div class="resources-overview-card">
        <div class="resources-overview-image"><img src="/assets/images/tenant-tools/FormativeAssessmentStrategies.png" alt=""></div>
        <h3 class="resources-overview-title">Formative Assessment Strategies</h3>
        <div class="resources-overview-button"><sbdl-button (click)="goToSearch(';resourceTypes=fs')" [buttonTitle]="'Formative Strategies'"
                                                            class="primary" ><i class="far fa-search" aria-hidden="true"></i>  FORMATIVE STRATEGIES</sbdl-button></div>
      </div>
      <div class="resources-overview-card resources-overview-wider">
        <div class="resources-overview-image"><img src="/assets/images/tenant-tools/AccessibilityStrategies.png" alt=""></div>
        <h3 class="resources-overview-title">Accessibility Strategy Resources</h3>
        <div class="resources-overview-button"><sbdl-button (click)="goToSearch(';resourceTypes=as')"  [buttonTitle]="'Accessibility Strategy Resources'" class="primary" ><i class="far fa-search" aria-hidden="true"></i> ACCESSIBILITY STRATEGIES</sbdl-button></div>
      </div>
    </div>
    <div class="resources-overview-text section-2">
      <h2>Power up your lesson plan with resources on
        <span class="highlight-blue">formative assessment strategies</span> and
        <span class="highlight-blue">accessibility strategies.</span></h2>
      <p>Search through our teacher created resources.</p>
    </div>
  </div>
</section>
<hr/>
<!--<section class="quick-start-card-container">-->
<!--  <div class="watermarks">-->
<!--    <img class="waterspot yellow" src="/assets/images/yellow-spot-7.png" aria-hidden="true">-->
<!--    <img class="waterspot blue" src="/assets/images/blue-spot-4.png" aria-hidden="true">-->
<!--    <img class="waterspot green" src="/assets/images/green-spot-4.png" aria-hidden="true">-->
<!--    <img class="waterspot yellow-tablet" src="/assets/images/yellow-spot-10.png" width="70" aria-hidden="true">-->
<!--    <img class="waterspot yellow-mobile" height="69px" src="/assets/images/yellow-spot-10.png" aria-hidden="true">-->
<!--  </div>-->
<!--  <h2 class="home-h2">Quick Start</h2>-->
<!--  <div class="caption emphasis-medium">-->
<!--    How can you use Tools for Teachers to support student learning? Check out these quick guides to teaching resources-->
<!--    and strategies that include helpful instructional tips, professional learning videos, and classroom lessons.-->
<!--  </div>-->
<!--  <div class="three-col-container">-->
<!--    <a *ngFor="let quickStart of quickStarts" [routerLink]="quickStart.href" role="group" aria-label="quick start" class="quick-start-card three-col-card">-->
<!--      <img src="{{ quickStart.imageSource }}" class="quick-start-image" alt="">-->
<!--      <h3 class="quick-start-title">{{ quickStart.title }}</h3>-->
<!--      <div class="quick-start-text">{{ quickStart.text }}</div>-->
<!--    </a>-->
<!--  </div>-->
<!--</section>-->
<!--<hr/>-->

<section class="resource-summary-cards-container professional-learning-container home-main">
  <div class="resource-summary-cards-container-flex">
    <div class="resource-summary-cards-text">
      <h2 class="scroller-title">Ignite your growth with our informative professional learning resources</h2>
      <p>Enhance teaching, master the formative assessment process, and utilize Smarter Balanced tools to boost student learning</p>
      <sbdl-button class="primary button-margin" (click)="goToSearch(';resourceTypes=pl')"><i class="far fa-search"></i> PROFESSIONAL LEARNING</sbdl-button>
      <sbdl-button class="button-margin home-learn-more" (click)="goToPLLanding()">LEARN MORE</sbdl-button>
    </div>
    <div class="resource-summary-cards-scroller">
      <div class="scroll-button left" (click)="scrollLeft(scrollAmount, 'PL')">
        <div class="scroll-button-circle"><i class="fa fa-chevron-left"></i></div>
      </div>
      <div #scrollerContentPL class="resource-summary-cards">
        <ng-container *ngIf="resourceSummariesPl$">
          <sbdl-search-result-card
            role="group" aria-label="search result"
            *ngFor="let result of resourceSummariesPl$; let i = index"
            class="result-card home-result-card"
            [resource]="result"
          ></sbdl-search-result-card>
          <div class="scroll-spacer"></div>
        </ng-container>
      </div>
      <div class="scroll-button right" (click)="scrollRight(scrollAmount, false, 'PL')">
        <div class="scroll-button-circle"><i class="fa fa-chevron-right"></i></div>
      </div>
    </div>
  </div>
</section>
<hr/>

<section class="resource-summary-cards-container accessibility-container home-main">
  <div class="resource-summary-cards-container-flex">
    <div class="resource-summary-cards-scroller">
      <div class="scroll-button left" (click)="scrollLeft(scrollAmount, 'AS')">
        <div class="scroll-button-circle"><i class="fa fa-chevron-left"></i></div>
      </div>
      <div  #scrollerContentAS class="resource-summary-cards">
        <ng-container *ngIf="resourceSummariesAs$">
          <sbdl-search-result-card
            role="group" aria-label="search result"
            *ngFor="let result of resourceSummariesAs$; let i = index"
            class="result-card home-result-card"
            [resource]="result"
          ></sbdl-search-result-card>
          <div class="scroll-spacer"></div>
        </ng-container>
      </div>
      <div class="scroll-button right" (click)="scrollRight(scrollAmount, false, 'AS')">
        <div class="scroll-button-circle"><i class="fa fa-chevron-right"></i></div>
      </div>
    </div>
    <div class="resource-summary-cards-text accessibility-text">
      <h2 class="scroller-title">Find accessibility strategies to meet your students' needs</h2>
      <p>Help make your lesson plans more accessible for students</p>
      <sbdl-button class="primary button-margin" (click)="goToSearch(';resourceTypes=as')"><i class="far fa-search"></i> ACCESSIBILITY STRATEGIES</sbdl-button>
      <sbdl-button class="button-margin home-learn-more" (click)="goToASLanding()">LEARN MORE</sbdl-button>
    </div>
  </div>
</section>
<hr/>

<!--<section class="video-container">-->
<!--  <div class="promoted-videos">-->
<!--    <div class="promoted-video">-->
<!--      <h2>Tools For Teachers Overview</h2>-->
<!--      <sbdl-able-player-float-ts class="video"-->
<!--        [youtubeVideoId]="'jJPAU1zOAp4'">-->
<!--      </sbdl-able-player-float-ts>-->
<!--    </div>-->
<!--  </div>-->
<!--</section>-->

<!--<hr/>-->

<section class="explore-more-tools-card-container">
<!--  <div class="watermarks">-->
<!--    <img class="waterspot yellow-tablet" src="/assets/images/yellow-spot-12.png" width="83" aria-hidden="true">-->
<!--    <img class="waterspot green-tablet" src="/assets/images/green-spot-7.png" width="22" aria-hidden="true">-->
<!--    <img class="waterspot blue-tablet" src="/assets/images/blue-spot-6.png" width="81" aria-hidden="true">-->
<!--    <img class="waterspot yellow-mobile" src="/assets/images/yellow-spot-11.png" width="45" aria-hidden="true">-->
<!--    <img class="waterspot green-mobile" src="/assets/images/green-spot-6.png" width="42" aria-hidden="true">-->
<!--  </div>-->
<!--  <img class="home-arrow" src="../../assets/images/large-home-arrow.png" aria-hidden="true">-->
<!--  <h2 class="home-h2">Explore More Tools</h2>-->
<!--  <div class="caption emphasis-medium">-->
<!--    Tools for Teachers is part of the Smarter Balanced suite of tools created to provide educators, students, and families with standards-aligned assessments, student performance data, classroom instructional support, and professional learning. Check out our other tools below.-->
<!--  </div>-->
  <h2 class="more-tools-h2">Unleash student potential with our comprehensive suite of balanced assessment tools and resources</h2>
  <p>Essential tools for both interim and summative assessments</p>
  <div class="mt-scroller-container">
    <div #scrollerContentMT class="explore-more-tools-cards">
      <ng-container *ngFor="let tenantTool of tenantTools">
        <div *ngIf="tenantTool.tenant === nameForCard || tenantTool.tenant === 'any'" role="group" aria-label="explore more" class="three-col-card">
            <div class="more-tools-card">
              <div class="more-tools-image"><img src="{{ tenantTool.imageSource }}" alt=""></div>
              <h3 class="more-tools-title">{{ tenantTool.title }}</h3>
              <div class="more-tools-text">{{ tenantTool.text }}</div>
              <div class="more-tools-button"><sbdl-button [link]=tenantTool.href [buttonTitle]=tenantTool.title
                class="primary" ><i class="far fa-external-link" aria-hidden="true"></i> {{ tenantTool.button }}</sbdl-button></div>
          </div>
        </div>
      </ng-container>

  <!--    &lt;!&ndash; Available to anyone cards &ndash;&gt;-->
  <!--    <ng-container *ngFor="let moreTool of moreTools" >-->
  <!--    <div class="more-tools-card three-col-card">-->
  <!--      <div class="more-tools-image"><img src="{{ moreTool.imageSource }}" alt=""></div>-->
  <!--      <h3 class="more-tools-title">{{ moreTool.title }}</h3>-->
  <!--      <div class="more-tools-text">{{ moreTool.text }}</div>-->
  <!--&lt;!&ndash;      <div class="more-tools-arrow"><img src="/assets/images/fa-arrow-right.png" alt=""></div>&ndash;&gt;-->
  <!--      <sbdl-button [link]=moreTool.href-->
  <!--        class="primary more-tools-button"><i class="far fa-external-link" aria-hidden="true"></i> {{ moreTool.title }}</sbdl-button>-->
  <!--    </div>-->
  <!--    </ng-container>-->
    </div>
    <div class="mt-scroll-button left" (click)="scrollLeft(mtScrollAmount, 'MT')">
      <div class="scroll-button-circle"><i class="fa fa-chevron-left"></i></div>
    </div>
    <div class="mt-scroll-button right" (click)="scrollRight(mtScrollAmount, false, 'MT')">
      <div class="scroll-button-circle"><i class="fa fa-chevron-right"></i></div>
    </div>
  </div>
<!--  <div class="login-link">-->
<!--    <a *ngIf="!(loggedIn$)" href="/auth/login?redirectUrl=%2F">Log in to access additional tools specific to your location.</a>-->
<!--  </div>-->
</section>
<hr/>
