import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {OktaAuthModule} from '@okta/okta-angular';
import {AppContainerComponent} from './app-container/app-container.component';
import {FooterComponent} from './footer/footer.component';
import {LoginCallbackComponent} from './login-callback/login-callback.component';
import {LogoutComponent} from './logout/logout.component';
import {PipesModule} from '../pipes/pipes.module';
import {SideNavigationComponent} from './navigation/side-navigation/side-navigation.component';
import {ErrorComponent} from './error/error.component';
import {SbdlCommonModule} from '../common/common.module';
import {FormsModule} from '@angular/forms';
import {PageNotFoundComponent} from './error/page-not-found/page-not-found.component';
import {DevelopmentEnvironmentComponent} from './development-environment/development-environment.component';
import {TopNavigationComponent} from './navigation/top-navigation/top-navigation.component';
import {NavLinkComponent} from './navigation/nav-link/nav-link.component';
import {SmallNavigationComponent} from './navigation/small-navigation/small-navigation.component';
import {OverlayComponent} from './overlay/overlay.component';
import {SearchModule} from '../search/search.module';

@NgModule({
  declarations: [
    AppContainerComponent,
    ErrorComponent,
    PageNotFoundComponent,
    FooterComponent,
    LoginCallbackComponent,
    LogoutComponent,
    SideNavigationComponent,
    SmallNavigationComponent,
    TopNavigationComponent,
    DevelopmentEnvironmentComponent,
    NavLinkComponent,
    OverlayComponent
  ],
  exports: [
    FooterComponent,
    SideNavigationComponent,
    TopNavigationComponent,
    SmallNavigationComponent,
    OverlayComponent
  ],
  imports: [
    OktaAuthModule,
    SbdlCommonModule,
    CommonModule,
    PipesModule,
    RouterModule,
    FormsModule,
    SearchModule
  ]
})
export class LayoutModule {
}
