import { Component, OnInit, Inject, Input, Output, NgModule } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { BrowseridService } from '../../common/browserid.service';
import { LandingService } from '../../data/landing/landing.service';
import { LandingPage } from '../../data/landing/model/landingPage.model';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'sbdl-landing-print',
  templateUrl: './landing-print.component.html',
  styleUrls: ['./landing-print.component.scss', '../landing.component.scss'],
  providers: [BrowseridService],
})

export class LandingPrintComponent implements OnInit {

  printSafari = false;

  @Output()
  printPageFromDialog = new EventEmitter();

  @Output()
  downloadPageFromDialog = new EventEmitter();

  constructor(
    public dialogRef: MatDialogRef<LandingPrintComponent>,
    public browserIdService: BrowseridService,
    @Inject(MAT_DIALOG_DATA) public data: {
      pdfData: any;
      landingService: LandingService, isWaitDisplayed: boolean},
    public exampleDialog: MatDialog
  ){}

  close() {
    this.dialogRef.close();
  }

  ngOnInit() {
    // Check if this is Safari - if it is, we don't display print, but
    // display download button instead.
    if (this.browserIdService.getBrowser() === 'safari/webkit') {
      this.printSafari = true;
    }
  }

  printLandingPage() {
    this.printPageFromDialog.emit();
  }

  downloadLandingPage() {
    this.downloadPageFromDialog.emit();
  }

}
