<!-- Overlay content -->

<!-- Always visible top navigation for small screens without overlay -->
  <sbdl-top-navigation></sbdl-top-navigation>
  <sbdl-small-navigation></sbdl-small-navigation>


<!--<sbdl-small-navigation *ngIf="!isBreakpointSecond"></sbdl-small-navigation>-->
<ng-template #overlayTemplate>
    <sbdl-side-navigation></sbdl-side-navigation>
</ng-template>


<!--<ng-template #overlayTemplate *ngIf="isBreakpointFirst || isBreakpointSecond || isResourcePage">-->
<!--  <div class="overlay-content">-->
<!--    <sbdl-top-navigation [fromOverlay]="true"></sbdl-top-navigation>-->
<!--    <sbdl-small-navigation *ngIf="!isBreakpointSecond"></sbdl-small-navigation>-->
<!--    <sbdl-side-navigation></sbdl-side-navigation>-->
<!--  </div>-->
<!--</ng-template>-->

<!--&lt;!&ndash; Navigation components for larger screens &ndash;&gt;-->
<!--<div *ngIf="isBreakpointDefault">-->
<!--  <sbdl-top-navigation></sbdl-top-navigation>-->
<!--  <sbdl-small-navigation></sbdl-small-navigation>-->
<!--  <sbdl-side-navigation></sbdl-side-navigation>-->
<!--</div>-->



<!--&lt;!&ndash; Navigation components for smallest screens without overlay &ndash;&gt;-->
<!--<div *ngIf="isBreakpointSecond && !overlayOpen">-->
<!--  <sbdl-top-navigation></sbdl-top-navigation>-->
<!--</div>-->
