<article #sectionHeader class="differentiation-container">
  <h2 class="leading-icon"><i class="far fa-universal-access"></i>
    <span *ngIf="hasDifferentiationContent()">Differentiation</span>
    <span *ngIf="!hasDifferentiationContent()">
      Accessibility Strategies Used
      <sbdl-tooltip
        text="Multi-tiered strategies to support all students individually based on their unique needs and preferences."
      ><sbdl-icon icon="info"></sbdl-icon></sbdl-tooltip>
    </span>
  </h2>
  <sbdl-instructional-differentiation-content
    *ngIf="hasDifferentiationContent()"
    [content]="resource.differentiation"
    (sectionLoaded)="addSubsection($event)">
  </sbdl-instructional-differentiation-content>
  <sbdl-strategy-reference-list
    [title]="hasDifferentiationContent() ? 'Accessibility Strategies Used' : ''"
    (sectionLoaded)="addSubsection($event)"
    [strategies]="resource.accessibilityStrategies">
  </sbdl-strategy-reference-list>
</article>
<hr/>
