import { NgModule } from '@angular/core';
import {NavigationStart, Router, RouterModule, Routes} from '@angular/router';
import {OktaAuthGuard, OktaCallbackComponent} from '@okta/okta-angular';
import { HomeComponent } from './home/home.component';
import { AppContainerComponent } from './layout/app-container/app-container.component';
import { ResourceTypeComponent } from './resource/resource-type.component';
import { ResourceResolve } from './resource/resource.resolve';
import { NotesResolve } from './notes/notes.resolve';
import { BookmarkListComponent } from './bookmarks/bookmark-list/bookmark-list.component';
import { BookmarksResolve } from './bookmarks/bookmarks.resolve';
import { ResourcesWithNotesResolve } from './notes/resources-with-notes.resolve';
import { SearchResultsComponent } from './search/results/search-results.component';
import { SearchResultsResolve } from './search/results/search-results.resolve';
import { ErrorComponent } from './layout/error/error.component';
import { LoginCallbackComponent } from './layout/login-callback/login-callback.component';
import { LogoutComponent } from './layout/logout/logout.component';
import {ERROR_PATH, OKTA_CALLBACK_LOGOUT_PATH, OKTA_CALLBACK_PATH} from './common/constants';
import { LoginComponent } from './layout/login/login.component';
import { PageNotFoundComponent } from './layout/error/page-not-found/page-not-found.component';
import { LandingComponent } from './landing/landing.component';
import { LandingResolve } from './landing/landing.resolve';
import { StorageService} from './common/storage.service';

const routes: Routes = [
  {
    path: '',
    component: AppContainerComponent,
    children: [
      {
        path: '',
        component: HomeComponent
      }, {
        path: 'bookmarks',
        component: BookmarkListComponent,
        resolve: {
          bookmarks: BookmarksResolve,
          idsOfResourcesWithNotes: ResourcesWithNotesResolve
        },
        canActivate: [ OktaAuthGuard ]
      }, {
        path: 'resource/:resourceId',
        component: ResourceTypeComponent,
        resolve: {
          resource: ResourceResolve,
          notes: NotesResolve
        }
      }, {
        path: 'search',
        component: SearchResultsComponent,
        resolve: { results: SearchResultsResolve }
      }, {
        path: 'landing/:landingType',
        component: LandingComponent,
        resolve: {
          landing: LandingResolve
        }
      }, {
        path: 'auth/login',
        component: LoginComponent
      }, {
        path: 'auth/logout',
        component: LogoutComponent
      }, {
        path: 'instructional',
        redirectTo: '/landing/instructional',
      }, {
        path: 'professional',
        redirectTo: '/landing/professional',
      }, {
        path: 'formative',
        redirectTo: '/landing/formative',
      }, {
        path: 'accessibility',
        redirectTo: '/landing/accessibility',
      }, {
        path: 'playlist',
        redirectTo: '/landing/playlist',
      }, {
        path: 'items',
        redirectTo: '/landing/items',
      }, {
        path: OKTA_CALLBACK_PATH,
        component: OktaCallbackComponent
      }, {
        path: 'auth/login-callback-component',
        component: LoginCallbackComponent
      }, {
        path: OKTA_CALLBACK_LOGOUT_PATH,
        redirectTo: ''
      }, {
        path: ERROR_PATH,
        component: ErrorComponent
      }, {
        path: '**',
        component: PageNotFoundComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [ RouterModule ]
})
export class AppRoutingModule {
  constructor(
    private router: Router,
    private storageService: StorageService
  ) {
    // This checks any path to see if there is "admin=true" if there is, then it bypasses the dev protection
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        const url = event.url;
        const tree = this.router.parseUrl(url);
        const adminQueryParam = tree.queryParams.admin;

        const isAdmin = adminQueryParam === 'true';
        if (isAdmin) {
          this.storageService.set('devLoggedIn', 'true');
        }
      }
    });
  }
}
