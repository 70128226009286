import { Component, EventEmitter, Input, Output, ViewChild, ElementRef } from '@angular/core';
import { IdGeneratorService } from 'src/app/common/id-generator.service';
import { DocumentSection } from 'src/app/resource/components/outline/document-outline.model';

@Component({
  selector: 'sbdl-print-checkbox',
  templateUrl: './print-checkbox.component.html',
  styleUrls: ['./print-checkbox.component.scss']
})
export class PrintCheckboxComponent {

  @Input()
  documentSection: DocumentSection;

  // You would think that this could be passed in as an Input, but for some
  // reason if this is not defined during object construction, the link between
  // the MDCFormField and MDCCheckbox is broken and clicking the label will not
  // toggle the checkbox.
  id = this.idService.nextId('sbdl-print-checkbox');

  @Output()
  changed = new EventEmitter<DocumentSection>();

  @ViewChild('formField', { static: false })
  formFieldRef: ElementRef;

  @ViewChild('checkbox', { static: false })
  checkboxRef: ElementRef;

  @ViewChild('thisIsMe', { static: false })
  thisIsMeRef: ElementRef;

  @Input()
  amIChecked: boolean;

  constructor(private idService: IdGeneratorService) { }

  change(checked: boolean) {
    this.documentSection.selectedForPrint = checked;
    this.changed.emit(this.documentSection);
  }

  changeCheckboxProgramatically() {
    this.thisIsMeRef['checked'] = this.amIChecked;
    this.amIChecked = !this.amIChecked;
  }

}
