import {Inject, Injectable, Injector} from '@angular/core';
import { DataService } from 'src/app/data/data.service';
import {ResourceAttachment } from 'src/app/data/resource/model/attachment.model';
import {UserService} from '../../../data/user/user.service';
import * as printJS from 'print-js';
import {OKTA_AUTH} from '@okta/okta-angular';
import {OktaAuth} from '@okta/okta-auth-js';

@Injectable({ providedIn: 'root' })
export class AttachmentsService {

  constructor(
    @Inject('Window') private window: Window,
    private dataService: DataService,
    private userService: UserService,
    @Inject(OKTA_AUTH) private oktaAuthService: OktaAuth,
  ) {}

  download(attachment: ResourceAttachment): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.dataService.downloadBlob(attachment.uri).subscribe(
        blob => {
          const link = document.createElement('a');
          link.href = (this.window as any).URL.createObjectURL(blob);
          link.download = `${attachment.name}.${attachment.fileExtension}`;
          link.hidden = true;
          link.type = attachment.mimeType;
          document.body.appendChild(link);
          link.click();

          setTimeout(() => {
            (this.window as any).URL.revokeObjectURL(link.href);
            document.body.removeChild(link);
          }, 100);
          resolve(true);
        },
        error => {
          resolve(false);
        }
      );
    });
  }

  print(attachment: ResourceAttachment) {
    this.dataService
      .downloadBlob(attachment.uri)
      .subscribe(blob => {
          const link = document.createElement('a');
          link.href = (this.window as any).URL.createObjectURL(blob);
          link.hidden = true;
          link.type = attachment.mimeType;
          document.body.appendChild(link);
          printJS({ printable: link.href, type: 'pdf', header: `${attachment.name}.${attachment.fileExtension}`, documentTitle: `${attachment.name}.${attachment.fileExtension}` });
          setTimeout(() => {
            (this.window as any).URL.revokeObjectURL(link.href);
            document.body.removeChild(link);
          }, 10000);
        },
        error => {
          console.log('Error in Printing', error);
        });
  }
}
