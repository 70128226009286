<!--{{data.landingPage | json}}-->
<!--{{data.pdfData}}-->
<div role="alertdialog" aria-modal="true" aria-labelledby="print-modal"
     aria-describedby="my-dialog-content">
  <div class="print-header">
    <h2 class="print-dialog">
      Print Preview
    </h2>
    <sbdl-button-icon
      buttonTitle="Close"
      aria-label="Close"
      [attr.tabindex]="printSafari ? 1 : null"
      (click)="close()"
    >
      <i class="far fa-times-circle"></i>
    </sbdl-button-icon>
  </div>

  <div class="sbdl-dialog__content" id="my-dialog-content">
    <!-- The PDF -->
    <div style="height: calc(100% - 105px);">
      <iframe id="printiframe" [attr.src]="data.pdfData" type="application/pdf"
              style="max-width: none; width: 820px;height: 100%;max-height: none;min-height:500px;"></iframe>
    </div>
  </div>

  <div class="sbdl-dialog__actions">
    <sbdl-button *ngIf='!printSafari' #bottomPrintButton class="primary" buttonTitle="Print" aria-label="print"
                 (click)="printLandingPage()" tabindex="2">Print
    </sbdl-button>

    <sbdl-button class="primary middle-bottom-button" buttonTitle="Download" aria-label="download"
                 (click)="downloadLandingPage()" tabindex="3">Download
    </sbdl-button>

    <sbdl-button class="primary" buttonTitle="Close" aria-label="close"
                 tabindex="4" (click)="close()">Close
    </sbdl-button>

    <sbdl-button *ngIf='printSafari' tabindex="5" style="border:none; padding:0;"></sbdl-button>

  </div>
</div>
