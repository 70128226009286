import { Component, Inject, OnInit } from '@angular/core';
import { VERSION } from 'src/environments/version';
import { AppConfig } from './common/config/app.config';
import { OKTA_CALLBACK_PATH } from './common/constants';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from '../environments/environment';
import { OktaAuth } from '@okta/okta-auth-js';
import { filter, map } from 'rxjs/operators';
import { UserService } from './data/user/user.service';
import { TenantThemeService } from './data/tenant-theme/tenant-theme.service';
import {OKTA_AUTH} from '@okta/okta-angular';

declare let gtag;
declare let pendo: any;

@Component({
  selector: 'sbdl-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  private title = 'sb-digital-library';
  public appVersion = VERSION;
  public envName: string;
  public googleIDEnv: string;
  private googleAnalyticsEnv: string;
  private email: string;
  private userSub: string;
  private fullName: string;
  private givenName: string;
  private familyName: string;
  public tenantName: string;

  constructor(
    @Inject('Window') private window: Window,
    private router: Router,
    private userService: UserService,
    @Inject(OKTA_AUTH) private oktaAuthService: OktaAuth,
    private tenantThemeService: TenantThemeService
  ) {}

  async ngOnInit() {
    if (AppConfig.settings) {
      this.envName = environment.envName;
      this.googleIDEnv = environment.googleTagManagerId;
      this.googleAnalyticsEnv = environment.googleAnalyticsID;
    }
    this.setUpAnalytics();
    this.storePathOnPageLoad(this.window.location.pathname);
    this.addFirstGAScriptToHead();
    this.addSecondGAScriptToHead();

    this.tenantThemeService.currentTenantTheme$.pipe(
      map(theme => theme.displayName)
    ).subscribe(val => {
      this.tenantName = val;
    });

    this.userService.authenticated.subscribe(isAuthenticated => {
      if (isAuthenticated === true && this.tenantName !== 'Smarter Balanced') {
        this.addPendoScriptToHead();
        this.userSub = this.userService.getUserSub();
        this.fullName = this.userService.getFullName();
        this.givenName = this.userService.getGivenName();
        this.familyName = this.userService.getFamilyName();
        this.email = this.userService.getEmail();
        this.initializePendo();
      }
    });

    await this.userService.userSessionCheck();
  }

  storePathOnPageLoad(path: string) {
    if (!path.includes(OKTA_CALLBACK_PATH)) {
      this.oktaAuthService.setOriginalUri(path);
    }
  }

  setUpAnalytics() {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        gtag('config', this.googleIDEnv, {
          page_path: event.urlAfterRedirects
        });
      });
  }

  addFirstGAScriptToHead() {
    // <script async src="https://www.googletagmanager.com/gtag/js?id=G-8954NSHC9G"></script>
    const firstScript = document.createElement('script');
    firstScript.setAttribute('async', '');
    firstScript.src = 'https://www.googletagmanager.com/gtag/js?id=' + this.googleAnalyticsEnv;
    document.head.appendChild(firstScript);
  }

  addSecondGAScriptToHead() {
    // <script>
    //       window.dataLayer = window.dataLayer || [];
    //       function gtag(){dataLayer.push(arguments);}
    //       gtag('js', new Date());
    //
    //       gtag('config', 'G-8954NSHC9G');
    // </script>
    const secondScript = document.createElement('script');
    secondScript.innerHTML =
      'window.dataLayer = window.dataLayer || [];\n' +
      'function gtag(){dataLayer.push(arguments);}' +
      'gtag(\'js\', new Date());' +
      'gtag(\'config\', \'' + this.googleAnalyticsEnv + '\')';
    document.head.appendChild(secondScript);
  }

  addPendoScriptToHead() {
    // <script>
    //   (function(apiKey){
    //     (function(p,e,n,d,o){var v,w,x,y,z;o=p[d]=p[d]||{};o._q=o._q||[];
    //       v=['initialize','identify','updateOptions','pageLoad','track'];for(w=0,x=v.length;w<x;++w)(function(m){
    //         o[m]=o[m]||function(){o._q[m===v[0]?'unshift':'push']([m].concat([].slice.call(arguments,0)));};})(v[w]);
    //       y=e.createElement(n);y.async=!0;y.src='https://cdn.pendo.io/agent/static/'+apiKey+'/pendo.js';
    //       z=e.getElementsByTagName(n)[0];z.parentNode.insertBefore(y,z);})(window,document,'script','pendo');
    //   })('1195fd81-5cf5-4b79-67d7-2203628eb2f5');
    // </script>
    const pendoScript = document.createElement('script');
    pendoScript.innerHTML =
      '(function(apiKey){' +
      '(function(p,e,n,d,o){var v,w,x,y,z;o=p[d]=p[d]||{};o._q=o._q||[];' +
      'v=[\'initialize\',\'identify\',\'updateOptions\',\'pageLoad\',\'track\'];for(w=0,x=v.length;w<x;++w)(function(m){' +
      'o[m]=o[m]||function(){o._q[m===v[0]?\'unshift\':\'push\']([m].concat([].slice.call(arguments,0)));};})(v[w]);' +
      'y=e.createElement(n);y.async=!0;y.src=\'https://content.pendo.dl.smarterbalanced.org/agent/static/\'+apiKey+\'/pendo.js\';' +
      'z=e.getElementsByTagName(n)[0];z.parentNode.insertBefore(y,z);})(window,document,\'script\',\'pendo\');' +
      '})(\'1195fd81-5cf5-4b79-67d7-2203628eb2f5\');';
    pendoScript.onload = this.initializePendo.bind(this);
    document.head.appendChild(pendoScript);
  }

  initializePendo() {
    pendo.initialize({
      visitor: {
        id: this.userSub,
        full_name: this.fullName,
        email: this.email,
        first_name: this.givenName,
        last_name: this.familyName
      },
      account: {
        id: this.tenantName
      }
    });
  }
}
