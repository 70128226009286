import {Component, ElementRef, Input, OnInit, ViewChild, SecurityContext} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {DCIStrand} from 'src/app/data/resource/model/dciStrand.model';
import {Domain} from 'src/app/data/resource/model/domain.model';
import {PerformanceExpectation} from 'src/app/data/resource/model/performanceExpectation.model';
import {ResourceSummary} from 'src/app/data/resource/model/summary.model';
import {SortingService} from '../../../common/sorting/sorting.service';

@Component({
  selector: 'sbdl-search-result-card',
  templateUrl: './search-result-card.component.html',
  styleUrls: ['./search-result-card.component.scss']
})
export class SearchResultCardComponent implements OnInit {

  @Input()
  resource: ResourceSummary;

  @ViewChild('card', {static: false})
  cardElem: ElementRef;

  svgSafeUrl: SafeResourceUrl;
  subjectCode = '';
  orderedDomains: Domain[];
  orderedDCIStrands: DCIStrand[];
  orderedPerformanceExpectations: PerformanceExpectation[];
  addOpenClass = false;
  typeShort = 'ir';
  textOnlyResourceSummary: string;
  constructor(private sanitizer: DomSanitizer, private sorting: SortingService, private elRef: ElementRef) {}

  ngOnInit() {
    if (this.resource.properties.image &&
        this.resource.properties.image.endsWith('.svg')) {
      this.svgSafeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.resource.properties.image);
    }

    if (this.resource.properties.subject) {
      this.subjectCode = this.resource.properties.subject.code || '';
    }

    const domainPriorityOrder = ['ess', 'ls', 'ps', 'ets'];
    this.sorting.customOrder(this.resource.properties.domains, domainPriorityOrder);
    this.sorting.customOrder(this.resource.properties.dciStrands, domainPriorityOrder);
    this.sorting.customOrder(this.resource.properties.performanceExpectations, domainPriorityOrder);

    this.orderedDCIStrands = this.resource.properties.dciStrands.sort(this.sorting.orderMultiples);
    this.orderedDomains = this.resource.properties.domains.sort(this.sorting.orderMultiples);
    this.orderedPerformanceExpectations = this.resource.properties.performanceExpectations.sort(this.sorting.orderMultiples);

    switch (this.resource.type) {
      case 'Instructional':
        this.typeShort = 'ir';
        break;
      case 'Connections Playlist':
        this.typeShort = 'cp';
        break;
      case 'Professional Learning':
        this.typeShort = 'pl';
        break;
      case 'Accessibility Strategy':
        this.typeShort = 'as';
        break;
      case 'Formative Assessment Strategy':
        this.typeShort = 'fs';
        break;
      default:
        this.typeShort = 'ir';
        break;
    }

    this.textOnlyResourceSummary = this.makePlainText(this.resource.summary);

  }

  toggleOpenClass(): void {
    this.addOpenClass = !this.addOpenClass;
  }

  makePlainText(html: string): string {
    // Sanitize the HTML input to remove potentially malicious scripts
    const sanitizedHtml = this.sanitizer.sanitize(SecurityContext.HTML, html) || '';
    // Remove HTML tags
    const strippedHtml = sanitizedHtml.replace(/<[^>]+>/g, ' ');
    // Convert HTML entities to text
    const textarea = document.createElement('textarea');
    textarea.innerHTML = strippedHtml;
    return textarea.textContent || '';
  }
}
