<div class="share-title">Share this resource</div>
<div class="form-field"
     cdkTrapFocus
     cdkTrapFocusAutoCapture
>
  <input
    #valueInput
    readonly
    type="text"
    [value]="value"
    tabindex="-1"

  >
  <button
    #button
    class="sbdl-icon-button material-icons top-right-close"
    title="Close Dialog"
    aria-label="Close Dialog"
    (click)="closed.emit()"
    cdkFocusRegionStart
    matRipple
  >
    <i class="fas fa-window-close"></i>
  </button>
  <button
    #focusButton
    class="sbdl-icon-button material-icons"
    title="Copy to clipboard"
    aria-label="Copy to clipboard"
    aria-controls="copied-message"
    (click)="onCopyButtonClick(valueInput, focusButton); gtm.emit()"
    cdkFocusInitial
    matRipple
  >
    <i class="fas fa-copy"></i>
  </button>

  <div
    [hidden]="!(copied$ | async)"
    class="success-message"
    id="copied-message"
    role="alert"
    [attr.aria-expanded]="(copied$ | async) ? 'true' : 'false'"
    cdkFocusRegionEnd
  >
      Copied!
      <button
        class="sbdl-icon-button material-icons"
        title="Close Dialog"
        aria-label="Close Dialog"
        (click)="onCopiedButtonClick(valueInput, focusButton)"
        matRipple
      >
        <i class="fas fa-window-close" style="color: #cccccc"></i>
      </button>
    </div>
</div>
