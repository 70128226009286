import { Component, Input, OnInit } from '@angular/core';
import { LandingPage } from '../../data/landing/model/landingPage.model';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import {TenantThemeService} from '../../data/tenant-theme/tenant-theme.service';
import {Observable} from 'rxjs';
import {TenantTheme} from '../../data/tenant-theme/tenant-theme.model';
import {map} from 'rxjs/operators';

@Component({
  selector: 'sbdl-landing-dive-deeper',
  templateUrl: './landing-dive-deeper.component.html',
  styleUrls: ['../landing-common.component.scss']
})
export class LandingDiveDeeperComponent implements OnInit {

  @Input()
  landingPage: LandingPage;

  tenantName: Observable<string>;
  theme: Observable<TenantTheme>;
  tenantNameGA: string;

  landingPageName: string;

  constructor(
    private gtmService: GoogleTagManagerService,
    private tenantThemeService: TenantThemeService,
  ) {
    this.theme = this.tenantThemeService.currentTenantTheme$;
    this.tenantName = this.theme.pipe(map(t => t.displayName));
  }

  getTenantName() {
    this.tenantName.subscribe(val => this.tenantNameGA = val);
    return this.tenantNameGA;
  }

  getLandingPageTitle() {
    switch (this.landingPage.type) {
      case 'cp': {
        this.landingPageName = 'Interim Connections Playlists';
        break;
      }
      case 'ir': {
        this.landingPageName = 'Instructional Resources';
        break;
      }
      case 'fs': {
        this.landingPageName = 'Formative Strategies';
        break;
      }
      case 'as': {
        this.landingPageName = 'Accessibility Strategies';
        break;
      }
      case 'pl': {
        this.landingPageName = 'Professional Learning Resources';
        break;
      }
      case 'iaip': {
        this.landingPageName = 'Interim Assessment Item Portal';
        break;
      }
    }
    return this.landingPageName;
  }

  gtmEvent(linkUrl, linkTitle) {
    // push GTM data layer with a custom event
    const gtmTag = {
      event: 't4t-asset-event',
      t4t_resource_id: 'Landing Page: ' + this.getLandingPageTitle(),
      t4t_resource_type: 'Landing Page: ' + this.getLandingPageTitle(),
      t4t_resource_title: 'Landing Page: ' + this.getLandingPageTitle(),
      t4t_tenant_name: this.getTenantName(),
      t4t_asset_title: linkTitle,
      t4t_asset_type: 'Pdf',
      t4t_asset_path: linkUrl,
    };
    this.gtmService.pushTag(gtmTag);
  }

  ngOnInit() {
  }

}
