import {Component, Inject, AfterViewInit} from '@angular/core';
import {APP_BASE_HREF} from '@angular/common';
import {Router} from '@angular/router';
import {OktaAuth} from '@okta/okta-auth-js';
import {OKTA_AUTH} from '@okta/okta-angular';
import {StorageService} from '../../common/storage.service';
import {UserService} from '../../data/user/user.service';

@Component({
  selector: 'sbdl-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements AfterViewInit {

  constructor(
    @Inject(APP_BASE_HREF) private appBaseHref: string,
    private storageService: StorageService,
    private userService: UserService,
    private router: Router,
    @Inject(OKTA_AUTH) private oktaAuthService: OktaAuth,
  ) {}

  ngAfterViewInit() {
    this.storageService.remove('userSessionState');
    this.userService.clearTokenExpirationTimer();
    setTimeout(() => this.oktaAuthService.signOut(
      {
        clearTokensBeforeRedirect: true
      }
    ), 2000);
  }
}

