<ng-container [ngSwitch]="attachmentCardType">
  <div
    *ngSwitchCase="CardType.File"
    class="attachment-card file-attachment"
  >
    <div class="type-info">
      <span class="attachment-category">{{ attachment.category }}</span>
      <i class="{{attachment.fileType | fileTypeIcon }}" aria-hidden="true"></i>
    </div>
    <div class="detail-info">
      <div class="file-info">
        {{ attachment.name }}
        <span class="emphasis-medium">(.{{attachment.fileExtension}})</span>
      </div>
      <div class="actions">
        <a
          href="javascript:void(0)"
          (click)="download(attachment);gtmEvent()"
          [attr.aria-label]="'Download ' + fileName"
          [attr.disabled]="isDownloading ? true : null"
          [ngClass]="isDownloading ? 'downloading' : null"
        >
          <span *ngIf="!isDownloading"><i class="far fa-cloud-download" aria-hidden="true"></i></span>
          {{ isDownloading ? 'Downloading...' : 'Download' }}
        </a>
        <sbdl-button class="primary attachment-view"
                     [attr.aria-label]="'View ' + fileName"
                     (click)="openDialogAttachment(attachmentIndex)">
          <i class="fas fa-eye"></i> VIEW
        </sbdl-button>
      </div>
    </div>
  </div>
  <div
    *ngSwitchCase="CardType.Media"
    class="attachment-card media-attachment"
  >
    <div class="type-info">
      <span class="attachment-category">{{ attachment.category }}</span>
      <i class="{{attachment.fileType | fileTypeIcon }}" aria-hidden="true"></i>
    </div>
    <div class="detail-info">
      <span>
        {{ attachment.name }}
      </span>
      <div class="actions">
        <a
          href="javascript:void(0)"
          (click)="showPlayer();gtmEvent()"
          [hidden]="previewMedia"
          [attr.aria-label]="'Watch ' + fileName"
        ><i class="far fa-eye" aria-hidden="true"></i> Watch</a>
      </div>
    </div>
    <div [ngSwitch]="attachment.fileType"
         [ngClass]="{ preview: previewMedia }"
         class="embedded-media-player">
      <ng-template [ngSwitchCase]='FileType.YouTubeLink'>
        <sbdl-able-player
          [hidden]="!previewMedia"
          [youtubeVideoId]="youtubeVideoId"
          [isTranscriptVisable]="isTranscriptVisable"
        ></sbdl-able-player>
      </ng-template>
      <ng-template ngSwitchCaseDefault>
        <div class="unknown-media">
          Unrecognized media type: {{ attachment.mimeType }}
        </div>
      </ng-template>
      <span>
        <sbdl-button
          style="float: left"
          *ngIf="isTranscriptButtonsVisable && isTranscriptVisable"
          class="plain" (click)="hideTranscript()">
          <i class="far fa-compress-alt" aria-hidden="true"></i> Hide Transcript
        </sbdl-button>
        <sbdl-button
          style="float: left"
          *ngIf="isTranscriptButtonsVisable && !isTranscriptVisable"
          class="plain" (click)="showTranscript()">
          <i class="far fa-expand-alt" aria-hidden="true"></i> Show Transcript
        </sbdl-button>
        <p
          style="float: left"
          *ngIf="!isTranscriptButtonsVisable">
          A transcript is not available for this video.
        </p>
        <sbdl-button
          style="float: right"
          class="plain"
          (click)="hidePlayer()">
          <i class="far fa-compress-alt" aria-hidden="true"></i> Close
        </sbdl-button>
    </span>
    </div>
  </div>
  <div
    *ngSwitchCase="CardType.Link"
    class="attachment-card link-attachment"
  >
    <div class="type-info">
      <span class="attachment-category">{{ attachment.category }}</span>
      <i class="far fa-link" aria-hidden="true"></i>
    </div>
    <div class="detail-info">
      <span>
        {{ attachment.name }}
      </span>
      <div class="actions">
        <a
          target="_blank"
          href="{{ attachment.uri }}"
          (click)="gtmEvent()"
          [attr.aria-label]="attachment.name + ' (opens in a new window)'"
        ><i class="far fa-external-link" aria-hidden="true"></i> Visit</a>
      </div>
    </div>
  </div>
</ng-container>
