<h2 class="outline-header">
  <span *ngIf="readingMode && !mobile">
    <i class="fas fa-bars"></i>
  </span>
  Outline
</h2>
<ul class=section-list *ngIf="outline">
  <li *ngFor="let section of sectionsInOrder; let sectionIndex = index">
    <div class="section-header">
      <sbdl-button  class="outline"
                    (click)="scrollTo(section); select(sectionIndex);"
                    rippleColor="#e2f8ff"
                    [ngClass]="{'selected-section': isActive(sectionIndex)}"
                    >
        <sbdl-icon *ngIf="section.sbdlIcon" [icon]="section.sbdlIcon"></sbdl-icon>
        <i *ngIf="section.fontAwesomeIcon" class="far" [ngClass]="section.fontAwesomeIcon"></i>
        {{ section.title }}
      </sbdl-button>
    </div>
    <ol class="subsection-list"
        *ngIf="section.subsections && section.subsectionsNumbered">
      <li *ngFor="let subsection of section.subsections; let numberedSubsectionIndex = index">
        <sbdl-button class="subsection"
                     (click)="scrollTo(subsection); select(numberedSubsectionIndex + 100);"
                      rippleColor="#e2f8ff"
                     [ngClass]="{'selected-section': isActive(numberedSubsectionIndex + 100)}">
          {{ subsection.title }}
        </sbdl-button>
      </li>
    </ol>
    <ul class="subsection-list"
        *ngIf="section.subsections && !section.subsectionsNumbered">
      <li *ngFor="let subsection of section.subsections; let subsectionIndex = index">
        <sbdl-button class="subsection"
                     (click)="scrollTo(subsection); select(subsectionIndex + 200);"
                     rippleColor="#e2f8ff"
                     [ngClass]="{'selected-section': isActive(subsectionIndex + 200)}">
          {{ subsection.title }}
        </sbdl-button>
      </li>
    </ul>
  </li>
</ul>
