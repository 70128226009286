<div [id]="'video-chapters-' + youtubeVideoId"></div>
<video #video
  [id]="'video-player-' + youtubeVideoId"
  data-able-player
  preload="auto"
  data-captions-position="overlay"
  [attr.data-youtube-id]="youtubeVideoId"
  [attr.data-youtube-desc-id]="youtubeVideoId"
  [attr.data-chapters-div]="'video-chapters-' + youtubeVideoId"
  [attr.data-transcript-div]="'video-transcript-' + youtubeVideoId"
  data-include-transcript="false"
  ></video>
<div 
  #transcriptArea
  [ngClass]="{'transcriptHide': !isTranscriptVisable }"
  [id]="'video-transcript-' + youtubeVideoId">
</div>
