import {Component, Inject, OnInit} from '@angular/core';
import { ActivatedRoute, NavigationStart, NavigationEnd, Router } from '@angular/router';
import {Observable, Subject} from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Location } from '@angular/common';
import { urlPathPart } from 'src/app/common/utils';
import { OKTA_CALLBACK_PATH } from 'src/app/common/constants';
import {ResourceService} from '../../data/resource/resource.service';
import {StorageService} from '../../common/storage.service';
import { environment} from '../../../environments/environment';

@Component({
  selector: 'sbdl-app-container',
  templateUrl: './app-container.component.html',
  styleUrls: ['./app-container.component.scss']
})
export class AppContainerComponent implements OnInit {
  routerPath: string;

  constructor(
    @Inject('Window') private window: Window,
    private route: ActivatedRoute,
    private location: Location,
    private router: Router,
    private resourceService: ResourceService,
    private storageService: StorageService
  ) {
    this.routerPath = router.url;
    if (
      !environment.envName ||
      environment.envName === 'default' ||
      environment.envName === 'dev' ||
      environment.envName === 'qa' ||
      environment.envName === 'uat' ||
      environment.envName === 'stage'
    ) {
      this.devEnvStart = false;
    } else {
      this.devEnvStart = true;
    }
    this.devEnv = false;
  }

  routeLoading = false;
  resourcePageFooterView$: Observable<boolean>;

  get locationPath() { return this.location.path(); }

  private oldPath = '/';
  private loading$ = new Subject<boolean>();
  private devEnvStart: boolean;
  public devEnv: boolean;
  public devLoggedIn: boolean;

  ngOnInit() {

    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationStart) {
        this.loading$.next(true);
        this.routerPath = urlPathPart(evt.url);
      } else if (evt instanceof NavigationEnd) {
        this.loading$.next(false);
        const newPath = urlPathPart(evt.url);
        if (newPath !== this.oldPath) {
          this.window.scrollTo(0, 0);
        }
        this.oldPath = newPath;
        this.routerPath = newPath;
      }
    });

    this.route.fragment.subscribe((fragment) => {
      if (fragment && !this.router.url.includes(OKTA_CALLBACK_PATH)) {
        setTimeout(() => {
          const el = document.querySelector('#' + fragment);
          if (el) { el.scrollIntoView({behavior: 'smooth'}); }
        });
      }
    });

    this.resourcePageFooterView$ = this.resourceService.resourcePageFooter;

    this.loading$
      .pipe(debounceTime(250))
      .subscribe(loading => this.routeLoading = loading);

    if (this.devEnv === false && this.devEnvStart === false) {
      this.devEnv = true;
    }

    this.route.queryParams.subscribe(params => {
      const isAdmin = params.admin === 'true';
      if (isAdmin) {
        this.devEnv = false;
        this.devLoggedIn = true;
        this.storageService.set('devLoggedIn', 'true');
      }
    });

    if (this.storageService.get('devLoggedIn') === 'true') {
      this.devEnv = false;
      this.devLoggedIn = true;
    }

  }

  onDevEnvAction(devEnvState: boolean) {
    this.devEnv = devEnvState;
  }

}
