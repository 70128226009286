<div *ngIf="parentResource.relatedProfLearningResources.length">
  <h3 class="label"
      [ngPlural]="parentResource.relatedProfLearningResources.length"
  >
    <ng-template ngPluralCase="=1">Related Resource</ng-template>
    <ng-template ngPluralCase="other">Related Resources</ng-template>
  </h3>
  <div class="detail-container">
    <div class="details">
      <div *ngFor="let relatedResource of parentResource.relatedProfLearningResources">

        <sbdl-resource-card
          resourceUrl="resource/{{ relatedResource.id }}"
          [text]="relatedResource.title"
          description="{{ relatedResource.title }}"
        >
          <sbdl-resource-type-icon
            class="link-icon"
            [type]="relatedResource.type"
          ></sbdl-resource-type-icon>
        </sbdl-resource-card>
      </div>
    </div>
  </div>
</div>






