export const environment = {
  production: false,
  envName: 'dev',
  googleTagManagerId: 'GTM-W2PXQSN',
  googleAnalyticsID: 'G-8954NSHC9G',
  oktaIssuer: 'https://smarterbalanced.oktapreview.com/oauth2/ausr8b7sqjHONBENP0h7',
  oktaclientId: '0oamt7hm566G44ZVm0h7'
};

import 'zone.js/dist/zone-error';  // Included with Angular CLI.
