<hr class=print-only />
<article #header class="overview-container">
  <h2 class="leading-icon">
    <i class="far fa-hand-point-right"></i>Get Started
  </h2>
  <div>
    <h3>
      Overview
      <sbdl-tooltip
        text="A brief summary of the instructional task."
      ><sbdl-icon icon="info"></sbdl-icon></sbdl-tooltip>
    </h3>
    <p>
      <sbdl-read-more [text] = "resource.getStarted.overview"></sbdl-read-more>
    </p>
  </div>
  <div>
    <h3>
      Learning Goal
      <sbdl-tooltip
        text="What learning students are to develop (e.g., Students know, apply, or understand)."
      ><sbdl-icon icon="info"></sbdl-icon></sbdl-tooltip>
    </h3>
    <p>
      <sbdl-read-more [text] = "resource.getStarted.learningGoal"></sbdl-read-more>
    </p>
  </div>
  <div>
    <h3>
      Success Criteria
      <sbdl-tooltip
        text="How students can demonstrate learning (e.g., Students explain, describe, or write)."
      ><sbdl-icon icon="info"></sbdl-icon></sbdl-tooltip>
    </h3>
    <dynamic-html
      [content]="resource.getStarted.successCriteria"
    ></dynamic-html>
  </div>
</article>
<hr/>
