<article #sectionHeader class="overview-container">
  <h2 class="leading-icon">
    <i class="far fa-hand-point-right"></i>
      Overview
  </h2>
  <div>
    <h3>
      Description
      <sbdl-tooltip
        text="A brief summary of the professional learning task."
      ><sbdl-icon class="tooltip-icon" icon="info"></sbdl-icon></sbdl-tooltip>
    </h3>
    <p>
      <sbdl-read-more [text] = "resource.overview.overview"></sbdl-read-more>
    </p>
  </div>
  <div>
    <h3>
      Learning Goal
      <sbdl-tooltip
        text="What learning students are to develop (e.g., Students know, apply, or understand)."
      ><sbdl-icon class="tooltip-icon" icon="info"></sbdl-icon></sbdl-tooltip>
    </h3>
    <p>
      <sbdl-read-more [text] = "resource.overview.learningGoal"></sbdl-read-more>
    </p>
  </div>
  <div>
    <h3>
      Success Criteria
      <sbdl-tooltip
        text="How students can demonstrate learning (e.g., Students explain, describe, or write)."
      ><sbdl-icon class="tooltip-icon" icon="info"></sbdl-icon></sbdl-tooltip>
    </h3>
    <div [innerHtml]="resource.overview.successCriteria"></div>
  </div>
</article>
<hr/>
