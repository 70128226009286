import { Component, Input, OnInit } from '@angular/core';
import {Resource} from '../../../data/resource/model/resource.model';
import {Observable} from 'rxjs';
import { map } from 'rxjs/operators';
import {TenantTheme} from '../../../data/tenant-theme/tenant-theme.model';
import {GoogleTagManagerService} from 'angular-google-tag-manager';
import {TenantThemeService} from '../../../data/tenant-theme/tenant-theme.service';
import { Router } from '@angular/router';

@Component({
  selector: 'sbdl-resource-card',
  templateUrl: './resource-card.component.html',
  styleUrls: ['./resource-card.component.scss'],
})
export class ResourceCardComponent implements OnInit {
  @Input()
  text: string;
  @Input()
  resourceUrl: string;
  @Input()
  description: string;
  @Input()
  ga: boolean;
  @Input()
  assetType: string;
  @Input()
  icon: string;
  @Input()
  resource: Resource;

  tenantName: Observable<string>;
  theme: Observable<TenantTheme>;
  tenantNameGA: string;

  get properties() {
    return this.resource.properties;
  }

  constructor(
    private gtmService: GoogleTagManagerService,
    private tenantThemeService: TenantThemeService,
    private router: Router
  ) {
    this.theme = this.tenantThemeService.currentTenantTheme$;
    this.tenantName = this.theme.pipe(map(t => t.displayName));
  }

  getTenantName() {
    this.tenantName.subscribe(val => this.tenantNameGA = val);
    return this.tenantNameGA;
  }

  clickedOnLink() {
    if (this.resourceUrl.startsWith('http')) {
      window.open(this.resourceUrl, '_blank');
    } else {
      this.router.navigate([this.resourceUrl]);
    }

    if (this.ga) {
      this.gtmEvent();
    }
  }

  gtmEvent() {
    // push GTM data layer with a custom event
    const gtmTag = {
      event: 't4t-asset-event',
      t4t_resource_id: this.resource.id,
      t4t_resource_type: this.resource.type,
      t4t_resource_title: this.properties.title,
      t4t_tenant_name: this.getTenantName(),
      t4t_asset_title: this.text,
      t4t_asset_type: this.assetType,
      t4t_asset_path: this.resourceUrl,
    };
    this.gtmService.pushTag(gtmTag);
  }

  ngOnInit() {
  }
}
