<button
  class="bookmark sbdl-icon-button material-icons"
  title="Toggle Bookmark"
  [attr.aria-label]="
    updatingBookmarked
      ? 'Bookmark updating...'
      : bookmark
        ? 'Bookmarked (click to remove the bookmark)'
        : 'Not bookmarked (click to bookmark)'
  "
  (click)="toggleBookmarked()"
>
  <div *ngIf="updatingBookmarked">
    <i class="fas fa-spinner fa-spin"></i>
  </div>
  <div *ngIf="!updatingBookmarked && bookmark">
    <i class="fas fa-bookmark"></i>
  </div>
  <div *ngIf="!updatingBookmarked && !bookmark">
    <i class="far fa-bookmark"></i>
  </div>
</button>

<button
  class="notes sbdl-icon-button material-icons"
  title="Notes"
  [routerLink]="['/resource', resourceSummary.id]"
  [queryParams]="{viewNotes: true}"
>
  <sbdl-icon
    [ngClass]="{'has-notes': hasNotes}"
    icon="take-note"
  ></sbdl-icon>
</button>

<button
  class="share sbdl-icon-button material-icons"
  title="Share"
  [attr.aria-label]="'share this resource'"
  cdkOverlayOrigin
  matRipple
  #trigger="cdkOverlayOrigin"
  (click)="shareOverlayOpen = !shareOverlayOpen"
>
  <i class="far fa-share"></i>
</button>


<ng-template
  cdkConnectedOverlay
  cdkConnectedOverlayPanelClass="windowed"
  cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
  [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayOpen]="shareOverlayOpen"
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayPositions]="shareOverlayPositions"
  [cdkConnectedOverlayPush]="true"
  (backdropClick)="shareOverlayOpen = false"
>
  <sbdl-share-form

    [value]="shareValue"
    (closed)="shareOverlayOpen = false"
  ></sbdl-share-form>
</ng-template>

<button
  class="print sbdl-icon-button material-icons"
  title="Print"
  [attr.aria-label]="'print this resource.'"
  [routerLink]="['/resource', resourceSummary.id]"
  [queryParams]="{print: true}">
  <i class="far fa-print" ></i>
</button>
