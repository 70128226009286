import {Component, Inject, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {AttachmentsService} from '../attachments.service';
import {FileType, ResourceAttachment} from 'src/app/data/resource/model/attachment.model';
import {CardType} from '../attachments.component';
import {AblePlayerComponent} from 'src/app/resource/components/able-player/able-player.component';
import {Resource} from '../../../../data/resource/model/resource.model';
import {GoogleTagManagerService} from 'angular-google-tag-manager';
import {TenantThemeService} from 'src/app/data/tenant-theme/tenant-theme.service';
import {TenantTheme} from 'src/app/data/tenant-theme/tenant-theme.model';
import {UserService} from 'src/app/data/user/user.service';
import {Observable, Subscription} from 'rxjs';
import {map} from 'rxjs/operators';
import {DataService} from '../../../../data/data.service';
import {OktaAuth} from '@okta/okta-auth-js';
import {OKTA_AUTH} from '@okta/okta-angular';
import {AttachmentsComponent} from '../attachments.component';

const YT_MATCH_VID = /.*youtube.*v=([^&]+).*$|.*youtu.be\/([^&?]+).*$|.*youtube\/embed\/([^&?]+).*$/;

function extractYouTubeVideoId(attachment: ResourceAttachment): string {
  if (attachment.fileType !== FileType.YouTubeLink) {
    throw new Error('Cannot extract YouTube video ID from attachment type ' + attachment.fileType);
  }

  const matches = attachment.uri.match(YT_MATCH_VID);
  if (!matches) {
    throw new Error('Cannot extract video ID from unrecognized YouTube URL pattern:' + attachment.uri);
  }
  return matches[1] || matches[2] || matches[3];
}

@Component({
  selector: 'sbdl-attachment-card',
  templateUrl: './attachment-card.component.html',
  styleUrls: ['./attachment-card.component.scss']
})
export class AttachmentCardComponent {

  previewMedia = false;
  isTranscriptVisable = false;
  isTranscriptButtonsVisable = true;
  fileName: string;
  youtubeVideoId: string;
  tenantName: Observable<string>;
  theme: Observable<TenantTheme>;
  tenantNameGA: string;
  isDownloading = false;
  subscription: Subscription;
  authenticated$: Observable<boolean>;
  _attachment: ResourceAttachment;
  CardType = CardType;
  FileType = FileType;

  @Input()
  attachmentCardType: CardType;

  @Input()
  resource: Resource;

  @Input()
  attachmentIndex;

  @Input()
  set attachment(value: ResourceAttachment) {
    this._attachment = value;
    this.fileName = `${value.name}.${value.fileExtension}`;
    if (value.fileType === FileType.YouTubeLink) {
      this.youtubeVideoId = extractYouTubeVideoId(value);
    }
  }

  get attachment(): ResourceAttachment {
    return this._attachment;
  }

  @ViewChild(AblePlayerComponent, { static: false })
  private ablePlayer: AblePlayerComponent;

  constructor(
    @Inject('Window') private window: Window,
    private sanitizer: DomSanitizer,
    private attachmentsService: AttachmentsService,
    private gtmService: GoogleTagManagerService,
    private tenantThemeService: TenantThemeService,
    private userService: UserService,
    private dataService: DataService,
    private attachmentsComponent: AttachmentsComponent,
    @Inject(OKTA_AUTH) private oktaAuthService: OktaAuth
  ) {
    this.authenticated$ = this.userService.authenticated;
    this.theme = this.tenantThemeService.currentTenantTheme$;
    this.tenantName = this.theme.pipe(map(t => t.displayName));
  }

  get properties() {
    return this.resource.properties;
  }

  openDialogAttachment(index) {
    this.attachmentsComponent.openDialog(index);
  }

  getTenantName() {
    this.tenantName.subscribe(val => this.tenantNameGA = val);
    return this.tenantNameGA;
  }

  gtmEvent() {
    // push GTM data layer with a custom event
    const gtmTag = {
      event: 't4t-asset-event',
      t4t_resource_id: this.resource.id,
      t4t_resource_type: this.resource.type,
      t4t_resource_title: this.properties.title,
      t4t_tenant_name: this.getTenantName(),
      t4t_asset_title: this.attachment.name,
      t4t_asset_type: FileType[this.attachment.fileType],
      t4t_asset_path: this.attachment.uri,
    };
    this.gtmService.pushTag(gtmTag);
  }

  download(attachment: ResourceAttachment): void {
    if (this.isDownloading !== true) {
      this.isDownloading = true;
      this.attachmentsService.download(attachment).then(
        r => {
          if (r) {
            this.isDownloading = false;
          } else {
            alert('This attachment is currently unavailable.');
            this.isDownloading = false; // Ensure that isDownloading is set to false even on error
          }
        }
      );
    }
  }

  showPlayer(): void {
    this.ablePlayer.show();
    this.previewMedia = true;
  }

  hidePlayer(): void {
    this.ablePlayer.hide();
    this.previewMedia = false;
  }

  hideTranscript(): void {
    this.isTranscriptVisable = false;
  }

  showTranscript(): void {
    this.isTranscriptVisable = true;
    this.isTranscriptButtonsVisable = this.ablePlayer.testTranscript();
    }
}
