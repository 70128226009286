<li
  [ngClass]="getClasses()"
  routerLinkActive="active"
  [routerLinkActiveOptions]="{exact: true}"
  class="nav-link"
  (click)="isLinkDisabled ? $event.preventDefault() : navigate()"
>
  <a
    [title]="title"
    [attr.aria-label]="ariaLabel"
    [tabIndex]="isLinkDisabled ? -1 : tabIndexLink"
    [class.disabled]="isLinkDisabled"
  >

    <div class="image-area" *ngIf="icon" [class.disabled]="isLinkDisabled">
      <i [class]="icon" aria-hidden="true"></i>
    </div>
    <div class="image-area" *ngIf="sbdlIcon" [class.disabled]="isLinkDisabled">
      <sbdl-icon [icon]="sbdlIcon" aria-hidden="true"></sbdl-icon>
    </div>
    <span>{{ title }}</span>
  </a>
</li>
