<!-- Sample sections -->
<div *ngIf="landingPage.sampleSections && landingPage.sampleSections.sampleResources.length > 0"
    class="rightSideSections">

  <h2 class="sample-section">{{landingPage.sampleSections.header}}</h2>

  <a
    *ngFor="let sp of landingPage.sampleSections.sampleResources"
    [routerLink]="'/resource/' + sp.id"
    [attr.aria-label]="sp.title + ' (opens in a new window)'"
    class="card-blue-left-stripe"
  >
    <div class="type-info">
      <div class="text-content">
        <span class="text-title">
          {{sp.title}}
        </span>
        <p *ngIf="sp.detail && sp.detail.length > 0" class="text-detail">
          {{sp.detail}}
        </p>
      </div>
      <ng-container [ngSwitch]="landingPage.type">
        <i class="far fa-chart-network icon-lg" *ngSwitchCase="'cp'"aria-hidden="true"></i>
        <sbdl-icon icon="steps" class="icon-lg" *ngSwitchCase="'ir'" aria-hidden="true"></sbdl-icon>
        <sbdl-icon icon="strategies" class="icon-lg" *ngSwitchCase="'fs'" aria-hidden="true"></sbdl-icon>
        <i class="far fa-universal-access icon-lg" *ngSwitchCase="'as'" aria-hidden="true"></i>
        <sbdl-icon icon="goal" class="icon-lg" *ngSwitchCase="'pl'"  aria-hidden="true"></sbdl-icon>
        <i class="fas fa-badge-check icon-lg" *ngSwitchCase="'iaip'" aria-hidden="true"></i>
      </ng-container>
    </div>


  </a>
</div>
