<a
  [attr.aria-label]="description"
  class="card-blue-left-stripe"
  #link
  (click)="clickedOnLink()"
>
  <div class="type-info">
    <span class="text-content">{{ text }}</span>
    <sbdl-icon
      *ngIf="icon"
      [icon]="icon"
      class="icon-lg"
      aria-hidden="true"
    ></sbdl-icon>
    <ng-content></ng-content>
  </div>
</a>
