import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FilterCommonWordsService {

  common = 'i, me, my, myself, we, our, ours, ourselves, you, your, yours, yourself, yourselves, he, him, his, ' +
    'himself, she, her, hers, herself, it, its, itself, they, them, their, theirs, themselves, what, which, who, ' +
    'whom, this, that, these, those, am, is, are, was, were, be, been, being, have, has, had, having, do, does, did, ' +
    'doing, a, an, the, and, but, if, or, because, as, until, while, of, at, by, for, with, about, against, between,' +
    ' into, through, during, before, after, above, below, to, from, up, down, in, out, on, off, over, under, again,' +
    ' further, then, once, here, there, when, where, why, how, all, any, both, each, few, more, most, other, some,' +
    ' such, no, nor, not, only, own, same, so, than, too, very, s, t, can, will, just, don, should, now';

  constructor() { }

  getFilteredWords(sentence) {
    let wordArr;
    let commonObj = {};
    let uncommonArr = [];
    let i;
    let commonWords;
    const noPunctuationSentence = sentence.replace(/[^a-zA-Z0-9 ]/g, ' ');
    wordArr = noPunctuationSentence.match(/\w+/g) || []; // Ensure wordArr is an array
    commonObj = {};
    uncommonArr = [];
    // Assuming this.common is defined somewhere in your code
    commonWords = this.common.split(',');

    for (i = 0; i < commonWords.length; i++) {
      commonObj[commonWords[i].trim()] = true;
    }

    for (i = 0; i < wordArr.length; i++) {
      const word = wordArr[i].trim().toLowerCase();
      if (!commonObj[word]) {
        uncommonArr.push(word);
      }
    }

    return uncommonArr;
  }


}
