import {
  Component,
  ElementRef,
  EventEmitter,
  Input, OnChanges,
  OnDestroy,
  OnInit,
  Output, SimpleChanges,
  ViewChild
} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Observable, Subscription} from 'rxjs';
import {filter} from 'rxjs/internal/operators/filter';
import {emptyFilters, Filter, SearchFilters} from '../data/search/search-filters.model';
import {whitelistKeys} from '../common/utils';
import { PopoverComponent } from '../common/controls/popover/popover.component';
import { SearchTextFieldComponent } from '../common/controls/search-text-field/search-text-field.component';
import { LoginWarningService } from './login-warning/login-warning.service';
import { LoginWarningComponent } from './login-warning/login-warning.component';
import { SessionStateKey } from '../common/enums/session-state-key.enum';
import { SearchService } from '../data/search/search.service';
import { Title } from '@angular/platform-browser';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import {TenantThemeService} from '../data/tenant-theme/tenant-theme.service';
import {TenantTheme} from '../data/tenant-theme/tenant-theme.model';
import {FilterCommonWordsService} from '../common/filter-common-words.service';
import {SearchQueryParams} from '../data/search/search-query-params.model';
import {map} from 'rxjs/operators';
import {SearchTextService} from '../data/search/search-text.service';

@Component({
  selector: 'sbdl-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})

export class SearchComponent implements  OnInit, OnDestroy, OnChanges {

  @Input() filters: SearchFilters = emptyFilters;
  @Input() defaultFilters: SearchFilters;
  @Input() numResults: number;
  @Input() showHeadings = true;
  @Input() searchPage = false;
  @Input() navSearch = false;
  @Input() InstanceId = 0;
  @Output() goToResults = new EventEmitter<boolean>();
  @ViewChild(SearchTextFieldComponent, { static: false }) searchInputTextField: SearchTextFieldComponent;
  @ViewChild('filterContainer') filterContainer!: ElementRef;
  @ViewChild('filterResourceType') filterResourceType!: ElementRef;
  @ViewChild('loginWarningPopover', { static: false }) loginWarningPopover: ElementRef;
  @ViewChild('loginWarning', { static: false }) loginWarning: LoginWarningComponent;

  public params: SearchQueryParams;
  private savedParams: SearchQueryParams;
  public popover: PopoverComponent;
  public filterLoading = false;
  private tenantName: Observable<string>;
  private theme: Observable<TenantTheme>;
  private tenantNameGA: string;

  private routerSubscription: Subscription;
  private loginWarningCloseSubscription: Subscription;
  private filterResourcesClicked: boolean;
  private searchTimeout: any;
  public combinedOptions: any[] = [];
  private filterName: any;
  public showAdvanced = false;
  private searchTextSubscription: Subscription;
  public typedText: string;
  public selectedFilters = [
    { type: 'resourceTypes', selectedValues: [] },
    { type: 'grades', selectedValues: [] },
    { type: 'targets', selectedValues: [] },
    { type: 'standards', selectedValues: [] },
    { type: 'dciStrands', selectedValues: [] },
    { type: 'performanceExpectations', selectedValues: [] },
    { type: 'subjects', selectedValues: [] },
    { type: 'claims', selectedValues: [] },
    { type: 'domains', selectedValues: [] },
    { type: 'contentArea', selectedValues: [] },
  ];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private loginWarningService: LoginWarningService,
    private searchService: SearchService,
    private titleService: Title,
    private gtmService: GoogleTagManagerService,
    private tenantThemeService: TenantThemeService,
    private searchFilterService: FilterCommonWordsService,
    private searchTextService: SearchTextService
  ) {
    this.theme = this.tenantThemeService.currentTenantTheme$;
    this.tenantName = this.theme.pipe(map(t => t.displayName));
  }

  ngOnInit() {

    // This really is how things work, we use the params to run the search and also decide on display.
    this.route.params.subscribe(params => {
      this.selectedFilters.forEach(filterType => {
        if (params[filterType.type]) {
          const codes = params[filterType.type].split(',');
          filterType.selectedValues = codes.map(code => ({
            code,
            title: this.getTitleForCode(code, filterType.type)
          }));
        } else {
          filterType.selectedValues = [];
        }
      });
    });

    // Just for Google Analytics
    this.getTenantName();

    // Resetting the title in the browser, again for Google Analytics
    if (this.router.url.includes('/search')) {
      this.titleService.setTitle('Search: Tools for Teachers - Smarter Balanced');
    }

    // Setting up the params the way we like them
    this.params = this.rectifyParams(this.parseParams(this.route.snapshot.params || {}));

    // If something changes in the params let us know
    this.routerSubscription = this.router.events
      .pipe(filter(e => e instanceof NavigationEnd))
      .subscribe(() => {
        this.params = this.rectifyParams(this.parseParams(this.route.snapshot.params || {}));
      });

    // Login warning service
    this.loginWarningService.onWarningClosed.subscribe(() => {
      requestAnimationFrame(() => this.searchInputTextField.textFieldRef.nativeElement.focus());
      this.search({ ...this.savedParams }, false);
    });

    // Initiate combined options for the content area select (combined options is claims and domains)
    this.makeCombinedOptions();

    // Get the current search text
    this.searchTextSubscription = this.searchTextService.searchText$.subscribe((data) => {
      // Update the value only if it is different to prevent triggering a loop
      if (data !== this.typedText) {
        this.typedText = data;
      }
    });
  }

  // Check if the `filters` input has changed
  ngOnChanges(changes: SimpleChanges) {
    if (changes.filters) {
      // Call the method to match filters with default filters so that users can only select available options with
      // the current filters
      this.matchFiltersAndDefaultFilters();
    }
  }

  // Get rid of subscriptions so we don't leak
  ngOnDestroy() {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }

    if (this.loginWarningCloseSubscription) {
      this.loginWarningCloseSubscription.unsubscribe();
    }
  }

  // Take a params object and filter down it's key discarding anything that is not a valid SearchQueryParam property.
  private parseParams(params: object): SearchQueryParams {
    return whitelistKeys(params as SearchQueryParams,
      [ 'query', 'claims', 'contentArea', 'grades', 'subjects', 'targets', 'standards',
        'resourceTypes', 'domains', 'dciStrands', 'performanceExpectations' ]);
  }

  // Take a valid SearchQueryParams object and make sure it only has correct format
  rectifyParams(params: SearchQueryParams, gtm?): SearchQueryParams {
    if (params.query && gtm) {
      this.gtmEvent(params.query);
    }
    const result = {...params};
    for (const key in result) {
      if (!result[key] || result[key].length === 0) {
        delete result[key];
      }
    }
    return result;
  }

  // This is the meat and potatoes of the whole page, it gets called a lot. It runs on parameters - that's how we
  // store what is going to be searched. All the rest is just for display, ultimately we need the params to be
  // correct if we're going to run the search.
  search(newParams: SearchQueryParams, checkLoginWarning: boolean, source?) {
    // Login warning logic
    let sourceRef: ElementRef;
    switch (source) {
      case 'filterContainer':
        sourceRef = this.filterContainer;
        break;
      case 'filterResourceType':
        sourceRef = this.filterResourceType;
        break;
      default:
        sourceRef = this.searchInputTextField.textFieldRef;
        break;
    }
    this.params = this.rectifyParams(this.parseParams(this.route.snapshot.params || {}));
    if (checkLoginWarning && this.loginWarningService.shouldDisplay(SessionStateKey.searchLoginWarningDisplayed) && !this.navSearch) {
      this.savedParams = { ...newParams };
      this.paramsToSearchUrl(this.rectifyParams({...this.params, query: this.filters.query, ...newParams}));
      this.loginWarningService.displayLoginWarning(this.loginWarningPopover, sourceRef,
        SessionStateKey.searchLoginWarningDisplayed);
    } else {
      // Finally we make the call! Search away!
      this.router.navigate(['search', this.rectifyParams({
        ...this.params,
        query: this.filters.query,
        ...newParams
      }, true)]);
    }
  }

  // Check which filters are available with current search
  private matchFiltersAndDefaultFilters(): void {
    // Check if both defaultFilters and Filters are there
    if (this.defaultFilters && this.filters) {
      // Go through the defaultFilters object and find each filterType
      Object.keys(this.defaultFilters).forEach(filterType => {
        if (Array.isArray(this.defaultFilters[filterType])) {
          // Go through each part of that filterType and check each entry in the array and assign disabled state
          this.defaultFilters[filterType].forEach(defaultFilter => {
            const filterExistsInFilters = Array.isArray(this.filters[filterType])
              ? this.filters[filterType].some(filterName => filterName.code === defaultFilter.code) : false;
            defaultFilter.disabled = !filterExistsInFilters;
          });
        }
      });
      this.sortDefaultFilters();
      this.makeCombinedOptions();
    }
  }

  //  Sorts filters so available filters are first
  private sortDefaultFilters(): void {
    Object.keys(this.defaultFilters).forEach(filterType => {
      if (Array.isArray(this.defaultFilters[filterType])) {
        if (filterType === 'targets' || filterType === 'standards' || filterType === 'dciStrands' || filterType === 'performanceExpectations') {
          this.defaultFilters[filterType].sort((a, b) => {
            // Set disabled state, treating missing 'disabled' as true
            const aDisabled = a.disabled === undefined ? true : a.disabled;
            const bDisabled = b.disabled === undefined ? true : b.disabled;

            // Sort by disabled
            if (aDisabled !== bDisabled) { return aDisabled ? 1 : -1; }

            // Then sort by originalOrder (set in search-results)
            const aOrder = a.originalOrder === undefined ? Number.MAX_SAFE_INTEGER : a.originalOrder;
            const bOrder = b.originalOrder === undefined ? Number.MAX_SAFE_INTEGER : b.originalOrder;
            return aOrder - bOrder;
          });
        }
      }
    });
  }

  // Special combination for ContentArea
  private makeCombinedOptions() {
    // Combine claims and domains for one Content Area select
    if (this.defaultFilters?.claims && this.defaultFilters?.domains) {
      // First, add a 'type' property to each item in claims and domains
      const claimsWithTypes = this.defaultFilters.claims.map(claim => ({ ...claim, type: 'claim' }));
      const domainsWithTypes = this.defaultFilters.domains.map(domain => ({ ...domain, type: 'domain' }));

      // Then, combine the arrays
      this.combinedOptions = [...claimsWithTypes, ...domainsWithTypes];
      // this.defaultFilters.contentArea = this.combinedOptions;
    }
  }

  // For landing page
  onFilterResourcesClick() {
    this.filterResourcesClicked = true;
    if (this.loginWarningService.shouldDisplay(SessionStateKey.searchLoginWarningDisplayed)) {
      this.loginWarningService.displayLoginWarning(this.loginWarningPopover,
        this.searchInputTextField.textFieldRef, SessionStateKey.searchLoginWarningDisplayed);
    } else {
      this.filterLoading = true;
    }
  }

  // This is for the resource types which are "chips"
  onChipSelectChange(code: string, title: string, filterRef) {
    // Attempt to find the resourceTypes filter in the selectedFilters array
    let resourceTypeFilter = this.selectedFilters.find(f => f.type === 'resourceTypes');

    // If resourceTypes filter doesn't exist, create it and add to selectedFilters
    if (!resourceTypeFilter) {
      resourceTypeFilter = { type: 'resourceTypes', selectedValues: [] };
      this.selectedFilters.push(resourceTypeFilter);
    }

    // Check if the code is already in the selectedValues
    const index = resourceTypeFilter.selectedValues.findIndex(item => item.code === code);

    // Add or remove it (toggle)
    if (index > -1) {
      resourceTypeFilter.selectedValues.splice(index, 1);
    } else {
      resourceTypeFilter.selectedValues.push({ code, title });
    }

    // Proceed with the search update trigger for resourceTypes
    this.updateSearchAndTrigger('resourceTypes', filterRef);
  }

  // This is for all the other filters
  onSelectDropdownChange(filterType: string, selectedItems: { code: string, title: string, type: string }[], filterRef?) {
    if (filterType === 'contentArea') {
      const claimsFilters = this.getFilterByName('claims');
      const domainsFilters = this.getFilterByName('domains');
      claimsFilters.selectedValues = [];
      domainsFilters.selectedValues = [];
      const claims = selectedItems.filter(item => item.type === 'claim');
      if (claims.length > 0) { claimsFilters.selectedValues = claims; }
      const domains = selectedItems.filter(item => item.type === 'domain');
      if (domains.length > 0) { domainsFilters.selectedValues = domains; }
    } else {
      this.filterName = this.getFilterByName(filterType);
      this.filterName.selectedValues = selectedItems;
    }
    this.updateSearchAndTrigger(filterType, filterRef);
  }

  // This gets the search parameters ready and then sets off the search
  updateSearchAndTrigger(filterType: string, filterRef?) {
    const searchParams: SearchQueryParams =
      this.selectedFilters.reduce((acc, filterName) => {
      acc[filterName.type] = filterName.selectedValues.map(item => item.code).join(','); return acc; }, {});
    this.params = searchParams;

    // Trigger search with updated params
    this.triggerSearch(searchParams, filterRef);
  }

  // This is a helper function, so we can add to the select
  compareWithFn(item1: any, item2: any) {
    return item1 && item2 ? item1.code === item2.code : item1 === item2;
  }

  // This is just a helper to add a pause if we want that
  triggerSearch(searchParams: SearchQueryParams, filterRef?) {
    // Clear any existing timer
    if (this.searchTimeout) { clearTimeout(this.searchTimeout); }
    // Set timer to call this.search
    this.searchTimeout = setTimeout(() => {
      this.search(searchParams, true, filterRef);
     }, 700); // 1000 milliseconds (1 second)
  }

  // This is for applied filters - this removes the clicked applied filter
  removeFilterValue(filterType: string, valueCode: string) {
    if (filterType === 'contentArea') {
      this.findRemoveFilterValues(filterType, valueCode);
      this.findRemoveFilterValues('claims', valueCode);
      this.findRemoveFilterValues('domains', valueCode);
      this.updateSearchAndTrigger(filterType);
    } else {
      this.findRemoveFilterValues(filterType, valueCode);
      this.updateSearchAndTrigger(filterType);
    }
  }

  // Find remove values
  findRemoveFilterValues(filterType, valueCode) {
    const selectedValuesFromFilterName = this.selectedFilters.find(f => f.type === filterType);
    const index = selectedValuesFromFilterName.selectedValues.findIndex(value => value.code === valueCode);
    if (index !== -1) {
      selectedValuesFromFilterName.selectedValues.splice(index, 1);
      this.updateSearchAndTrigger(filterType);
    }
  }

  // For applied filters - clear all filters
  clearAllFilters(): void {
    this.selectedFilters.forEach(filterName => { filterName.selectedValues = []; });
    this.resetAllDefaultFilters();
    this.updateSearchAndTrigger('');
  }

  private resetAllDefaultFilters(): void {
    // Check if defaultFilters is present
    if (this.defaultFilters) {
      // Iterate over each filter type in defaultFilters
      Object.keys(this.defaultFilters).forEach(filterType => {
        // Check if the filterType is an array and iterate over it if true
        if (Array.isArray(this.defaultFilters[filterType])) {
          this.defaultFilters[filterType].forEach(filterName => {
            // Set each filter's disabled property to false
            filterName.disabled = false;
          });
        }
      });

      // Optionally, if you have a method to re-sort or re-process filters after resetting them
      this.sortDefaultFilters();
    }
  }

  // Helper method to set the url to return to if we set off the login warning
  paramsToSearchUrl(params: any) {
    const parts = Object.keys(params).map(key => `${key}=${params[key]}`);
    return `/search;${parts.join(';')}`;
  }

  // Helper method for display, because we display Resource Types differently, we need to have a way to change the icon
  isResourceTypeSelected(code: string): boolean {
    const resourceTypeFilter = this.selectedFilters.find(filterType => filterType.type === 'resourceTypes');
    if (resourceTypeFilter) {
      return resourceTypeFilter.selectedValues.some(selectedValue => selectedValue.code === code);
    }
    return false;
  }

  // Helper method to reduce duplication
  getFilterByName(filterName: string) {
    return this.selectedFilters.find(filterType => filterType.type === filterName);
  }

  // Helper method to hide and show only selects that are available and the label
  containsSci() {
    return this.defaultFilters?.subjects.some(subject => subject.code === 'sci');
  }

  // Helper method for Applied Filters hide/show
  hasSelectedValues(): boolean {
    return this.selectedFilters.some(filterName => filterName.selectedValues.length > 0);
  }

  // Helper method for Applied Filters button titles
  shouldUseCodeForTitle(filterType): boolean {
    switch (filterType) {
      case 'resourceTypes': return false;
      case 'grades': return false;
      case 'targets': return false;
      case 'standards': return false;
      case 'dciStrands': return true;
      case 'performanceExpectations': return true;
      case 'subjects': return true;
      case 'claims': return false;
      case 'domains': return false;
      case 'contentArea': return false;
      default: return false;
    }
  }

  // Helper for mobile-advanced
  showAdvancedClick() {
    this.showAdvanced = !this.showAdvanced;
  }

  // Helper for title display
  getTitleForCode(code: string, filterType: string): string {
    // Assuming defaultFilters is an object with each property being an array of filters
    const filterCategory = this.defaultFilters[filterType];
    if (filterCategory) {
      const foundFilter = filterCategory.find(filterName => filterName.code === code);
      return foundFilter ? foundFilter.title : '';
    }
    return '';
  }

  // For Google Analytics
  getTenantName() {
    this.tenantName.subscribe(val => this.tenantNameGA = val);
    return this.tenantNameGA;
  }

  // Google Analytics - push to Google Tag Manager
  gtmEvent(searchFull) {
    const searchKeywords = this.searchFilterService.getFilteredWords(searchFull).toString();
    // push GTM data layer with a custom event
    const gtmTag = {
      event: 't4t-search-event',
      t4t_tenant_name: this.getTenantName(),
      t4t_search_keywords: searchKeywords,
      t4t_search_full_search: searchFull,
    };
    this.gtmService.pushTag(gtmTag);
  }

  updateSearchText(text) {
    this.searchTextService.updateSearchText(text);
  }
}
