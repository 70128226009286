import { AfterViewInit, Component, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { NavVisibilityService } from '../../common/nav-visbility.service';
import {NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {emptyFilters} from '../../data/search/search-filters.model';

@Component({
  selector: 'sbdl-overlay',
  templateUrl: './overlay.component.html',
  styleUrls: ['./overlay.component.scss']
})
export class OverlayComponent implements OnInit, AfterViewInit {

  constructor(
    private overlay: Overlay,
    private viewContainerRef: ViewContainerRef,
    private navVisibilityService: NavVisibilityService
  ) {}

  overlayOpen = false;
  @ViewChild('overlayTemplate') overlayTemplate: TemplateRef<any>;
  overlayRef: OverlayRef;
  navVisibilityState = 'closed';

  protected readonly filters = emptyFilters;

  ngOnInit(): void {
    this.navVisibilityState = this.navVisibilityService.getVisibility();
    this.navVisibilityService.currentVisibility.subscribe(visibility => {
      this.navVisibilityState = visibility;
      this.handleVisibilityChange(visibility);
    });
  }

  ngAfterViewInit(): void {
    const overlayConfig = {
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-dark-backdrop',
      positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically()
    };

    this.overlayRef = this.overlay.create(overlayConfig);

    // Close overlay when clicking outside
    this.overlayRef.backdropClick().subscribe((next) => {
      this.closeOverlay();
      this.navVisibilityService.setNavHidden();
    });
  }

  openOverlay(): void {
    if (!this.overlayTemplate) {
      return;
    } else {
      if (this.overlayRef && !this.overlayRef.hasAttached()) {
        const overlayPortal = new TemplatePortal(this.overlayTemplate, this.viewContainerRef);
        this.overlayRef.attach(overlayPortal);
        this.overlayOpen = true;
      }
    }
  }

  closeOverlay(): void {
    if (this.overlayRef && this.overlayRef.hasAttached()) {
      this.overlayRef.detach();
      this.overlayOpen = false;
    }
  }

  handleVisibilityChange(visibility: string): void {
    if (visibility !== 'closed') {
      this.openOverlay();
    } else if (visibility === 'closed') {
      this.closeOverlay();
    }
  }

  closeSideNav() {
    this.navVisibilityService.setNavHidden();
  }
}
