import { Component, ElementRef, Inject, Input, OnInit, ViewChild} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ResourceAttachment } from '../../../../data/resource/model/attachment.model';
import { OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { DomSanitizer } from '@angular/platform-browser';
import { DataService } from '../../../../data/data.service';
import {AttachmentsService} from '../attachments.service';
import * as printJS from 'print-js';


@Component({
  selector: 'sbdl-attachments-gallery',
  templateUrl: './attachments-gallery.component.html',
  styleUrls: ['./attachments-gallery.component.scss']
})
export class AttachmentsGalleryComponent implements OnInit {
  elements: ResourceAttachment[] = [];
  currentIndex = 0;
  accessToken: string;
  pdfSrc;
  imageSrc;
  videoSrc;
  loading = true;
  isFirstElement: boolean;
  isLastElement: boolean;
  @ViewChild('pdfIframe') pdfIframe: ElementRef;
  pausePrint = false;
  pauseDownload = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRefGallery: MatDialogRef<AttachmentsGalleryComponent>,
    @Inject(OKTA_AUTH) private oktaAuthService: OktaAuth,
    private sanitizer: DomSanitizer,
    private dataService: DataService,
    private attachmentsService: AttachmentsService,
    @Inject('Window') private window: Window
  ) {
    if (data && data.fileAttachments) {
      this.elements = data.fileAttachments;
      this.currentIndex = data.attachmentIndex;
      this.updateElementStatus();
    }
  }

  ngOnInit() {
    this.createAttachmentContents(this.elements[this.currentIndex]);
  }

  getTotalElementsCount(): number {
    return this.elements.length;
  }

  getCurrentElementCategory(): string {
    return this.elements[this.currentIndex]?.category || 'N/A';
  }

  getCurrentElementIndex(): number {
    return this.currentIndex + 1;
  }

  updateElementStatus() {
    this.isFirstElement = this.currentIndex === 0;
    this.isLastElement = this.currentIndex === this.elements.length - 1;
  }

  showNextElement() {
    if (!this.isLastElement) {
      this.currentIndex++;
      this.updateElementStatus();
      this.createAttachmentContents(this.elements[this.currentIndex]);
    }
  }

  showPreviousElement() {
    if (!this.isFirstElement) {
      this.currentIndex--;
      this.updateElementStatus();
      this.createAttachmentContents(this.elements[this.currentIndex]);
    }
  }

  jumpToFirstElement() {
    this.loading = true;
    this.currentIndex = 0;
    this.updateElementStatus();
    this.createAttachmentContents(this.elements[this.currentIndex]);
  }

  jumpToLastElement() {
    this.loading = true;
    this.currentIndex = this.elements.length - 1;
    this.updateElementStatus();
    this.createAttachmentContents(this.elements[this.currentIndex]);
  }

  closeDialog() {
    this.dialogRefGallery.close();
  }

  printFile(): void {
    if (!this.pausePrint) {
      this.pausePrint = true;
      if ( this.pdfSrc ) {
        this.pdfIframe.nativeElement.focus();
        this.pdfIframe.nativeElement.contentWindow.print();
        this.pausePrint = false;
      } else if ( this.imageSrc ) {
        this.printImage(this.elements[this.currentIndex]).then(r => this.pausePrint = false);
      }
    }
  }

  downloadFile(): void {
    if (this.pauseDownload !== true) {
      this.pauseDownload = true;
      this.attachmentsService.download(this.elements[this.currentIndex]).then(
        r => {
          if (r) {
            this.pauseDownload = false;
          } else {
            alert('This attachment is currently unavailable.');
            this.pauseDownload = false; // Ensure that isDownloading is set to false even on error
          }
        }
      );
    }
  }

  createAttachmentContents(attachment: ResourceAttachment, print?): Promise<void> {
    this.pdfSrc = null;
    this.imageSrc = null;
    this.videoSrc = null;
    this.loading = true;
    return new Promise((resolve, reject) => {
      const allowedExtensions = ['docx', 'doc', 'xlsx', 'xls', 'ppt', 'pptx', 'txt', 'pdf', 'pptm'];
      const extension = attachment.uri.split('.').pop()?.toLowerCase() || '';

      // Only proceed if the attachment type is allowed
      if (!allowedExtensions.includes(extension)) {
        const imageExtensions = ['jpg', 'png', 'svg', 'gif'];
        if (imageExtensions.includes(extension)) {
          this.loadImage(attachment.uri)
            .then(() => {
              this.loading = false;
              resolve();
            })
            .catch(error => reject(error));
          resolve();
        } else if (extension === 'mp4') {
          return this.loadVideo(attachment.uri)
            .then(() => {
              this.loading = false;
              resolve();
            })
            .catch(error => reject(error));
        } else {
          return Promise.reject(new Error('Invalid file extension'));
        }
      } else {
        this.loadPdf(attachment.uri)
          .then(result => {
            this.loading = false;
            return result;
          })
          .catch(error => reject(error));
      }
    });
  }

  loadPdf(attachment): Promise<void> {
    const headersCreate = new Headers({
      'Content-Type': 'application/octet-stream'
    });

    return new Promise<void>((resolve, reject) => {
      try {
        this.accessToken = this.oktaAuthService.getAccessToken();
        if (this.accessToken) {
          headersCreate.set('Authorization', `Bearer ${this.accessToken}`);
        }
      } catch (error) {
        console.error('Error getting access token:', error);
        reject(error);
      }

      fetch(this.updatePdfName(attachment), {
        method: 'GET',
        headers: headersCreate
      })
        .then(response => response.arrayBuffer())
        .then(buffer => {
          const blob = new Blob([buffer], { type: 'application/pdf' });
          this.pdfSrc = this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(blob));
          resolve();
        })
        .catch(err => {
          console.error('Error fetching and displaying in iframe:', err);
          reject(err);
        });
    });
  }

  updatePdfName($url: string): string {
    const urlObj = new URL($url);
    const pathSegments = urlObj.pathname.split('/');
    const filename = pathSegments.pop();
    const extension = $url.split('.').pop()?.toLowerCase() || '';
    let updatedPdf = $url;
    if (extension !== 'pdf') {
      updatedPdf =  `${urlObj.origin}/${pathSegments.join('/')}/pdf-${filename}.pdf`;
    }
    return updatedPdf;
  }

  loadImage(imageUrl): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.dataService.downloadBlob(imageUrl).subscribe(
        (blob: Blob) => {
          const reader = new FileReader();
          reader.onload = (e: any) => {
            const dataUrl = e.target.result;
            this.imageSrc = this.sanitizer.bypassSecurityTrustUrl(dataUrl);
            resolve();
          };
          reader.readAsDataURL(blob);
        },
        (error) => {
          console.error('Error loading image:', error);
          reject(error);
        }
      );
    });
  }

  loadVideo(videoUrl: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.dataService.downloadBlob(videoUrl).subscribe(
        (blob: Blob) => {
          this.videoSrc = this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(blob));
          resolve();
        },
        error => {
          console.error('Error fetching MP4 file:', error);
          reject(error);
        }
      );
    });
  }

  async printImage(attachment: ResourceAttachment) {
    const headersCreate = new Headers({
      'Content-Type': 'application/octet-stream'
    });

    try {
      this.accessToken = this.oktaAuthService.getAccessToken();
      if (this.accessToken) {
        headersCreate.set('Authorization', `Bearer ${this.accessToken}`);
      }

      const response = await fetch(attachment.uri, {
        method: 'GET',
        headers: headersCreate
      });

      if (response.ok) {
        const blob = await response.blob();
        const blobURL = (this.window as any).URL.createObjectURL(blob);
        printJS({ printable: blobURL, type: 'image', header: `${attachment.name}.${attachment.fileExtension}`, documentTitle: `${attachment.name}.${attachment.fileExtension}` });

      } else {
        console.error('Error fetching blob:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching and printing blob:', error);
    }
  }
}
