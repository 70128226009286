import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'internalLinks'
})
export class InternalLinksPipe implements PipeTransform {

    transform(value) {
    if (!value) {
      return false;
    } else if (value.startsWith('https://smartertoolsforteachers.org/')) {
      value = value.replace('https://smartertoolsforteachers.org', '');
      return value;
    } else if (value.match('resource\/*')) {
      return value;
    } else {
      return false;
    }
  }

}
