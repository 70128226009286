import { Injectable, Inject, Injector } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { OktaAuth } from '@okta/okta-auth-js';
import { OKTA_AUTH } from '@okta/okta-angular';
import { Observable, throwError, from } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    @Inject(OKTA_AUTH) private oktaAuthService: OktaAuth,
    private router: Router // Inject Injector to get Router instance later
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.headers.has('SkipInterceptor')) {
      const newRequest = request.clone({
        headers: request.headers.delete('SkipInterceptor')
      });
      return next.handle(newRequest);
    } else {
      return from(this.oktaAuthService.tokenManager.get('accessToken')).pipe(
        switchMap(token => {
          if (token && token.accessToken) {
            request = request.clone({
              setHeaders: {
                Authorization: `Bearer ${token.accessToken}`
              }
            });
          }
          return next.handle(request).pipe(
            catchError(error => {
              if (error instanceof HttpErrorResponse && error.status === 403) {
                this.handle403Error();
                return throwError(error);
              } else {
                return throwError(error);
              }
            })
          );
        })
      );
    }
  }

  private handle403Error(): void {
    // Navigate to the home page
    // This is here because we sometimes have an old token when requesting from OKTA, this
    // was put in as a "workaround" as the Project Manager didn't want developer (Summer / Fall 2024)
    // to spend time fixing it.
    this.router.navigate(['/']); // Adjust the route as needed for your home page
  }
}
