import {Component, OnInit, EventEmitter, Output, Input, OnDestroy, Inject} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LoginWarningService } from './login-warning.service';
import { SearchQueryParams } from '../../data/search/search-query-params.model';
import { StorageService } from 'src/app/common/storage.service';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/data/user/user.service';
import { SessionStateKey } from 'src/app/common/enums/session-state-key.enum';
import { OktaAuth } from '@okta/okta-auth-js';
import {OKTA_AUTH} from '@okta/okta-angular';

@Component({
  selector: 'sbdl-login-warning',
  templateUrl: './login-warning.component.html',
  styleUrls: ['./login-warning.component.scss']
})
export class LoginWarningComponent implements OnInit, OnDestroy {

  @Input()
  queryParams: SearchQueryParams;

  @Output()
  closeClick: EventEmitter<any>;

  private authenticated: boolean;
  private authSubscription: Subscription;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private loginWarningService: LoginWarningService,
    private userService: UserService,
    private storageService: StorageService,
    @Inject(OKTA_AUTH) private oktaAuthService: OktaAuth
  ) {
    this.closeClick = new EventEmitter<any>();
  }

  ngOnInit() {
    this.authSubscription = this.userService.authenticated
      .subscribe((auth) => this.authenticated = auth);
  }

  ngOnDestroy() {
    if (this.authSubscription) {
      this.authSubscription.unsubscribe();
    }
  }

  login() {
    this.close();
    const redirectUrl = this.router.createUrlTree(['/search'],
      { queryParams: this.queryParams, relativeTo: this.route }).toString()
      .replace('?', ';');
    this.router.navigate(['/auth/login'], { queryParams: { redirectUrl }});
  }

  shouldDisplay(sessionKey: SessionStateKey): boolean {
    const previouslyDisplayed = this.storageService.getLoginWarningDisplayed(sessionKey);
    const displayPopover = !this.authenticated && !previouslyDisplayed;

    return displayPopover;
  }

  close = () => {
    this.loginWarningService.close();
  }
}
